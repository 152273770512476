import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import MED from "../../../Assets/Medicare/Med-1.png";
import TitleFlag from "../../Common/TitleFlag/Index";
export default function MedicareRecipient() {
  return (
    <>
      <MedicareRecipientStyles>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <img src={MED} className="img-fluid" alt="" />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="med-content">
              <TitleFlag text="Medicare" />
              <p className="title">
                90% of Medicare Recipients are happy with their Medicare plan.
              </p>
              <p className="desc">
                A recent survey revealed that 90% of Medicare recipients are
                satisfied with their Medicare plan. This high satisfaction rate
                highlights the program's effectiveness in providing essential
                healthcare services and financial security for seniors. We work
                with top medicare carriers; you can compare not only medicare
                carriers but also compare rates. Enroll in a Medicare Plan
                Online or With a Caring Agent. Contact Us for Medicare Signup.
                Top Medicare Plans for Your Needs. Enroll Today. Call Our
                Experts to Get Started Now. Explore Medicare Plans Online & Find
                the Right Fit. Affordable Medicare Plans. Add Prescriptions &
                See Costs. Call to Enroll & Maximize Benefits
              </p>
              {/* w */}
              {/* <p className="speak">
                Speak to a licensed insurance agent:{" "}
                <span>+1 866-359-7865TTY:711</span>
              </p> */}
              <div className="d-flex flex-colum w-75 align-items-center justify-content-between flex-wrap">
                <a href="tel:+18668615366" class="speak my-2">
                  <button class="speak my-2">English: +1 866-861-5366</button>
                </a>
                <a href="tel:+18668392310" class="speak me-3">
                  <button class="speak me-3">Spanish: +1 866-839-2310</button>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </MedicareRecipientStyles>
    </>
  );
}

const MedicareRecipientStyles = styled.div`
  width: 100%;
  margin: 4rem 0;
  border-radius: 12px;
  border: 1px solid #22d1a3;
  background: #fdfdfd;
  padding: 1rem;
  .sub-form {
    .input-wrapper {
      input {
        height: 45px;
        width: 55%;
        border-radius: 5px;
        border: 1px solid #22d1a3;
        background: #fff;
        padding-left: 10px;
        font-size: 12px;
        outline: none;
        &::placeholder {
          color: #848484;
          font-family: Tienne;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      .sub-btn {
        height: 45px;
        outline: none;
        padding: 0 20px;
        border: none;
        border-radius: 5px;
        background: #22d1a3;
        color: #fff;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: -10px;
      }
    }
    .desclaimer {
      color: #848484;
      font-family: Tienne;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 50ch;
      margin-top: 1rem;
    }
  }

  .title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .desc {
    color: #848484;
    font-family: Tienne;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .multi {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .speak {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
    border: none;
    background: none;
    span {
      color: #3a3a3a;
      font-family: Tienne;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
  }
`;
