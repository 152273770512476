import React,{useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ADV1 from "../../../Assets/Medicare/Adv-1.png";
import ADV2 from "../../../Assets/Medicare/Adv-2.png";
import GetInTouch from "../../Models/GetInTouch";
export default function Advantages() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <AdStyles>
        <Row className="mb-4 w-100">
          <Col lg={6} md={12} sm={12}>
            <div className="h-100 w-100 d-flex align-items-center justify-content-start">
              <img src={ADV1} className="w-100 h-auto" alt="" />
            </div>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <div className="content">
              <p className="c-title">
                Easy and Hassle-Free Medicare Signup Process.
              </p>
              <p className="c-desc">
                Turning 65 and need Medicare enrollment? Whether it's your first time or adjusting your
                plan, our guide and support ensure stress-free coverage.
              </p>
              <p className="c-desc">
                <b>Expert Guidance:</b> Our Medicare advisors assist you through
                every step.
              </p>
              <p className="c-desc">
                <b>Comprehensive Support:</b> Get personalized help, free
                quotes, and unlimited calls to answer all your Medicare signup
                questions.
              </p>
              <p className="c-desc">
                <b>Check Eligibility:</b> Confirm your eligibility and
                understand enrollment periods.
              </p>
              <p className="c-desc">
                <b>Compare Plans:</b> Use our tools to compare Medicare plans by
                coverage and cost.
              </p>
              <p className="c-desc">
                <b>Enroll Online:</b> Follow our simple process to sign up for
                your chosen plan.
              </p>
              <button className="get-in-touch-btn"  onClick={() => setModalShow(true)}>Talk to Agent</button>
            </div>
          </Col>
        </Row>

        <Row className="my-4 w-100 c-reverse">
          <Col lg={6} md={12} sm={12}>
            <div className="content">
              <p className="c-title">
                Secure Your Future with Medicare Supplement Insurance
              </p>
              <p className="c-desc">
                Medicare Supplement Insurance (Medigap) fills gaps in Original
                Medicare, reducing out-of-pocket deductibles, copayments, and
                coinsurance costs. Choose any Medicare-accepting doctor or
                hospital without network restrictions. Explore our plans:
              </p>
              <p className="c-desc">
                <b>Plan A:</b> Basic coverage for essential needs.
              </p>
              <p className="c-desc">
                <b>Plan B:</b> More comprehensive care
              </p>
              <p className="c-desc">
                <b>Plan F:</b> Maximum protection.
              </p>
              <p className="c-desc">
               <b>Plan G:</b> Extensive coverage with lower premiums than Plan F.
              </p>
              <button className="get-in-touch-btn"  onClick={() => setModalShow(true)}>Talk to Agent</button>
            </div>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <div className="h-100 w-100 d-flex align-items-center justify-content-lg-end">
              <img src={ADV2} className="w-100 h-auto" alt="" />
            </div>
          </Col>
        </Row>
        <GetInTouch show={modalShow} onHide={() => setModalShow(false)} />
      </AdStyles>
    </>
  );
}

const AdStyles = styled.div`
  width: 100%;
  min-height: 450px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 0;

  @media (max-width: 800px) {
    .c-reverse {
      flex-direction: column-reverse;
    }
  }

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .c-title {
      color: #3a3a3a;
      font-family: Tienne;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 25ch;
      margin: 1rem 0;
      @media(max-width:600px){
        font-size:22px;
        max-width:100ch;
      }
    }
    .c-desc {
      color: #3a3a3a;
      font-family: Tienne;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 55ch;
      @media(max-width:600px){
        font-size:14px;
      }
    }
    .get-in-touch-btn {
      border-radius: 10px;
      background: #4c9784;
      display: inline-flex;
      padding: 15px 3rem;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      text-align: center;
      font-family: Epilogue;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.5px;
      letter-spacing: -0.15px;
      outline: none;
      border: none;
    }
  }
`;
