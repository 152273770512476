import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import BookingSubmissions from "./BookingSubmissions";
import { DP } from "../../../Shared/SharedStyles";
export default function PrimaryCare() {
  return (
    <>
      <DP>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="desc mb-0">
              Medicare Supplement Plan G covers almost all expenses not covered
              by Original Medicare. Part A coinsurance and hospital costs are
              covered up to an additional 365 days after using all Medicare
              benefits. Plan G does not cover prescription drugs, but you can
              get this coverage separately with a Part D plan.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="desc mb-0">
              The monthly premium for Medicare Supplement Plan G varies by age,
              sex, smoking status, and location. In Houston, Texas, a
              65-year-old non-smoking man might pay $126 to $198 monthly in
              2023.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12 col-md-6">
            <div className="custom-list">
              <ul>
                <li>Extra 365 days of hospital costs</li>
                <li>Part B copays and coinsurance</li>
                <li>Part A deductible costs</li>
                <li>Skilled nursing facility coinsurance</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-6">
            <div className="custom-list">
              <ul>
                <li>Is the premium affordable monthly?</li>
                <li>Does it cover expected health care needs?</li>
                <li>Will Plan G broadly cover medical costs if ill?</li>
              </ul>
            </div>
          </div>
        </div>
        <BookingSubmissions/>
      </DP>
    </>
  );
}

