import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import GetInTouch from "../../Models/GetInTouch";
import FE1 from "../../../Assets/Medicare/icons/FE-1.svg";
import FE2 from "../../../Assets/Medicare/icons/FE-2.svg";
import FE3 from "../../../Assets/Medicare/icons/FE-3.svg";
import FE4 from "../../../Assets/Medicare/icons/FE-4.svg";

export default function MedicareTypes() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <TypesStyles>
        <Container>
          <div className="upper-section">
            <p className="mb-0 up-title">Types of Medicare Plans</p>
            <button
              className="get-in-touch-btn"
              onClick={() => setModalShow(true)}
            >
              Talk to Agent
            </button>
          </div>

          <div className="types-cards">
            <Row>
              <Col lg={3} md={6} sm={12}>
                <div className="tc-content">
                  <div className="icon">
                    <img src={FE1} className="tc-content-icon" alt="" />
                  </div>
                  <p className="tc-content-title">Original Medicare</p>
                  <div className="tc-content-list">
                    <ul>
                      <li>
                        Original Medicare provides health insurance for people
                        65 & older, and some younger individuals with
                        disabilities.
                      </li>
                      <li>
                        It includes Part A for hospital care and Part B for
                        medical services. Part A covers inpatient hospital
                        stays.
                      </li>
                      <li>
                        Part B covers doctor visits and preventive care. You can
                        visit any healthcare provider that accepts Medicare.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <div className="tc-content">
                  <div className="icon">
                    <img src={FE2} className="tc-content-icon" alt="" />
                  </div>
                  <p className="tc-content-title">
                    Medicare Prescription Drug Policy
                  </p>
                  <div className="tc-content-list">
                    <ul>
                      <li>
                        Medicare Prescription Drug policy helps to cover the
                        cost of medications. Available through Medicare Part D.
                      </li>
                      <li>
                        These plans are offered by private insurers approved by
                        Medicare, reducing out-of-pocket expenses for
                        prescription drugs.
                      </li>
                      <li>
                        You can join a standalone Part D plan or get coverage
                        through a Medicare Advantage plan that includes drug
                        coverage.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <div className="tc-content">
                  <div className="icon">
                    <img src={FE3} className="tc-content-icon" alt="" />
                  </div>
                  <p className="tc-content-title">Medicare Supplement Plans</p>
                  <div className="tc-content-list">
                    <ul>
                      <li>
                        Medicare Supplement plans, or Medigap, cover
                        out-of-pocket costs not included in Original Medicare,
                        like copayments, coinsurance, and deductibles.
                      </li>
                      <li>
                        Offered by private insurers, these plans work with any
                        provider that accepts Medicare, enhancing your Medicare
                        coverage.
                      </li>
                      <li>
                        Medigap provides financial protection, ensuring broader
                        access to healthcare without unexpected expenses.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <div className="tc-content">
                  <div className="icon">
                    <img src={FE4} className="tc-content-icon" alt="" />
                  </div>
                  <p className="tc-content-title">
                    Signing Up for Medicare
                  </p>
                  <div className="tc-content-list">
                    <ul>
                      <li>
                        Understanding Medicare and supplemental insurance
                        options is crucial when signing up for Medicare.
                      </li>
                      <li>
                        The Medicare application process includes evaluating
                        Part A, Part B, and Medigap plans to suit your
                        healthcare needs.
                      </li>
                      <li>
                        Signing up for Medicare ensures comprehensive coverage,
                        while Medigap policies offer additional financial
                        protection.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <GetInTouch show={modalShow} onHide={() => setModalShow(false)} />
        </Container>
      </TypesStyles>
    </>
  );
}

const TypesStyles = styled.div`
  width: 100%;
  background: #fafdfc;
  min-height: 450px;
  padding: 2rem 0;

  .upper-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    @media(max-width:600px){
      flex-direction:column;
      align-items:flex-start;
    }
    .up-title {
      color: #112950;
      font-family: Epilogue;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 43.2px; 
      @media(max-width:600px){
        font-size:26px;
      }
    }
  }

  .get-in-touch-btn {
      border-radius: 10px;
      background: #4c9784;
      display: inline-flex;
      padding: 15px 3rem;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      text-align: center;
      font-family: Epilogue;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.5px;
      letter-spacing: -0.15px;
      outline: none;
      border: none;
    }

  .tc-content {
    border-radius: 5px;
    padding:1rem;
    border: 1px solid #22d1a3;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    min-height:500px;
    width:100%;
    margin-top: 4rem;
    .icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 1px solid #22d1a3;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top:-35px;
      right:38%;
   

      @media(max-width:600px){
        right:40%;
      }
      .tc-content-icon{
        width:40px;
        height:auto;
      }
    }
    .tc-content-title {
      color: #22d1a3;
      text-align: center;
      font-family: Epilogue;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
    }

    ul {
      list-style-type: circle;
      li {
        color: #3A3A3A;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom:1rem;
      }
    }
  }
`;
