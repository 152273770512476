import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import BookingSubmissions from "./BookingSubmissions";
import { DP } from "../../../Shared/SharedStyles";
export default function UrgentCare() {
  return (
    <>
      <DP>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="desc mb-0">
              Medicare Supplement (Medigap) plans are additional insurance
              policies crafted to enhance your coverage, reduce expenses, and
              provide continuous peace of mind for beneficiaries. Immediate
              Coverage: No waiting period for preexisting conditions. Low
              Out-of-Pocket Costs: Affordable plans with minimal expenses.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="desc mb-0">
              Receive thorough diagnostic services covered by your Medicare
              supplemental insurance to ensure accurate and timely medical care.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12 col-md-6">
            <div className="custom-list">
              <ul>
                <li>Freedom of Choice</li>
                <li>Best Medicare Medigap Plan G</li>
                <li>Medicare Plan G Swift Enrollment</li>
                <li>Guaranteed Renewable of Medicare Supps Plans</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-6">
            <div className="custom-list">
              <ul>
                <li>covered lab tests and X-rays</li>
                <li>Imaging services like MRIs and CT scans</li>
                <li>Cardiovascular screenings</li>
              </ul>
            </div>
          </div>
        </div>
        <BookingSubmissions/>
      </DP>
    </>
  );
}
