import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import MedicareArr from "../../Common/MedicareArr";
import TitleFlag from "../../Common/TitleFlag/Index";
import HomeTrending from "../HomeTrending";
import { TrendingStylesList } from "../../Shared/SharedStyles";
import { Trendinglist } from "../../Common/StaticData/Trending";
import TrendingComp from "../../Common/Trending";

export default function VideoVlogs() {
  return (
    <>
      <TitleFlag text="Elderly Care" />
      <Row className="my-4">
        <Col md={8}>
          <div className="yt-video">
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/htizd48Db10?si=OoBkGQvDilwQL4gn"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </Col>
        <Col md={4}>
            <div>
              <div className="trending-start">
                <p className="mb-0 ts-title">Trending</p>
              </div>
            <TrendingComp/>
            </div>
        </Col>
      </Row>
    </>
  );
}
