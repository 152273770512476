import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import MED from "../../../Assets/Medicare/Med-1.png";
import TitleFlag from "../../Common/TitleFlag/Index";
export default function MedicareRecipient2() {
  return (
    <>
      <MedicareRecipientStyles>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <img src={MED} className="img-fluid" alt="" />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="med-content">
              <TitleFlag text="Medicare" />
              <p className="title">
                Compare Top Carriers and Medicare Advantage Plans
              </p>
              <p className="desc">
              At The Senior Digest, we work with top carriers such as Humana Medicare, Aetna 
              Medicare, WellCare Medicare Advantage, and United Health Medicare Advantage 
              Plans. This allows you to compare top carriers and Medicare Advantage Plans to 
              identify the best option.
              Our Medicare Advantage Plans are specifically designed to meet the needs of 
              seniors, providing comprehensive coverage and additional benefits to ensure your 
              health and well-being.
              At The Senior Digest, we prioritize your health and satisfaction. We offer tailored 
              medicare advantage plans to make sure the comprehensive coverage, additional 
              benefits, and exceptional care. Don't wait—join thousands of satisfied members 
              who trust us for their healthcare needs
              Ready to enhance your healthcare coverage? Call us today to learn more about our
              Medicare Advantage Plans. Our licensed medicare advantage plans are here to 
              help you choose the rightest medicare advantage plan that best fits your needs.
              </p>
              {/* w */}
              {/* <p className="speak">
                Speak to a licensed insurance agent:{" "}
                <span>+1 866-359-7865TTY:711</span>
              </p> */}
              <div className="d-flex flex-colum w-75 align-items-center justify-content-between flex-wrap">
                <a href="tel:+18668615366" class="speak my-2">
                  <button class="speak my-2">English: +1 866-861-5366</button>
                </a>
                <a href="tel:+18668392310" class="speak me-3">
                  <button class="speak me-3">Spanish: +1 866-839-2310</button>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </MedicareRecipientStyles>
    </>
  );
}

const MedicareRecipientStyles = styled.div`
  width: 100%;
  margin: 4rem 0;
  border-radius: 12px;
  border: 1px solid #22d1a3;
  background: #fdfdfd;
  padding: 1rem;
  .sub-form {
    .input-wrapper {
      input {
        height: 45px;
        width: 55%;
        border-radius: 5px;
        border: 1px solid #22d1a3;
        background: #fff;
        padding-left: 10px;
        font-size: 12px;
        outline: none;
        &::placeholder {
          color: #848484;
          font-family: Tienne;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      .sub-btn {
        height: 45px;
        outline: none;
        padding: 0 20px;
        border: none;
        border-radius: 5px;
        background: #22d1a3;
        color: #fff;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: -10px;
      }
    }
    .desclaimer {
      color: #848484;
      font-family: Tienne;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 50ch;
      margin-top: 1rem;
    }
  }

  .title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .desc {
    color: #848484;
    font-family: Tienne;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .multi {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .speak {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
    border: none;
    background: none;
    span {
      color: #3a3a3a;
      font-family: Tienne;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
  }
`;
