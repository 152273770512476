import DE1 from '../../../../Assets/Common/Diabetes/DE-1.png';
import DE2 from '../../../../Assets/Common/Diabetes/DE-2.png';
import DE3 from '../../../../Assets/Common/Diabetes/DE-3.png';
import DE4 from '../../../../Assets/Blogs/BDTP-1.png';
import DE5 from '../../../../Assets/Blogs/BDWO-1.png';


export const DiabetesSection = [
    {
        id:"1",
        title:"10 Tips for a Healthier Future with Type 2 Diabetes",
        flag:"Diabetes",
        img:DE1,
        path:"/diabetes/blogs/healthier-future"
    },
    {
        id:"2",
        title:"10 important tests for Type 2 Diabetes",
        flag:"Diabetes",
        img:DE2,
        path:'/diabetes/blogs/important-test'
    },
    {
        id:"3",
        title:"Top 10 ways to improve your blood flow and circulation",
        flag:"Diabetes",
        img:DE3,
        path:"/diabetes/blogs/blood-flow"
    },
    {
        id:"4",
        title:"Tea And Diabetes: 11 Best Teas For People With Diabetes",
        flag:"Diabetes",
        img:DE4,
        path:'/diabetes/blogs/diabetes-tea'
    },
    {
        id:"5",
        title:"Exercising With Diabetes: 10 Simple Steps To Start Working Out Safely",
        flag:"Diabetes",
        img:DE5,
        path:'/diabetes/blogs/working-out'
    },
]