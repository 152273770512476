import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background: #fff;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    list-style: none;
  }

  .upper-nav{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
  }

  .health-score-btn{
    height:45px;
    min-width:180px;
    border-radius: 6px;
    background: #22D0A3;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline:none;
    border:none;
  }
.health-score-btn-2{
    height:45px;
    min-width:180px;
    border-radius: 6px;
    background: #fff;
    color: #22D0A3;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline:none;
    border:none;
    padding:0 10px;

}
.input-booking-field {
    width: 100%;
    display: flex;
    align-items: center;

    input {
      height: 60px;
      width: 100%;
      border-left: 2px solid #112950;
      background: #fff;
      border: none;
      outline: none;
      padding: 20px;
    }
    .book-btn {
      display: flex;
      width: 30%;
      height: 60px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      color: #fff;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.5px;
      letter-spacing: -0.15px;
      border: none;
      outline: none;
      background: #177ee5;
    }
  }


  
  .social-list{
    @media (max-width: 600px) {
          display:none;
        }
  }


  .header-one-list{
    padding-left:0px;
  
    li{
        font-size:16px;
        margin:10px;
        @media (max-width: 600px) {
          margin:5px 0;
        }
    }
    }



  @media (max-width: 600px) {
  .reverse-column {
    display: flex;
    flex-direction: column-reverse;
  }
}
.trending-start {
  border-bottom: 1px solid #bfbfbf;
  margin-bottom: 1rem;
}
.ts-title {
  color: #3a3a3a;
  font-family: Tienne;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.logo{
  width:200px;
  height:auto;

  @media(max-width:600px){
    width:40%;
  }
}


.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  border-color:transparent;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
  border-color:transparent;
}
.nav-item{
  color: #3A3A3A;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height:2;
}
.nav-link{
  color: #3A3A3A;
}
.nav-link:focus, .nav-link:hover{
 color:#22D1A3; 
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  color:#22D1A3; 
  border-bottom:2px solid #22D1A3;
  line-height:2;
}
`;

export default GlobalStyles;
