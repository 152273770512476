import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import HeroImage from "../../Assets/Common/hero-left-img.png";
import TitleFlag from "../Common/TitleFlag/Index";
import MedicareArr from "../Common/MedicareArr";
import HomeTrending from "./HomeTrending";
import Diabetes from "./Diabetes";
import FinalExpense from "./FinalExpense";
import MedicalInsurance from "./MedicalInsurance";
import Ebook from "./Ebook";
import ElderCare from "./ElderyCare";
import VideoVlogs from "./VideoVlogs";
import Subscribe from "./Subscribe";
import Footer from "../Footer";
import { Link } from "react-router-dom";
export default function Home() {
  return (
    <>
      <Container>
        <Row>
          <Col md={6}>
            <img src={HeroImage} alt="Hero" className="img-fluid w-100" />
          </Col>
          <Col md={6}>
            <HeroContent>
              <TitleFlag text="Medicare Made Easy" />
              <HeroTitle>
                Check your enrollment eligibility and review your Medicare Plans
                options that may be available in your area!
              </HeroTitle>
              <HeroButton>
                <Link to="/maxwell-berlin/details">Explore more</Link>
                </HeroButton>
            </HeroContent>
          </Col>
        </Row>
        <MedicareArr/>
        <HomeTrending/>
        <Diabetes/>
        <FinalExpense/>
        <MedicalInsurance title="Medical Insurance"/>
        <Ebook/>
        <ElderCare/>
        <VideoVlogs/>
        <Subscribe/>
        <Footer/>
      </Container>
      
    </>
  );
}

const HeroContent = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  margin: 1rem 0;
`;
const HeroTitle = styled.p`
  color: #3a3a3a;
  font-family: Tienne, serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;

  @media (max-width: 1400px) {
    font-size: 3vw;
  }

  @media (max-width: 992px) {
    font-size: 2.5vw;
  }

  @media (max-width: 768px) {
    font-size: 2.5vw;
  }

  @media (max-width: 576px) {
    font-size: 5vw;
  }
`;

const HeroButton = styled.button`
  border-radius: 5px;
  background: #22d1a3;
  width: 35%;
  height: 44px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  outline: none;
  border: none;

  @media(max-width:600px){
    width:50%;
    height:40px;
  }
`;


