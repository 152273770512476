import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import {
  TrendingStylesList,
  HealthContentStyles,
} from "../../Shared/SharedStyles";
import TitleFlag from "../../Common/TitleFlag/Index";
import { Trendinglist } from "../../Common/StaticData/Trending";
import FE1 from "../../../Assets/Common/FinalExpense/FE-1.png";
import FE2 from "../../../Assets/Common/FinalExpense/FE-2.png";
import TrendingComp from "../../Common/Trending";
export default function FinalExpense() {
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  return (
    <>
      <FinalExpStyles>
        <p className="fe-title">Final Expense</p>

        <Row>
          <Col md={8}>
            <Row>
              <Col md={12} lg={5}>
                <img src={FE1} alt="" className="img-fluid w-100" />
              </Col>
              <Col md={12} lg={7}>
                <HealthContentStyles>
                  <TitleFlag text="Health" />
                  <p className="title">
                    What is final expense life insurance plan?
                  </p>
                  <p className="desc">
                    {truncateText(
                      "What is final expense life insurance plan? Like all whole life insurance, it features policies that you pay into as long as you live. When you die, a death benefit, which you determined when you signed up for the policy, goes to your beneficiaries. That death benefit will not change during the life of the policy, and you are locked into the premium rates that you start with — they won't go up as the years pass. Unlike most whole life policies, however, a final expenses policy does not require a medical exam. At most, you'll fill out an application form that asks some questions about your health. But the underwriting process is short, and in some cases, you can have a policy in hand almost immediately",
                      250
                    )}
                  </p>
                  <p className="author mb-0">
                    By Afrasiab Khatak | May 5, 2024
                  </p>
                </HealthContentStyles>
              </Col>
            </Row>
            <Row className="my-4">
              <Col md={12} lg={6}>
                <TrendingComp />
              </Col>
              <Col md={12} lg={6}>
                <HealthContentStyles>
                  <TitleFlag text="Health" />
                  <p className="title">HOW DOES FINAL EXPENSE LIFE INSURANCE PLAN PROTECT MY LEGACY?</p>
                  <p className="desc">
                  Dedicated money to pay for burial or cremation costs. Fast, simple coverage with no medical exams. Low cost fixed payments - monthly, quarterly, or annual premiums. Tax-free benefit paid to your designated beneficiary. No waiting periods if you qualify - this is where we can help!
                  </p>
                  <p className="author mb-0">
                    By Afrasiab Khatak | May 5, 2024
                  </p>
                </HealthContentStyles>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <div>
              <div className="trending-start">
                <p className="mb-0 ts-title">Trending</p>
              </div>
              <TrendingComp />
            </div>
            <img src={FE2} className="custom-w-h" alt="" />
          </Col>
        </Row>
      </FinalExpStyles>
    </>
  );
}

const FinalExpStyles = styled.div`
  width: 100%;
  margin: 1.5rem 0;
  .fe-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
  }
  .custom-w-h {
    width: 100%;
    height: 292px;
  }
`;
