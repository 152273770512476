import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { DiabetesSection } from "../../Common/StaticData/DiabetesSec";
import { Link } from "react-router-dom";
export default function Diabetes() {
  const [showAll, setShowAll] = useState(false);
  const handleToggle = () => {
    setShowAll(!showAll);
  };
  return (
    <>
      <DiabetesStyles>
        <p className="title">Diabetes</p>

        <Row>
          {DiabetesSection.slice(0, showAll ? DiabetesSection.length : 3).map(
            (item, index) => (
              <Col key={index} md={4}>
                <Link to={item.path} style={{ textDecoration: "none" }}>
                  <DiabetesListing>
                    <img
                      src={item.img}
                      alt=""
                      className="img-fluid mb-3 w-100"
                    />
                    <p className="flag">{item.flag}</p>
                    <p className="dl-title">{item.title}</p>
                  </DiabetesListing>
                </Link>
              </Col>
            )
          )}
        </Row>

        <div className="w-100 d-flex align-items-center justify-content-center">
          <ViewMoreBtn onClick={handleToggle}>
            {showAll ? "View Less" : "View More"}
          </ViewMoreBtn>
        </div>
      </DiabetesStyles>
    </>
  );
}

const DiabetesStyles = styled.div`
  width: 100%;
  margin: 1.5rem 0;
  .title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
  }
`;

const DiabetesListing = styled.div`
  .dl-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .flag {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 30ch;
  }
`;

const ViewMoreBtn = styled.button`
  border-radius: 5px;
  border: 1px solid #22d1a3;
  height: 44px;
  width: 35%;
  color: #22d1a3;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background: none;
  margin: 1.5rem 0;

  @media (max-width: 600px) {
    font-size: 14px;
    width: 50%;
  }
`;
