import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import BD from "../../../../Assets/Blogs/BNP-1.jpg";
import Subscribe from "../../../Home/Subscribe";
import MedicalInsurance from "../../../Home/MedicalInsurance";
import TrendingComp from "../../../Common/Trending";
import Footer from "../../../Footer";
export default function BlogDetailsNeckPain() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <BlogStyles>
              <p className="mb-0 bs-title">What Causes Neck Pain?</p>
              <div className="w-100 auth-flex">
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
                <div className="share-social">
                  <FaFacebook size={15} color="#8A8A8A" />
                  <FaXTwitter size={15} color="#8A8A8A" className="mx-3" />
                  <FaInstagram size={15} color="#8A8A8A" />
                </div>
              </div>
              <div className="detail-content">
                <img src={BD} className="img-fluid w-100 my-2" />
                <p className="dc-desc">
                  The human neck is the most vital and one of the sensitive
                  parts of the body that is comprised of spinal bones,
                  ligaments, muscles and soft tissues. For this reason, neck is
                  considered to be at high risk of aching, getting injured or
                  being strained as it is the most moved part of our body.
                  Normally, neck pain seems to be a common ailment that occurs
                  to people with 60 or 65+ ages. Although the pain is not as
                  such serious every time but there are chances that the pain
                  might take the shape of serious illness which would then need
                  immediate medical attention and checkup. Especially when we
                  talk about elderly people, proper attention must be given to
                  them as their pain needs to be cured on time before it gets
                  worse.
                </p>
                <p className="dc-desc">
                  When looking for common pain spots, it is not necessary that
                  pain only occurs in the neck and cannot extend further.
                  However, the neck pain can develop into an acute disease and
                  can affect its adjoining areas such as the pain is highly
                  expected to shoot down in the shoulders, arms and back.
                </p>
                <p className="dc-desc">
                  The main question that arises here is that why is that neck
                  pain occurs in aged people. In most of the cases, people
                  experience pain due to a muscle tension or any other benign
                  cause. While there are also cases where people undergo severe
                  agony because they may have had an injury or bone fracture.
                  Neck pain is easily curable at home as you can use home
                  medications and remedies but if the pain worsens and gets
                  prolonged, then you must see the doctor urgently.
                </p>
                <p className="dc-desc">
                  In order to get deep information about your neck pain and to
                  determine its reason, read the causes below.
                </p>

                <p className="mb-0 dc-title-2">Causes of Neck Pain</p>
                <p className="dc-desc">
                  The most common causes of neck pain are explained below;
                </p>
                {/* <p className="dc-title">The Four Parts of Medicare</p> */}
                <p className="mb-0 dc-title-2">Muscle strain or tension</p>
                <p className="dc-desc">
                  Muscle tension occurs due to the rough tear and wear we give
                  to your necks. Some factors that are the cause of these
                  strains include:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Poor posture while sleeping</li>
                    <li>Not allowing your neck to rest while working</li>
                    <li>Roughly jerking your neck while exercising</li>
                    <li>Poor posture while sleeping</li>
                    <li>Not allowing your neck to rest while working</li>
                  </ul>
                </div>

                <p className="mb-0 dc-title-2">Mental stress and anxiety</p>
                <p className="dc-desc">
                  Mental stress is one of the major reason of neck pain. When
                  the nerves and muscles in the neck tighten or get stiff due to
                  emotional stress, they cause your neck to ache badly. Many
                  people, at such a sensitive age of seniority, complain to hold
                  pressure and tension in their necks or shoulders.
                </p>

                <p className="mb-0 dc-title-2">Meningitis</p>
                <p className="dc-desc">
                  The state in which there is inflammation of a thin tissue in
                  the the brain and spinal cord is known as 'meningitis'. People
                  with meningitis go through mild fever and a headache
                  accompanied with stiff neck. This condition can be lethal
                  therefore, it needs immediate medical aid.
                </p>

                <p className="mb-0 dc-title-2">Heart attack</p>
                <p className="dc-desc">
                  Many people experience neck pain after they have had a heart
                  attack. Not only heart attack but few other factors also add
                  to the pain;
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Nausea</li>
                    <li>Vomiting</li>
                    <li>Shortness of breath</li>
                    <li>Sweating</li>
                    <li>Jaw pain</li>
                  </ul>
                </div>

                <p className="mb-0 dc-title-2">Whiplash or a sudden injury</p>
                <p className="dc-desc">
                  Sudden jerking of the neck where the head jolts forward and
                  backward very quickly, is known as a 'whiplash'. Such a
                  movement may result in a crack of a whip where the ligaments
                  and muscles of the neck get misplaced. In some cases, cervical
                  vertebrae might fracture due to any accident or any falls.
                  Furthermore, neck injury is possible to occur in sport
                  activities or other sudden movements of your neck.
                </p>

                <p className="mb-0 dc-title-2">Osteoporosis</p>
                <p className="dc-desc">
                  Osteoporosis is a condition where the bones gets weak
                  ultimately leading to minor fractures. Bones weakening takes
                  place in hands, knees, neck and other major bones of the body.
                </p>

                <p className="mb-0 dc-title-2">Fibromyalgia</p>
                <p className="dc-desc">
                  It is a disease in which there is a serious muscle pain
                  throughout the body, especially in the neck, shoulders and its
                  adjoining areas.
                </p>

                <p className="mb-0 dc-title-2">Cervical radiculopathy</p>
                <p className="dc-desc">
                  Cervical radiculopathy, also referred as a pinched nerve, is a
                  state which occurs when irritation of the nerves originates in
                  the cervical spine, and eventually causes the neck pain to
                  shoot down in the arm. Primary symptoms that are expected to
                  occur in this condition are;
                </p>

                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Numbness in the shoulders and arms</li>
                    <li>Weakness or aching at some points in the arms</li>
                    <li>Feelings of pins and needles in the arms</li>
                    <li>Numbness in the shoulders and arms</li>
                    <li>Weakness or aching at some points in the arms</li>
                    <li>Feelings of pins and needles in the arms</li>
                  </ul>
                </div>

                <p className="mb-0 dc-title-2">Rheumatoid Arthritis</p>
                <p className="dc-desc">
                  Arthritis is progressive and long-term illness that causes
                  pain and weakness in the joints of the body. This state is
                  followed by swelling, inflammation, and aches in the joints of
                  the entire body. Usually Rheumatoid arthritis affects the
                  joints of hands and feet first but it can occur in any of the
                  joint.
                </p>
                <p className="dc-desc">
                  All the above mentioned causes of neck pain are more common in
                  senior people as they are at an age where bones and muscles
                  slowly weaken down. Despite the fact that the pain is curable,
                  still you must seek immediate medical checkup if your pain
                  prolongs.
                </p>
                <p className="dc-desc">
                  In conclusion, neck pain can be caused by a wide range of
                  factors and underlying conditions. It is often the result of
                  muscle strain, poor posture, or injury. However, neck pain can
                  also be a symptom of more serious medical conditions, such as
                  cervical spondylosis, herniated discs, or osteoarthritis.
                  Identifying the root cause of neck pain is essential for
                  effective treatment. Consulting with a healthcare
                  professional, undergoing diagnostic tests, and following
                  recommended treatments can help alleviate neck pain and
                  improve overall neck health. It's crucial to address neck pain
                  promptly to prevent it from becoming chronic or leading to
                  more severe complications.
                </p>
              </div>
              <MedicalInsurance title="Related Blogs" />
              <Subscribe />
            </BlogStyles>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TrendingComp />
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

const BlogStyles = styled.div`
  width: 100%;

  .bs-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 21ch;
  }
  .author {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .auth-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dc-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dc-desc {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }

  .dc-title-2 {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .dc-list {
    ul {
      li {
        color: #3a3a3a;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.2rem;
      }
    }
  }
`;
