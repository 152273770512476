import React, { useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import axios from "axios";

export default function BookingSubmissions() {
  const [value, setValue] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const input = e.target.value.replace(/\D/g, "");
    const formattedValue = formatPhoneNumber(input);
    setValue(formattedValue);
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const validateNumber = () => {
    if (!value) {
      setAlertMessage("Phone Number is required.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateNumber()) {
      setTimeout(() => setAlertMessage(""), 5000);
      return;
    }
    setIsSubmitting(true);
    console.log("NUMBER", value);
    try {
      const response = await axios.post(
        "https://solar-backend-qa7k.onrender.com/api/sd-booking-form",
        { phoneNumber: value } // Correctly pass value as an object
      );
      if (response.status === 200) {
        setAlertMessage(
          `Phone Number submitted successfully! We will contact you shortly.`
        );
        setValue("");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setAlertMessage(error.response?.data?.error || "An error occurred.");
    } finally {
      setIsSubmitting(false);
      setTimeout(() => setAlertMessage(""), 5000);
    }
  };

  return (
    <>
      <div className="input-booking-field">
        <input
          type="text"
          placeholder="Enter your phone number"
          value={value}
          onChange={handleChange}
          className="phone-input"
          maxLength="14"
        />
        <button className="book-btn" onClick={handleSubmit}>
          {isSubmitting ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Submitting...
            </>
          ) : (
            "Request a Callback"
          )}
        </button>
      </div>
      <div className="my-3">
        {alertMessage && <Alert variant="warning">{alertMessage}</Alert>}
      </div>
    </>
  );
}
