import React from 'react'
import styled from 'styled-components';

export default function TitleFlag({text}) {
  return (
    <>
    <Flag>{text}</Flag>
    </>
  )
}


const Flag = styled.div`
padding:1rem;
height:30px;
border-radius: 220px;
background: #4C9784;
color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
display: inline-flex;
align-items:center;
justify-content:center;
white-space: nowrap;
margin:0.5rem 0;


@media(max-width:600px){
  font-size:12px;
  font-weight:500;
}
`