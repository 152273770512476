import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import BD from "../../../Assets/Blogs/BRMP-1.jpg";
import Subscribe from "../../Home/Subscribe";
import MedicalInsurance from "../../Home/MedicalInsurance";
import TrendingComp from "../../Common/Trending";
import Footer from "../../Footer";
export default function BlogDetailsReviewMedicarePlans() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <BlogStyles>
              <p className="mb-0 bs-title">
                How To Decide Whether To Review Medicare Plans
              </p>
              <div className="w-100 auth-flex">
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
                <div className="share-social">
                  <FaFacebook size={15} color="#8A8A8A" />
                  <FaXTwitter size={15} color="#8A8A8A" className="mx-3" />
                  <FaInstagram size={15} color="#8A8A8A" />
                </div>
              </div>
              <div className="detail-content">
                <img src={BD} className="img-fluid w-100 my-2" />
                <p className="dc-desc">
                  Navigating the world of Medicare can be complex, yet staying
                  informed about your Medicare plans is crucial for optimal
                  healthcare and financial well-being. With changes in
                  healthcare policies, personal health needs, and plan
                  offerings, it's essential to periodically review your Medicare
                  coverage. This article guides you through the key
                  considerations to help you decide whether it's time to review
                  your Medicare plans.
                </p>
                <p className="mb-0 dc-title-2">
                  Understanding the Dynamics of Medicare
                </p>
                <p className="dc-desc">
                  Medicare plans aren't static; they evolve. Annually, plans can
                  change in terms of coverage options, costs, and provider
                  networks. Understanding these dynamics is the first step in
                  determining if a plan review is necessary. Especially during
                  the Medicare Open Enrollment Period, reviewing your plan can
                  ensure it still aligns with your needs.
                </p>
                <p className="dc-title-2">Assessing Your Health Needs</p>
                <p className="dc-desc">
                  As you age or as your health situation changes, so do your
                  healthcare needs. A plan that was a perfect fit a year ago
                  might not be suitable now if you have new medical diagnoses,
                  medications, or treatments. Regularly assessing your health
                  and aligning it with your Medicare plan is crucial.
                </p>
                <p className="mb-0 dc-title-2">Financial Considerations</p>
                <p className="dc-desc">
                  Your financial situation can influence your Medicare choices.
                  Changes in premiums, deductibles, and out-of-pocket costs can
                  impact your budget. Additionally, you might become eligible
                  for extra assistance or find that a different plan offers more
                  cost-effective coverage.
                </p>
                <p className="mb-0 dc-title-2">
                  Lifestyle and Provider Preferences
                </p>
                <p className="dc-desc">
                  Lifestyle changes, such as moving to a new area or traveling
                  more frequently, can affect which Medicare plan is best for
                  you. Moreover, ensure that your preferred healthcare providers
                  and hospitals are covered in your plan's network.
                </p>

                <p className="mb-0 dc-title-2">
                  Reviewing Prescription Drug Needs
                </p>
                <p className="dc-desc">
                  If you're on medication, review your Part D plan. It's vital
                  to ensure your current medications are covered and to compare
                  the costs associated with different plans. Changes in
                  medication or the need for new prescriptions are significant
                  reasons to review your plan.
                </p>
                <p className="mb-0 dc-title-2">
                  Keeping Up with Policy Changes
                </p>
                <p className="dc-desc">
                  Staying informed about changes in Medicare policies is
                  important. These changes can impact your coverage and costs.
                  Regularly reviewing these changes can help you stay ahead and
                  make informed decisions.
                </p>

                <p className="mb-0 dc-title-2">Seeking Professional Advice</p>
                <p className="dc-desc">
                  Deciding whether to review your Medicare plan can be
                  overwhelming. Consulting with a Medicare expert or a counselor
                  can provide clarity and guidance tailored to your specific
                  situation.
                </p>
                <p className="mb-0 dc-title-2">Conclusion</p>
                <p className="dc-desc">
                  Medicare plans require regular review to ensure they continue
                  to meet your evolving health, lifestyle, and financial needs.
                  By taking the time to assess your current situation and
                  understanding the changing landscape of Medicare, you can make
                  informed decisions about your healthcare coverage. Remember,
                  the goal is to have a plan that offers the best overall value
                  for your unique circumstances.
                </p>
              </div>
              <MedicalInsurance title="Related Blogs" />
              <Subscribe />
            </BlogStyles>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TrendingComp />
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

const BlogStyles = styled.div`
  width: 100%;

  .bs-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 21ch;
  }
  .author {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .auth-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dc-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dc-desc {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }

  .dc-title-2 {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .dc-list {
    ul {
      li {
        color: #3a3a3a;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.2rem;
      }
    }
  }
`;
