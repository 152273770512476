import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import pbcillus from "../../../Assets/Medicare/phc-1.png";
import TitleFlag from "../../Common/TitleFlag/Index";
export default function PublicHealthCare() {
  return (
    <>
      <PHC>
        <Row>
          <Col
            md={5}
            className="d-flex align-items-center justify-content-center"
          >
            <img src={pbcillus} className="pub-img" alt="" />
          </Col>
          <Col md={7}>
            <TitleFlag text="Medicare Supplemental Insurance Plans" />
            <p className="mb-0 title">
            Medicare Supplment To help you fully enjoy life, we offer protection for you and your loved ones with Medicare, Medigap insurance, & easy Medicare signup.
            </p>
          </Col>
        </Row>
      </PHC>
      <Facts>"11.8 million people had a Medicare plan in 2015."</Facts>
    </>
  );
}

const PHC = styled.div`
  width: 100%;
  min-height: 250px;
  border-radius: 12px;
  border: 1px solid #22d1a3;
  margin-top: 1.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .pub-img {
    width: 300px;
    height: auto;
  }
  .title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 41ch;
  }
`;

const Facts = styled.div`
  width: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: Tienne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 12px;
  background: #4c9784;

  @media (max-width: 991px) {
    font-size: 14px;
    padding: 1rem;
  }
`;
