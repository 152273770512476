import React, { useEffect, useState } from "react";
import { Table, Tabs, Tab, Container } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from "axios";
export default function GetData() {
  const [seniorDigestData, setSeniorDigestData] = useState([]);
  const [tqcData, setTqcData] = useState([]);
  const [loadingSeniorDigest, setLoadingSeniorDigest] = useState(true);
  const [loadingTQC, setLoadingTQC] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  const fetchData = async () => {
    try {
      const [seniorDigestResponse, tqcResponse] = await Promise.all([
        axios.get('https://solar-backend-qa7k.onrender.com/api/senior-digest-form'),
        axios.get('https://solar-backend-qa7k.onrender.com/api/quote-form-data')
      ]);
      setSeniorDigestData(seniorDigestResponse.data);
      setTqcData(tqcResponse.data);
      setLoadingSeniorDigest(false);
      setLoadingTQC(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoadingSeniorDigest(false);
      setLoadingTQC(false);
    }
  };

  console.log("object ::", tqcData);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (username === 'Infoperformance Agent' && password === 'Infoperformance@2024') {
        setAuthenticated(true);
        await fetchData();
      } else {
        alert('Invalid username or password');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('Login failed. Please try again.');
    }
  };


  const handleDeleteTQC = async (id) => {
    try {
      await axios.delete(`https://solar-backend-qa7k.onrender.com/api/quote-form-data/${id}`);
      const response = await axios.get('https://solar-backend-qa7k.onrender.com/api/quote-form-data');
      setTqcData(response.data);
    } catch (error) {
      console.error('Error deleting quote:', error);
    }
  };
  if (!authenticated) {
    return (
      <Container>
        <h1>Login</h1>
        <Form onSubmit={handleLogin}>
          <Form.Group className="mb-3" controlId="formUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Login
          </Button>
        </Form>
      </Container>
    );
  }

  return (
    <>
  
      <Container>
        <h1>Data Tabs</h1>
        <Tabs defaultActiveKey="seniorDigest" id="data-tabs">
          <Tab eventKey="seniorDigest" title="Senior Digest">
            {loadingSeniorDigest ? (
              <p>Loading Senior Digest data...</p>
            ) : seniorDigestData.length === 0 ? (
              <p>No data available for Senior Digest</p>
            ) : (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Submitted Date</th>
                  </tr>
                </thead>
                <tbody>
                  {seniorDigestData.map((item) => (
                    <tr key={item._id}>
                      <td>{item._id}</td>
                      <td>{item.name}</td>
                      <td>{item.phone}</td>
                      <td>{item.email}</td>
                      <td>{new Date(item.submittedDate).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Tab>
          <Tab eventKey="tqc" title="TQC">
            {loadingTQC ? (
              <p>Loading TQC data...</p>
            ) : tqcData.length === 0 ? (
              <p>No data available for TQC</p>
            ) : (
                <Table striped bordered hover>
                <thead>
                  <tr>
                  
                    <th>Name</th>
                    <th>Email</th>
                    <th>Zip Code</th>
                    <th>Date of Birth</th>
                    <th>Submitted Date</th>

                    {/* <th>Action</th>  */}
                  </tr>
                </thead>
                <tbody>
                  {tqcData.map(item => (
                    <tr key={item._id}>
                      <td>{`${item.firstName} ${item.lastName}`}</td>
                      <td>{item.emailAddress}</td>
                      <td>{item.zipCode}</td>
                      <td>{new Date(item.dob).toLocaleDateString()}</td>
                      <td>{new Date(item.submittedDate).toLocaleDateString()}</td>
                      {/* <td>
                        <Button variant="danger" onClick={() => handleDeleteTQC(item._id)}>
                          Delete
                        </Button>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Tab>
        </Tabs>
      </Container>
    </>
  );
}
