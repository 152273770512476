import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import BD from "../../../Assets/Blogs/BRMP-2.jpg";
import Subscribe from "../../Home/Subscribe";
import MedicalInsurance from "../../Home/MedicalInsurance";
import TrendingComp from "../../Common/Trending";
import Footer from "../../Footer";
export default function BlogDetailsRightMedicarePlans() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <BlogStyles>
              <p className="mb-0 bs-title">
                4 Surefire Tips For Picking The Right Medicare Plan
              </p>
              <div className="w-100 auth-flex">
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
                <div className="share-social">
                  <FaFacebook size={15} color="#8A8A8A" />
                  <FaXTwitter size={15} color="#8A8A8A" className="mx-3" />
                  <FaInstagram size={15} color="#8A8A8A" />
                </div>
              </div>
              <div className="detail-content">
                <img src={BD} className="img-fluid w-100 my-2" />
                <p className="dc-desc">
                  Your health care needs are personal, and your Medicare choices
                  should be too. Of course, you want a plan with the coverage
                  you need. You also want one that's kind to your pocketbook.
                </p>
                <p className="dc-desc">
                  Use these tips to help sort through your choices and nail down
                  what's right for you.
                </p>
                <p className="mb-0 dc-title-2">
                  Tip 1: It's Not Just about the Plan Premium
                </p>
                <p className="dc-desc">
                  Plan costs may include premiums, deductibles, copays and
                  coinsurance. Premiums are often what people mistakenly focus
                  only on - usually because this amount is paid monthly and can
                  seem larger than your other costs. But you want to compare all
                  the costs of plans, not just premiums. Why? Because while your
                  monthly premiums are consistent, your other costs can vary and
                  add up quickly! You pay towards your deductibles and for
                  copays and coinsurance when you receive covered services, so
                  the more services you use, the higher the other costs may be.
                </p>
                <p className="dc-desc">
                  In general, low premiums often come with high deductibles,
                  copays and coinsurance. This may suit you if you are generally
                  healthy and use few health care services. The opposite is also
                  true; you may choose to pay relatively high premiums and few
                  or no other costs if you have a lot of medical needs.
                </p>
                <p className="dc-desc">
                  The main point is to look at all your costs - not just
                  premiums - when comparing and choosing plans. Also check the
                  limits on annual out-of-pocket costs offered with Medicare
                  Advantage plans (Part C).
                </p>
                <p className="mb-0 dc-title-2">
                  Tip 2: Drug Formularies Are Your Friends
                </p>
                <p className="dc-desc">
                  Every Medicare plan features a unique formulary, which is a
                  list of the drugs it covers. These formularies are subject to
                  change annually, so it's important to review them each year to
                  ensure they include your required prescriptions. After
                  identifying plans that cover your medications, compare their
                  costs and additional benefits, such as routine vision, dental,
                  and hearing coverage. Also, consider the plan's pharmacy
                  network to ensure it encompasses your preferred pharmacy, as
                  this can affect the pricing of medications under the plan.
                </p>
                <p className="mb-0 dc-title-2">
                  Tip 3: Consider Whether You Want Specific Benefits
                </p>
                <p className="dc-desc">
                  Original Medicare (Parts A & B) only covers hospital and
                  medical services and additional benefits, respectively. And
                  often some services or health care additional benefits you
                  need come with a lot of conditions for them to even be
                  covered. Moreover, Original Medicare doesn't cover
                  prescription drugs (you need either a Part D or Medicare
                  Advantage plan), and it doesn't offer coverage for some
                  important things you may be used to getting such as routine
                  dental, vision or hearing coverage, you should make a list of
                  what you want. Then after you have that list, start exploring
                  plans that may offer these benefits in your area.
                </p>

                <p className="mb-0 dc-title-2">
                  Tip 4: Take Time to Understand Available Medicare Plans
                </p>
                <p className="dc-desc">
                  There may be additional plan benefits when it comes to
                  choosing Medicare coverage. You actually have seven different
                  ways you can combine the parts of Medicare to find the right
                  fit for you.
                </p>
                <p className="mb-0 dc-title-2">
                  The Right Plan Fits Your Personal Health Needs
                </p>
                <p className="dc-desc">
                  You may get a lot of advice about what Medicare plan you
                  should get. But the final decision for you needs to be made by
                  you, and for unique health care and lifestyle needs.
                </p>
              </div>
              <MedicalInsurance title="Related Blogs" />
              <Subscribe />
            </BlogStyles>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TrendingComp />
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

const BlogStyles = styled.div`
  width: 100%;

  .bs-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 21ch;
  }
  .author {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .auth-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dc-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dc-desc {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }

  .dc-title-2 {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .dc-list {
    ul {
      li {
        color: #3a3a3a;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.2rem;
      }
    }
  }
`;
