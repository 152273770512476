import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import BD from "../../../../Assets/Blogs/BDCC-1.jpg";
import Subscribe from "../../../Home/Subscribe";
import MedicalInsurance from "../../../Home/MedicalInsurance";
import TrendingComp from "../../../Common/Trending";
import Footer from "../../../Footer";
export default function BlogDetailsCC() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <BlogStyles>
              <p className="mb-0 bs-title">
                What Is Comprehensive Care For The Elderly?
              </p>
              <div className="w-100 auth-flex">
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
                <div className="share-social">
                  <FaFacebook size={15} color="#8A8A8A" />
                  <FaXTwitter size={15} color="#8A8A8A" className="mx-3" />
                  <FaInstagram size={15} color="#8A8A8A" />
                </div>
              </div>
              <div className="detail-content">
                <img src={BD} className="img-fluid w-100 my-2" />
                <p className="dc-desc">
                  Over the years, the physical state of people deteriorates and
                  although they are in good health, they require greater
                  attention to maintain their health both physically, mentally
                  and emotionally; To learn more about elderly care, what it
                  consists of and how it is provided, we encourage you to
                  continue reading this article.
                </p>
                <p className="dc-desc">
                  Comprehensive care of the elderly includes a series of
                  services, systems and methods aimed at ensuring the
                  psycho-educational, socio-cultural and physical-therapeutic
                  well-being of the elderly; they also help to prolong their
                  independence as much as possible, in addition to providing
                  them with a comfortable life.
                </p>
                <p className="mb-0 dc-desc">
                  However, the ideal is to understand this comprehensive care as
                  a coherent, cohesive and harmonious synthesis of multiple
                  methods and actions, rather than as a simple sum of services;
                  For this it is necessary to develop a comprehensive strategy
                  both at the family, social and governmental level. In this way
                  we will be able to provide our grandparents with a dignified
                  old age.
                </p>
                <p className="dc-desc">
                  It should be emphasized that not all cases are the same, so it
                  is necessary to pay special attention to the needs, both
                  personal and social, of each person in particular; the latter,
                  in order to identify those who only need some help to carry
                  out their daily activities, those who may require professional
                  assistance or the care of a nursing home or retirement home.
                </p>
                <p className="mb-0 dc-title-2">
                  What is the importance of Elderly Care?
                </p>
                <p className="dc-desc">
                  The state in which we will spend our old age will be a direct
                  result of the actions or omissions that we carry out
                  throughout our lives; however, it is also true that older
                  adults require greater care in order to ensure their physical,
                  mental and spiritual well-being.
                </p>
                <p className="mb-0 dc-desc">
                  Providing the elderly with the necessary care and attention in
                  each of these aspects results in lower mortality rates from
                  diseases such as coronary heart disease, colon or breast
                  cancer, hypertension, stroke, or type two diabetes.
                </p>
                <p className="dc-desc">
                  It also helps them to have a better function of the heart and
                  lungs; they also acquire greater muscle mass and better body
                  composition, while their functional health is also better, and
                  they have a lower risk of falls.
                </p>
                <p className="dc-desc">
                  Likewise, it reduces the levels of depression and its
                  cognitive functions are preserved in a better way; in
                  addition, it reduces the risk of severe or moderate functional
                  limitations.
                </p>
                <p className="mb-0 dc-title-2">
                  What care does an older person need?
                </p>
                <p className="dc-desc">
                  According to the “Manual for the care of dependent elderly
                  people and those with loss of autonomy,” published by the
                  Ministry of Health, comprehensive care for grandparents must
                  take into consideration a series of actions in favor of their
                  well-being.
                </p>
                <p className="dc-desc">
                  In principle, it says that we must integrate the principles of
                  autonomy and well-being into their care; Likewise, we must
                  know the characteristics of aging and the diseases that are
                  most common in them; it is also necessary to have knowledge
                  about its pharmacological treatment.
                </p>
                <p className="dc-desc">
                  Similarly, even family caregivers need to know what vital
                  signs are, their importance, and how they are measured; in the
                  case of older people with dementia, they should know their
                  most common behaviors, as well as their care.
                </p>
                <p className="dc-desc">
                  For its part, the Ministry of Public Health, in its “Manual
                  for caregivers of the elderly person; emphasizes the
                  importance of communication with the elderly to understand
                  their emotions and spirituality; It also considers that their
                  care must take into account their integral hygiene, as well as
                  their needs for mobility and comfort.
                </p>
                <p className="mb-0 dc-title-2">
                  How to make a plan of care for an elderly person?
                </p>
                <p className="dc-desc">
                  The Centers for Disease Control and Prevention (CDC)
                  recommends creating a written care plan that includes the
                  person's health conditions; it should also include the
                  treatments and medicines you are taking and emergency
                  contacts. Finally, you must give precise instructions on what
                  to do in the event of hospitalization or death.
                </p>
                <p className="dc-desc">
                  Developing a written plan, which is updated each year, can
                  help us reduce emergency room visits and hospitalizations; in
                  the same way, it can improve the medical treatment of people
                  with a chronic disease and improve the living conditions of
                  our grandparents.
                </p>
                <p className="dc-desc">
                  To do this, the first thing is to have an in-depth talk
                  between the grandparent and the person who will take care of
                  them; It will also be necessary to speak with your treating
                  doctor to find out your health status and the treatment you
                  are receiving; Finally, it is necessary to also discuss the
                  needs of the caregiver, in order to avoid anxiety and
                  depression problems that may arise in them.
                </p>
                <p className="mb-0 dc-title-2">
                  Recommendations for the Care of the Elderly
                </p>
                <p className="dc-desc">
                  Due to the multiple combinations of conditions and needs that
                  a single older adult may have, it is not possible to provide a
                  single specific plan; However, once the steps in the previous
                  section have been carried out, a series of recommendations can
                  be implemented to improve your quality of life:
                </p>
                <p className="dc-title-2 ps-3">Prevent falls</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Reviewing the medications they take to find out which ones
                      can cause dizziness or drowsiness.
                    </li>
                    <li>
                      Performing exercises that increase balance, strength, and
                      flexibility.
                    </li>
                    <li>Removing sources of risk in the home.</li>
                  </ul>
                </div>

                <p className="dc-title-2 ps-3">Doing physical activity</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Helping you perform multimodal exercises with an emphasis
                      on strength and endurance.
                    </li>
                    <li>Setting daily goals for physical activation.</li>
                  </ul>
                </div>

                <p className="dc-title-2 ps-3">
                  Prevent severe cognitive decline
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Monitoring their level of consciousness through constant
                      communication with them and evaluating their degree of
                      attention.
                    </li>
                    <li>
                      Offering them cognitive stimulation activities, even if
                      they have not been diagnosed with any impairment.
                    </li>
                  </ul>
                </div>

                <p className="dc-title-2 ps-3">
                  Promote your psychological well-being
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Proposing achievable goals and things they can or want to
                      do.
                    </li>
                    <li>Stimulating their social interactions.</li>
                    <li>
                      Carrying out activities in which they exercise short-term
                      memory.
                    </li>
                  </ul>
                </div>

                <p className="dc-title-2 ps-3">Maintain sensory functions</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Carrying out periodic examinations of the hearing and the
                      vision, especially.
                    </li>
                    <li>
                      Regularly carrying out personal hygiene and hygiene
                      measures.
                    </li>
                  </ul>
                </div>

                <p className="dc-title-2 ps-3">
                  Coping with problems associated with aging such as urinary
                  incontinence
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Performing bladder training exercises.</li>
                    <li>Implementing scheduled times to go to the bathroom.</li>
                    <li>Controlling fluids and diet in general.</li>
                  </ul>
                </div>

                <p className="dc-title-2 ps-3">Support caregivers</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Offering psychological interventions through a
                      professional.
                    </li>
                    <li>
                      Supporting them in caring for their grandparent so that
                      they can take time for themselves.
                    </li>
                    <li>Sharing useful and relevant information with them .</li>
                  </ul>
                </div>

                <p className="dc-title-2">Who should provide Elderly Care?</p>
                <p className="dc-desc">
                  Virtually all the laws of the world agree that the state must
                  provide health services and care options for the elderly;
                  However, it is in the family that the care and attention of
                  the grandparents and grandmothers falls.
                </p>
                <p className="dc-desc">
                  In this sense, more and more countries include in their
                  legislation the duty or even the obligation of the family to
                  take care of elderly relatives , most of them parents or
                  grandparents; although in countries like Colombia, for
                  example, this also extends to uncles in cae they do not have
                  children or their legitimate descendants cannot take care of
                  them. China has taken this precept further, with
                  administrative sanctions for those who fail to provide
                  adequate care for their elderly relatives.
                </p>
                <p className="dc-desc">
                  However, recent amendments to the law establish that the
                  family must also fulfill its social function of providing
                  “constant and permanent” care to the elderly; Likewise, it
                  makes them responsible for maintaining and caring for their
                  quality of life, as well as providing them with the means for
                  their care, attention and comprehensive development.
                </p>
              </div>
              <MedicalInsurance title="Related Blogs" />
              <Subscribe />
            </BlogStyles>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TrendingComp />
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

const BlogStyles = styled.div`
  width: 100%;

  .bs-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 21ch;
  }
  .author {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .auth-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dc-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dc-desc {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }

  .dc-title-2 {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .dc-list {
    ul {
      li {
        color: #3a3a3a;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.2rem;
      }
    }
  }
`;
