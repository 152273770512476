import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import styled from "styled-components";
import DoctorsConsultation from "./Accordian/DoctorsConsultation";
import DiagnosticServices from "./Accordian/DiagnosticServices";
import AnnualWellnessPhysical from "./Accordian/AnnualWellnessPhysical";
import PrimaryCare from "./Accordian/PrimaryCare";
import UrgentCare from "./Accordian/UrgentCare";

export default function BookService() {
  return (
    <>
      <BookServiceSectionStyles>
        <Container>
          <div className="bs-content">
            <p className="bs-content__title">
              We Offer a Whole Range of Medical Services
            </p>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Different parts of Medicare</Accordion.Header>
                <Accordion.Body>
                  <DoctorsConsultation />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Medicare Supplement Insurance Plans</Accordion.Header>
                <Accordion.Body>
                  <DiagnosticServices />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Benefits of Medicare Supplement Plans</Accordion.Header>
                <Accordion.Body>
                  <UrgentCare />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Medicare Supplement Plan G</Accordion.Header>
                <Accordion.Body>
                  <PrimaryCare />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>How do I apply for Medicare?</Accordion.Header>
                <Accordion.Body>
                  <AnnualWellnessPhysical />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Container>
      </BookServiceSectionStyles>
    </>
  );
}

const BookServiceSectionStyles = styled.div`
  width: 100%;
  background: #edf5f3;
  padding: 3rem;

  @media(max-width:600px){
    padding:1rem;
  }
  .bs-content {
    &__title {
      color: #112950;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 43.2px;
      max-width: 27ch;
      @media(max-width:600px){
        font-size:24px;
      }
    }
  }
  .accordion-item {
    background-color: transparent;
    border: none;
  }
  .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
    color: #177ee5;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34.08px;
  }
  .accordion-button:focus {
    box-shadow: none;
  }
  .accordion-button {
    color: #112950;
    background-color: transparent;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 34.08px;

    @media(max-width:600px){
      font-size:18px !important;
    }
  }
`;
