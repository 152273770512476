import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import BD from "../../../Assets/Blogs/BBPM-1.jpg";
import Subscribe from "../../Home/Subscribe";
import MedicalInsurance from "../../Home/MedicalInsurance";
import TrendingComp from "../../Common/Trending";
import Footer from "../../Footer";
export default function BlogDetailsBenefitPeriod() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <BlogStyles>
              <p className="mb-0 bs-title">
                What Is A Benefit Period For Medicare?
              </p>
              <div className="w-100 auth-flex">
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
                <div className="share-social">
                  <FaFacebook size={15} color="#8A8A8A" />
                  <FaXTwitter size={15} color="#8A8A8A" className="mx-3" />
                  <FaInstagram size={15} color="#8A8A8A" />
                </div>
              </div>
              <div className="detail-content">
                <img src={BD} className="img-fluid w-100 my-2" />
                <p className="dc-desc">
                  Navigating Medicare can often seem daunting, especially when
                  trying to understand specific terms and how they impact your
                  coverage. One such term is the “benefit period,” a critical
                  concept in Medicare that influences your coverage and costs.
                  This article demystifies the Medicare benefit period, helping
                  you better understand how it works and what it means for your
                  healthcare.
                </p>
                <p className="mb-0 dc-title-2">
                  What is a Benefit Period in Medicare?
                </p>
                <p className="dc-desc">
                  1- Hospital Stays: Under Medicare Part A, each benefit period
                  carries a deductible. This means if you're admitted to the
                  hospital multiple times in a year, but each stay is separated
                  by more than 60 days, you'll need to pay the deductible for
                  each benefit period.
                </p>
                <p className="dc-desc">
                  Skilled Nursing Facility (SNF) Care: For SNF care, Medicare
                  covers the first 20 days fully and partial costs from day 21
                  to 100 for each benefit period. A new benefit period means a
                  reset in these coverage terms.
                </p>
                <p className="dc-title-2">Cost Implications</p>
                <p className="dc-desc">
                  Understanding the benefit period is crucial for financial
                  planning, as it can significantly affect out-of-pocket
                  expenses. Knowing when a benefit period resets helps in
                  anticipating potential costs for hospital or SNF admissions.
                </p>
                <p className="mb-0 dc-title-2">
                  Benefit Period vs. Calendar Year
                </p>
                <p className="dc-desc">
                  Unlike a calendar year, which resets on January 1st each year,
                  a benefit period is not tied to the calendar. It is entirely
                  dependent on your use of inpatient services. This distinction
                  is important as other parts of Medicare, like Part B and Part
                  D, operate on a calendar-year basis for deductibles and
                  out-of-pocket maximums.
                </p>
                <p className="mb-0 dc-title-2">
                  Managing Multiple Benefit Periods
                </p>
                <p className="dc-desc">
                  It's possible to have multiple benefit periods in a year if
                  you have frequent hospitalizations. Keeping track of these can
                  be crucial for understanding your Medicare Part A coverage
                  limits and potential costs.
                </p>

                <p className="mb-0 dc-title-2">Conclusion</p>
                <p className="dc-desc">
                  The Medicare benefit period is a fundamental concept that
                  affects how much you pay for hospital and skilled nursing
                  facility care. By understanding how benefit periods work, you
                  can better plan for your healthcare needs and budget for
                  potential expenses. Always consult with a healthcare advisor
                  or a Medicare expert if you have specific questions about your
                  situation.
                </p>
              </div>
              <MedicalInsurance title="Related Blogs" />
              <Subscribe />
            </BlogStyles>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TrendingComp />
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

const BlogStyles = styled.div`
  width: 100%;

  .bs-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 21ch;
  }
  .author {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .auth-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dc-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dc-desc {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }

  .dc-title-2 {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .dc-list {
    ul {
      li {
        color: #3a3a3a;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.2rem;
      }
    }
  }
`;
