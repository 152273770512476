import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import BD from "../../../../Assets/Blogs/BDIT-1.png";
import Subscribe from "../../../Home/Subscribe";
import MedicalInsurance from "../../../Home/MedicalInsurance";
import TrendingComp from "../../../Common/Trending";
import Footer from "../../../Footer";
export default function BlogDetailsD2() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <BlogStyles>
              <p className="mb-0 bs-title">
                10 Important Tests For Type 2 Diabetes
              </p>
              <div className="w-100 auth-flex">
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
                <div className="share-social">
                  <FaFacebook size={15} color="#8A8A8A" />
                  <FaXTwitter size={15} color="#8A8A8A" className="mx-3" />
                  <FaInstagram size={15} color="#8A8A8A" />
                </div>
              </div>
              <div className="detail-content">
                <img src={BD} className="img-fluid w-100 my-2" />
                <p className="dc-desc">
                  Diabetes could get complicated and affect the body if it goes
                  undiagnosed. Testing is essential to identify the condition
                  earlier and start treatment before the situation gets worse.
                  Sometimes symptoms appear, but sometimes it develops silently.
                </p>
                <p className="dc-desc">
                  Diabetes testing assists in diagnosing prediabetes, a stage
                  before diabetes that can surely turn into diabetes if
                  untreated. Also, if you have diabetes, then you should undergo
                  specific testing and lab procedures regularly.
                </p>
                <p className="dc-desc">
                  Diabetes complications alter the normal physiology of
                  different organs and systems, so testing is essential to
                  ensure their functionality. It can affect your kidney, eyes,
                  skin, etc., well before you notice it, while diabetes testing
                  can catch the problems earlier with more chances of recovery.
                </p>
                <p className="dc-title">
                  10 Important Tests Diabetics Must Know!
                </p>
                <p className="dc-desc">
                  Following tests are the most important among the diabetes
                  checkup checklist, and you should get them performed:
                </p>
                <p className="dc-title-2">1- Blood Sugar Test</p>
                <p className="dc-desc">
                  The blood sugar test is the easiest, cheapest, and probably
                  the most performed test for diabetes. You can test for
                  diabetes at home by pricking your finger with the lancet and
                  assessing over a glucometer screen. Different blood tests for
                  sugar level are:
                </p>
                <p className="dc-title-2">Random Blood Sugar Test</p>
                <p className="dc-desc">
                  This test is performed randomly without considering when you
                  ate and what you ate last time. As a result, 200 mg/dl or
                  above random blood sugar level suggests that you have
                  diabetes, especially if symptoms accompany this high sugar
                  level.
                </p>
                <p className="dc-desc">
                  Test diabetes at home with your glucometer by pricking the
                  finger with the provided lancet. Keep a good record of your
                  blood sugar patterns from day 1; it will help manage a lot.
                </p>

                <p className=" dc-title-2">Fasting Blood Sugar Test:</p>
                <p className="dc-desc">
                  In this, you have to take a blood sample after a nightly fast
                  to analyze the serum sugar. A sugar level under 100 mg/dl is
                  pretty standard in this regard, while if the sugar level is
                  between 100-125mg/dl, you have prediabetes. But if the sugar
                  level exceeds 126 mg/ dl, this is a clear indicator of
                  diabetes.
                </p>
                <p className="dc-title-2">2- A1C Test </p>
                <p className="dc-desc">
                  The A1C test analyzes the average value of blood sugar over
                  the last 2-3 months. It helps to identify how well your sugar
                  level is maintained and controlled. It has different names
                  like haemoglobin A1C, HbA1C, glycated haemoglobin test.
                </p>
                <p className="dc-desc">
                  How does this test assess the glucose level? The A1C test is
                  one of the primary tests for diabetes and its management.
                  Glucose generally binds to haemoglobin, an integral part of
                  the red blood cells, and the A1C test is based on
                  glucose-haemoglobin attachment.
                </p>
                <p className="dc-desc">
                  If you have more glucose in the blood, there will be more
                  attachment to haemoglobin, and the A1C test measures the
                  average attached hemoglobin-glucose level. An A1C test result
                  below 5.7% is normal, and if it ranges from 5.7-6.4%, it
                  indicates prediabetes. While if the A1C test result is 6.5 %
                  or above, then you have diabetes.{" "}
                </p>

                <p className=" dc-title-2">3- Glucose Tolerance Test</p>
                <p className="dc-desc">
                  The primary purpose of this test is to determine how well your
                  body can metabolize the administered glucose and ultimately
                  diagnose diabetes and prediabetes. It measures how well the
                  body utilizes the sugar for muscle, fat, and other cells.
                </p>
                <p className="dc-desc">
                  The body needs glucose to provide energy to the cells, and
                  insulin hormone supplies this glucose from the blood to cells.
                  If insulin resistance develops, then there is a rise in blood
                  glucose levels.{" "}
                </p>
                <p className="dc-desc">
                  A glucose tolerance test is used to measure the overnight
                  fasting blood glucose level first. Then after administering a
                  particular sugar drink ( having approx 75 gm glucose), blood
                  sugar is again measured at fixed intervals. Blood glucose
                  level after two hours of drinking sugary beverages helps to
                  measure glucose tolerance.
                </p>
                <p className="dc-desc">
                  Following are the reference ranges for two hours:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Normal: less than 140 mg/dl</li>
                    <li>Prediabetes : 140-199 mg/dl</li>
                    <li>Diabetes: 200mg/dl or above</li>
                  </ul>
                </div>

                <p className=" dc-title-2">4- Lipid Profile Test</p>

                <p className="dc-desc">
                  If you have diabetes, you must undergo a lipid profile test
                  for diabetes at least once a year, according to the American
                  Diabetes Association. It is a fasting test to measure your
                  total serum cholesterol, triglycerides, LDL cholesterols, LDL
                  cholesterols.
                </p>
                <p className="dc-desc">
                  You might wonder about normal ranges of blood lipid profiles
                  to compare your test results, Right? Well, here are the few
                  normal ranges for comparison:
                </p>
                <p className=" dc-title-2">Total Cholesterol: </p>
                <p className="dc-desc">
                  It is the sum of HDL, LDL cholesterol, and triglycerides. Its
                  level should be 200 mg/dl or below to fall within normal
                  ranges.
                </p>
                <p className=" dc-title-2">LDL or Low-Density Lipoprotein:</p>
                <p className="dc-desc">
                  If you have diabetes, then the healthy range for LDL is 100
                  mg/dl or below. If you have diabetes and are a heart patient,
                  you should have 70 mg/dl or below LDL level.
                </p>

                <p className=" dc-title-2">HDL or High-Density Lipoprotein:</p>
                <p className="dc-desc">
                  A healthy range of HDL, good cholesterol is about 45 mg/dl or
                  above for men and about 55 mg/dl or above for women.
                </p>

                <p className=" dc-title-2">Triglyceride:</p>
                <p className="dc-desc">
                  A regular healthy reading for triglyceride should be 150mg/dl
                  or less.
                </p>

                <p className=" dc-title-2">5- Kidney function Test</p>
                <p className="dc-desc">
                  The kidney is one of the body's vital organs, as it excretes
                  the waste product and plays a crucial role in urine
                  production. If you have diabetes, your kidneys are at risk of
                  damage, so there is a need to have regular screening.{" "}
                </p>
                <p className="dc-desc">
                  According to research, every 1 out 3 adult diabetics have a
                  kidney disease too. How does diabetes affect the kidney? High
                  blood sugar increases the risk of renal vasculature damage,
                  ultimately compromising the kidney's normal function.{" "}
                </p>
                <p className="dc-desc">
                  Kidney testing includes a urine test to detect the protein
                  level and blood tests that show how well the kidney performs.
                  Any abnormality in these tests is a bad condition and needs to
                  be addressed immediately.{" "}
                </p>
                <p className="dc-desc">
                  How can I prevent my kidney from damaging as a person with
                  diabetes? Well, controlling the predisposing factor has a role
                  in lowering this risk. Managing the blood sugar, blood
                  pressure regulation, and less protein ingestion can prevent or
                  delay diabetic kidney damage.{" "}
                </p>
                <p className=" dc-title-2">6- Blood Pressure Check</p>
                <p className="dc-desc">
                  Diabetes testing requires regular blood pressure checking, as
                  it is easy, and you can even check at home. Diabetes and blood
                  pressure are the worst combination for normal health, and you
                  should maintain it.
                </p>
                <p className="dc-desc">
                  But, how can you maintain the blood pressure without knowing
                  its normal range? Diabetics' blood pressure should not exceed
                  140/80 mm of Hg, and you have to keep a strict check on it to
                  avoid severe complications.
                </p>
                <p className="dc-desc">
                  Recommended to check your diabetes score and manage your blood
                  pressure strictly accordingly. People with diabetes and having
                  increased BP are at elevated risk of heart failure, stroke,
                  and other cardiovascular diseases.
                </p>
                <p className=" dc-title-2">7- Foot Check</p>
                <p className="dc-desc">
                  If you are unable to manage your diabetes, it may complicate.
                  Foot problems are a complication of diabetes and require
                  regular screening. What to check in diabetic foot examination?
                  Look for any wound, infection, bone abnormalities, and any
                  kind of foot injury.
                </p>
                <p className="dc-desc">
                  Diabetes sometimes damages the nerves, induces neuropathy, and
                  poor circulation to the feet. It results in loss of sensation,
                  numbness in the feet, making it difficult to feel any wound or
                  injury. Also, reduced circulation results in delayed healing
                  of the damage and ulcers, which could be problematic late
                </p>
                <p className="dc-desc">
                  Without regular foot screening, the problem, if it exists,
                  maybe worse without being noticed. So it's crucial to examine
                  your feet daily; it's not a difficult task as you can check
                  them at home.{" "}
                </p>

                <p className=" dc-title-2">8- Eye Check</p>
                <p className="dc-desc">
                  Diabetes affects many areas of the body, and the eyes are one
                  of the targets it may concern. Vision is a great blessing, and
                  no one wants to lose it earlier with diabetes, Right? Regular
                  eye screening by your doctor can avoid optic complications
                  like cataracts, glaucoma, and diabetic retinopathy.
                </p>
                <p className="dc-desc">
                  Diabetic retinopathy is the primary concern for optic health
                  in diabetics, as it may lead to reduced, blurry vision, and
                  even sometimes blindness. Only early detection of the optic
                  abnormality, regular monitoring, and immediate treatment after
                  detection can avoid this complication.
                </p>
                <p className="dc-desc">
                  According to the American Diabetes Association, if you have
                  type 2 diabetes, you must undergo an eye exam immediately
                  after diagnosing diabetes. Better to check your health score
                  after diabetes diagnosis to get an idea about how the body is
                  functioning after this condition.
                </p>
                <p className=" dc-title-2">9- Dental Tests</p>
                <p className="dc-desc">
                  Dental tests are essential to avoid diabetic oral
                  complications; about 90% of people with diabetes have oral
                  manifestations. Excessive blood sugar can affect your mouth,
                  and teeth are an integral part of the mouth being affected.{" "}
                </p>
                <p className="dc-desc">
                  Saliva generally contains glucose, and when there is raised
                  blood sugar, its level also increase in saliva. High salivary
                  glucose induces bacterial growth in the mouth and results in
                  plaque formation over teeth. Plaque is not suitable for dental
                  health and may cause tooth decay and cavity damage.
                </p>
                <p className="dc-desc">
                  Diabetes testing includes regular dental screening to avoid
                  plaque formation in the beginning phase. Plaque, if not
                  removed timely, can harden and deposit over the gumline. It
                  may induce bleeding and pain while brushing and cleaning the
                  teeth.
                </p>
                <p className=" dc-title-2">10- Genetic Testing </p>

                <p className="dc-desc">
                  Type 2 diabetes is a severe medical disorder and generally
                  results from an interaction between environment and genes.
                  Various risk factors induce diabetes, such as blood pressure,
                  obesity, smoking, diet, low physical activity, etc. But you
                  can't deny the genetic aspect of diabetes, which is a bitter
                  truth.
                </p>
                <p className="dc-desc">
                  If the parents, either one or both, have diabetes, then the
                  risk of diabetes in offspring increases. According to a study,
                  diabetes development risk in offspring is 40 % if one parent
                  is affected, usually higher if a mother is concerned. And this
                  percentage jumps to 70% if both parents have diabetes.
                </p>
                <p className="dc-desc">
                  If your parents have diabetes, then what are you waiting for?
                  Recommended to get your genetic testing done, diagnose
                  diabetes earlier, and avoid any complications in the future.
                </p>
              </div>
              <MedicalInsurance title="Related Blogs" />
              <Subscribe />
            </BlogStyles>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TrendingComp />
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

const BlogStyles = styled.div`
  width: 100%;

  .bs-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 21ch;
  }
  .author {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .auth-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dc-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dc-desc {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }

  .dc-title-2 {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .dc-list {
    ul {
      li {
        color: #3a3a3a;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.2rem;
      }
    }
  }
`;
