import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Logo from "../../Assets/Common/primary-logo.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <FooterStyles>
        <div className="bg-g w-100 h-100">
          <Row className="w-100 h-100">
            <Col md={6}>
              <div className="footer-content-left">
                <img src={Logo} alt="" className="w-25 h-auto my-3" />
                <p className="fcl-desc">
                  The Senior Digest & Top-Quality Coverage is your Independent
                  Medicare-licensed Insurance agency and Advisor. Participating
                  sales agencies represent Medicare Advantage [HMO, PPO, and
                  PFFS] organizations that are contracted with Medicare.
                  Enrollment depends on the plan's contract renewal with
                  Medicare. We do not offer every plan available in your area.
                  Currently, we represent 4 organizations that offer 2 products
                  in your area. Please contact Medicare.gov, <b>1-800-MEDICARE</b>, or
                  your local State Health Insurance Program (SHIP) to get
                  information on all of your options. Not all plans offer all of
                  these benefits. Benefits may vary by carrier and location.
                  Limitations, exclusions, copays, deductibles, and coinsurance
                  may apply. The benefit (s) mentioned may be part of a special
                  supplemental program for chronically ill members with one of
                  the following conditions: Diabetes mellitus, Cardiovascular
                  disorders, Chronic and disabling mental health conditions,
                  Chronic lung disorders, and Chronic heart failure. This is a
                  partial list of qualifying conditions. Having a qualifying
                  condition alone does not mean you will receive the benefit(s).
                  Other requirements may apply. <br />
                  The Insurance Provider for this website is{" "}
                  <b>Maxwell Berlin</b>.
                </p>
                {/* <p className="len-more mt-3">Learn more about us</p> */}
              </div>
            </Col>
            <Col md={2}>
              <div className="footer-links">
                <p className="title">Site map</p>
                <ul>
                  <li>Diabetes</li>
                  <li>Eldery Care</li>
                  <li>Medicare Insurance</li>
                  <li>Final Expense</li>
                </ul>
              </div>
            </Col>
            <Col md={2}>
              <div className="footer-links">
                <p className="title">Links</p>
                <ul>
                  <li>
                    <Link
                      to="/privacy-policy"
                      style={{ textDecoration: "none" }}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms" style={{ textDecoration: "none" }}>
                      Terms of use
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={2}>
              <div className="footer-links">
                <p className="title">Contact Us</p>
                <ul>
                  <li>+1 866-861-5366</li>
                  <li>info@theseniordigest.com</li>
                </ul>
              </div>
            </Col>
          </Row>
          <div className="copy-rights">
            <p className="mb-0">
              All Rights Reserved &copy; theseniordigest.com
            </p>
          </div>
        </div>
      </FooterStyles>
    </>
  );
}

const FooterStyles = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
  background: #f8f8f8;
  padding: 1rem 1rem 0rem 1rem;

  .copy-rights {
    width: 100%;
    background: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: #3a3a3a;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .fcl-desc {
    color: #3a3a3a;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 55ch;
  }
  .len-more {
    color: #22d1a3;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
  .footer-links {
    .title {
      color: #3a3a3a;
      font-family: Tienne;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    ul {
      padding-left: 0px;
      text-align: left;
      li {
        color: #3a3a3a;
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        margin-bottom: 1rem;
      }
    }
  }
`;
