import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import BookingSubmissions from "./BookingSubmissions";
import { DP } from "../../../Shared/SharedStyles";
export default function DiagnosticServices() {
  return (
    <>
      <DP>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="desc mb-0">
              Medicare supplemental or Medicare Medigap insurance plans, also
              known as Medigap, help cover the out-of-pocket costs not paid by
              Original Medicare. Medicare Supplement (Medigap) plans work
              accordingly with your Original Medicare by covering the gaps in
              your coverage.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="desc mb-0">
              Signing up for Medicare can be a hassle-free experience with our
              step-by-step guidance. We help you navigate the Medicare
              application process to ensure you get the best coverage.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12 col-md-6">
            <div className="custom-list">
              <ul>
                <li>Applying for Medicare Made Easy</li>
                <li>Best Medicare Medigap Plans Available</li>
                <li>Enroll in Medicare for Seniors</li>
                <li>Signup for Medicare Swiftly
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-6">
            <div className="custom-list">
              <ul>
                <li>Right Medicare Supplemental Insurance Plan</li>
                <li>Detailed Medicare Application Assistance</li>
                <li>Enroll in Medicare with Ease</li>
              </ul>
            </div>
          </div>
        </div>
          <BookingSubmissions/>
      </DP>
    </>
  );
}

