import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import BookingSubmissions from "./BookingSubmissions";
import { DP } from "../../../Shared/SharedStyles";
export default function DoctorsConsultation() {
  return (
    <>
      <DP>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="desc mb-0">
              Part A covers hospital care, some skilled nursing facility costs,
              hospice care, and a portion of home health care (with Part B
              covering the rest). It is funded by the payroll tax (FICA)
              deposited into the Hospital Insurance Trust Fund.
              Part B covers doctor visits, outpatient hospital care, and home health care not covered by Part A. It also includes:
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="desc mb-0">
            Top Medicare Supplement (Medigap) plans that work with Medicare Part A and Part B.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12 col-md-6">
            <div className="custom-list">
              <ul>
                <li>Diagnostic and lab tests</li>
                <li>Medical equipment & preventive benefits</li>
                <li>Orthotics and prosthetics</li>
                <li>Mental Health & Ambulance Service</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-6">
            <div className="custom-list">
              <ul>
                <li>Medicare Supplement Plan F</li>
                <li>Medigap Plan G</li>
                <li>Medicare Supps Plan N</li>
              </ul>
            </div>
          </div>
        </div>
        <BookingSubmissions/>
      </DP>
    </>
  );
}
