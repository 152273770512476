import React from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import styled from "styled-components";
import MH1 from "../../Assets/Medicare/Mhero-1.png";
import TitleFlag from "../Common/TitleFlag/Index";
import mcrl1 from "../../Assets/Medicare/mcrl-1.svg";
import mcrl2 from "../../Assets/Medicare/mcrl-2.svg";
import absmsg from "../../Assets/Medicare/abs-msg.svg";
import Subscribe from "../Home/Subscribe";
import Footer from "../Footer";
import PublicHealthCareAdvantage from "./PublicHealthCare";
import AdvantagesAdv from "./AdvantagesAd";
import AppForm from "../Medicare/AppForm";
import MedicareRecipient from "../Medicare/MedicareRecipient";
import MedicalInsurance from "../Home/MedicalInsurance";
import MedicareRecipient2 from "./MedicareRecipient2";

export default function MedicareAdvantage() {
  return (
    <>
      <Container>
        <MHero>
          <Row className="w-100 h-100" style={{ minHeight: "100%;" }}>
            <Col md={12} lg={6}>
              <div className="mh-content">
                <TitleFlag text="Easy Medicare Signup" />
                <p className="mb-0 title">
                The Senior Digest provides exceptional Medicare Advantage Plans to meet your 
                unique healthcare needs. Our plans offer many benefits beyond Original Medicare, 
                ensuring you receive the best possible care and support.
                </p>
                <div className="sub-form">
                  <p className="mb-3 desclaimer">
                    Speak to a licensed insurance agent
                  </p>
                  <a href="tel:+18668615366" class="desclaimer my-0">
                    <button class="desclaimer my-0">
                      For English: +1 866-861-5366
                    </button>
                  </a>
                  <a href="tel:+18668392310" class="desclaimer me-3">
                    <button class="desclaimer mt-0 me-3">
                      For Spanish: +1 866-839-2310
                    </button>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={12} lg={6}>
              <div className="mh-content-right mt-2">
                <Row>
                  <Col md={6}>
                    <div className="mcr-left-wrapper my-3">
                      <img src={mcrl1} alt="" className="img-fluid" />
                      <p className="title mb-0">
                        Help cover certain hospital and medical deductibles, or
                        coinsurance copays
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mcr-left-wrapper e-layer my-3">
                      <img src={mcrl2} alt="" className="img-fluid" />
                      <p className="title mb-0">
                        May help you save on Medicare out-of-pocket costs
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div className="abs-msg">
            <img src={absmsg} className="img-fluid" alt="" />
            <p className="mb-0 desc">
              Some plans include prescription drug coverage
            </p>
          </div>
        </MHero>
       <PublicHealthCareAdvantage/>
       <AdvantagesAdv/>
      </Container>
      <Container>
        <AppForm/>
      </Container>
      {/* <MedicareTypes /> */}
      {/* <Container>
        <AppForm />
        <MedicareArr />
      </Container>
      <BookService /> */}
      <Container>
        <MedicareRecipient2/>
        <MedicalInsurance title="Related Blogs" />
        <Subscribe />
        <Footer />
      </Container>
    </>
  );
}

const MHero = styled.div`
  width: 100%;
  min-height: 70vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.36)),
    url(${MH1});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 12px;
  position: relative;
  .sub-form {
    .input-wrapper {
      input {
        height: 45px;
        width: 55%;
        border-radius: 5px;
        border: 1px solid #22d1a3;
        background: #fff;
        padding-left: 10px;
        font-size: 12px;
        outline: none;
        &::placeholder {
          color: #848484;
          font-family: Tienne;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      .sub-btn {
        height: 45px;
        outline: none;
        padding: 0 20px;
        border: none;
        border-radius: 5px;
        background: #22d1a3;
        color: #fff;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: -10px;
      }
    }
    .desclaimer {
      color: #848484;
      font-family: Tienne;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 50ch;
      margin-top: 1rem;
      outline: none;
      border: none;
      background: none;
    }
  }
  .mh-content {
    height: 100%;
    width: 75%;
    border-radius: 6px;
    background: #fff;
    padding: 2rem 1rem;
    @media (max-width: 600px) {
      margin: 1rem 0;
      width: 100%;
    }
    .title {
      color: #3a3a3a;
      font-family: Tienne;
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 18ch;
      @media (max-width: 600px) {
        font-size: 30px;
        font-weight: 500;
      }
    }
    .desc {
      color: #3a3a3a;
      font-family: Tienne;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 5px;
    }
  }
  .mh-content-right {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    .mcr-left-wrapper {
      height: 180px;
      width: 100%;
      border-radius: 6px;
      border: 1px solid #fff;
      background: rgba(115, 115, 115, 0.44);
      backdrop-filter: blur(9.300000190734863px);
      padding: 1rem;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .title {
        color: #fff;
        font-family: Tienne;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px;
      }
    }
    .e-layer {
      border-radius: 6px;
      background: #4c9784 !important;
    }
  }
  .abs-msg {
    padding: 1rem;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 6px;
    border: 1px solid #22d1a3;
    background: #fff;
    backdrop-filter: blur(9.300000190734863px);
    .desc {
      color: #3a3a3a;
      font-family: Tienne;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 20px;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
`;
