import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import BD from "../../../../Assets/Blogs/BJP-1.jpg";
import Subscribe from "../../../Home/Subscribe";
import MedicalInsurance from "../../../Home/MedicalInsurance";
import TrendingComp from "../../../Common/Trending";
import Footer from "../../../Footer";
export default function BlogDetailsJointPain() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <BlogStyles>
              <p className="mb-0 bs-title">Home Remedies To Treat Back Pain</p>
              <div className="w-100 auth-flex">
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
                <div className="share-social">
                  <FaFacebook size={15} color="#8A8A8A" />
                  <FaXTwitter size={15} color="#8A8A8A" className="mx-3" />
                  <FaInstagram size={15} color="#8A8A8A" />
                </div>
              </div>
              <div className="detail-content">
                <img src={BD} className="img-fluid w-100 my-2" />
                <p className="dc-desc">
                  Are you the one suffering with back pain? Don't worry, a
                  number of people are your companions in this matter. Majority
                  of the aged people, sooner or later, are more likely to be
                  affected by lower back ache. As according to a research, four
                  out of five senior people suffer with this pain.
                </p>
                <p className="dc-desc">
                  In order to kick off your back ache out of your life, step up
                  and practically do something. For this reason, first and the
                  foremost thing that you need to adopt immediately is using
                  natural home remedies. This will thus, help you reduce the
                  intake of medications by treating your back pain and distress
                  naturally.
                </p>
                <p className="mb-0 dc-title-2">
                  How to treat back pain at home?
                </p>
                <p className="dc-desc">
                  Here are some remedies that you can easily afford and begin
                  with, at your home, if your pain is mild. However, if your
                  back doesn't get relief and the pain continues to intensify
                  for a long time period, stop struggling with the home remedies
                  and turn to your doctor for a proper medical treatment.
                </p>
                {/* <p className="dc-title">The Four Parts of Medicare</p> */}
                <p className="mb-0 dc-title-2">
                  Use oils and pain relieving ointments
                </p>
                <p className="dc-desc">
                  The use of essential oils and pain reliever ointments are
                  highly beneficial as they suck all your pain in very little
                  time, by blocking the sensation. For instance, use creams that
                  contain capsaicin which help in the desensitization of the
                  pain channels in the agonizing area. Other ointments that you
                  can look for are;
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Ibuprofen (Nurofen)</li>
                    <li>Diclofenac (Voltaren)</li>
                    <li>Lidocaine (Lidoderm)</li>
                    <li>Comfrey</li>
                    <li>Cayenne</li>
                    <li>Ketoprofen (Diractin)</li>
                    <li>Ibuprofen (Nurofen)</li>
                    <li>Diclofenac (Voltaren)</li>
                    <li>Lidocaine (Lidoderm)</li>
                    <li>Ketoprofen (Diractin)</li>
                  </ul>
                </div>
                <p className="dc-desc">
                  All these above mentioned medications come in the form of
                  gels, patches, creams, or any other form. Beware of directly
                  applying the ointments on the affected areas as some people
                  have allergic skin which might get worse after the application
                  of the medication. Make sure to do a patch test and then use
                  it normally.
                </p>

                <p className="mb-0 dc-title-2">
                  Gentle massage for easing pain
                </p>
                <p className="dc-desc">
                  Massage is one of the best solutions of your back problems.
                  Gentle massaging of your back can play a magic spell and will
                  miraculously relieve your backache. The benefit of massage
                  therapies is that it takes off all the pressure and stress
                  form your aching back and blesses you with relief and comfort.
                  Along with the reduction of pain, it plays a vital role in
                  improving your back function and helps you lessen down the use
                  of anti-inflammatory medicines. Furthermore, it relaxes all
                  your muscles in a very short time period.
                </p>

                <p className="mb-0 dc-title-2">Exercise and body stretches</p>
                <p className="dc-desc">
                  Another wonderful home remedy that is really easy to practice
                  is the stretching of your body on daily basis. Human body gets
                  tired up even after you sleep for a long time. So, it is
                  necessary to stretch your body every day. Once you wake up,
                  allow your muscles to wake up too. Staying in the bed, slowly
                  stretch your arms and legs in order to wake up your back. Be
                  cautious if you are suffering with severe twinges or pains in
                  the body and try not to practice this exercise.
                </p>

                <p className="mb-0 dc-title-2">Apply cold or hot pads</p>
                <p className="dc-desc">
                  The use of either hot or cold pads can also be an effective
                  soothing remedy. It depends on your choice and ease, if you
                  need heat or coldness for your affected area. Use the pads
                  that makes you feel good and relaxed. Unfortunately, this
                  remedy is not really a quick one but it's pretty healing and
                  soothing in its own. The use pf heating pads can help in
                  comforting your muscles, and eventually pain, by increasing
                  the blood flow in your body. Likewise, cold pads are also
                  useful as its coolness reduces down the inflammation and the
                  sensation of pain.
                </p>
                <p className="mb-0 dc-title-2">
                  Try to strengthen your core back muscle and tissues
                </p>
                <p className="dc-desc">
                  Another remedy that can result in speedy recovery is the
                  strengthening up of the core of your back muscles and
                  ligaments. Your core is the key area as it consists of various
                  muscle groups in your abdomen, sides and back. Their basic
                  purpose is to keep up your spine aligned. that needs to be
                  strong. Therefore, you need to look for a trainer who will
                  help you learn soothing exercises. Learn and practice those
                  core exercises on regular basis to get effective results.
                </p>

                <p className="mb-0 dc-title-2">Conclusion</p>
                <p className="dc-desc">
                  Back pain can disrupt your daily life, but you don't always
                  need medication or medical procedures to find relief. By
                  incorporating these home remedies and lifestyle changes into
                  your routine, you can take proactive steps to manage and
                  alleviate back pain naturally. Remember that the effectiveness
                  of these remedies may vary, so consult with a healthcare
                  professional to determine the best approach for your specific
                  condition. With patience and consistency, you can improve your
                  comfort and well-being.
                </p>
              </div>
              <MedicalInsurance title="Related Blogs" />
              <Subscribe />
            </BlogStyles>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TrendingComp />
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

const BlogStyles = styled.div`
  width: 100%;

  .bs-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 21ch;
  }
  .author {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .auth-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dc-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dc-desc {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }

  .dc-title-2 {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .dc-list {
    ul {
      li {
        color: #3a3a3a;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.2rem;
      }
    }
  }
`;
