import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import HE1 from "../../../Assets/Home/Health/He-1.png";
import HE2 from "../../../Assets/Home/Health/He-2.png";
import HE3 from "../../../Assets/Blogs/BTN-1.jpg";
import TitleFlag from "../../Common/TitleFlag/Index";
import { Trendinglist } from "../../Common/StaticData/Trending";
import Tags from "../../Common/Tags";
import { TrendingStylesList, HealthContentStyles } from "../../Shared/SharedStyles";
import { Link } from "react-router-dom";

export default function HomeTrending() {
  return (
    <>
      <Row>
        <Col md={8}>
          <Row>
            <Col md={12} lg={5}>
              <img src={HE1} alt="" className="img-fluid w-100" />
            </Col>
            <Col md={12} lg={7}>
              <HealthContentStyles>
                <TitleFlag text="Health" />
                <Link to="/blogs/what-causes-neck-pain" className="title">What causes neck pain?</Link>
                <p className="desc">
                  The human neck is the most vital and one of the sensitive
                  parts of the body that is comprised of spinal bones,
                  ligaments, muscles and soft tissues...
                </p>
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
              </HealthContentStyles>
            </Col>
          </Row>

          <Row className="my-5">
            <Col md={12} lg={6}>
              <HealthContentStyles>
                <img src={HE2} alt="" className="img-fluid w-100" />
                <Link to="/blogs/treat-back-pain" className="title">Home Remedies To Treat Back Pain</Link>
                <p className="desc">
                Are you the one suffering with back pain? Don't worry...
                </p>
                <p className="author">By Afrasiab Khatak | May 5, 2024</p>
              </HealthContentStyles>
            </Col>
            <Col md={12} lg={6}>
              <HealthContentStyles>
                <img src={HE3} alt="" className="img-fluid w-100" />
                <Link to="/blogs/types-of-neck-pain" className="title">Types Of Neck Pain In Seniors</Link>
                <p className="desc">
                Neck pain, one of the severest spinal column pains, is quite common among people who belong to the age group of 60-70 years or above...
                </p>
                <p className="author">By Afrasiab Khatak | May 5, 2024</p>
              </HealthContentStyles>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <TrendingStylesList>
            <div className="trending-start">
              <p className="mb-0 ts-title">Trending</p>
            </div>
            {Trendinglist.map((item, index) => (
              <Row key={index} className="mb-4 reverse-column">
                <Col md={9}>
                  <div className="d-flex h-100 align-items-start justify-content-evenly flex-column">
                    <p className="mb-0 title">{item.title}</p>
                    <div className="name-details">
                      <p className="mb-0">By {item.author}</p>
                      <p className="mb-0">|</p>
                      <p className="mb-0">{item.date}</p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <img src={item.img} className="img-fluid my-2" alt={item.title} />
                </Col>
              </Row>
            ))}
          </TrendingStylesList>
            <TrendingTags>
                <Tags text="Health"/>
                <Tags text="Diabetes"/>
                <Tags text="Medicare"/>
                <Tags text="Final Expense"/>
                <Tags text="Testing"/>
                <Tags text="Flu"/>
                <Tags text="Vitamins"/>
                <Tags text="Fever"/>
                <Tags text="Dengue"/>
            </TrendingTags>
        </Col>
      </Row>
    </>
  );
}


const TrendingTags = styled.div`
    width:100%;
    display:flex;
    align-items:flex-start;
    justify-content:flex-start;
    flex-wrap:wrap;
`