import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  FloatingLabel,
  Col,
  Row,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";
import styled from "styled-components";
import MD1 from "../../Assets/Medicare/icons/MD-1.svg";
import BGMD from "../../Assets/Medicare/MD1-bg.jpg";
import axios from "axios";
export default function GetInTouch(props) {
  const initialFormValues = {
    fullName: "",
    email: "",
    overSixty: "",
    phoneNumber: "",
    agree: false,
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [alertMessage, setAlertMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "phoneNumber") {
      const input = value.replace(/\D/g, "");
      const formattedPhoneNumber = formatPhoneNumber(input);
      setFormValues({
        ...formValues,
        [name]: formattedPhoneNumber,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };
  const validateForm = () => {
    const { fullName, email, overSixty, phoneNumber, agree } = formValues;
    if (!fullName || !email || !overSixty || !phoneNumber || !agree) {
      setAlertMessage("All fields are required.");
      return false;
    }
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setAlertMessage(
        "Please enter a valid US phone number (e.g., (123) 456-7890)."
      );
      return false;
    }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setTimeout(() => setAlertMessage(""), 5000);
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "https://solar-backend-qa7k.onrender.com/api/sd-modal-form",
        formValues
      );
      if (response.status === 200) {
        setAlertMessage(
          `Form submitted successfully! We Will contact your shortly.`
        );
        setFormValues(initialFormValues);
        // setTimeout(() => props.hide(), 6000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setAlertMessage(error.response.data.error);
    } finally {
      setIsSubmitting(false);
      setTimeout(() => setAlertMessage(""), 5000);
    }
  };
  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };
  return (
    <PrimaryModalStyles>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <ModalStyless>
            {alertMessage && <Alert variant="warning">{alertMessage}</Alert>}
            <Row className=" m-0 w-100">
              <Col lg={6} md={12} sm={12} className="px-0">
                <div className="md-content">
                  <div className="icon">
                    <img src={MD1} alt="" />
                  </div>
                  <p className="md-content-title mb-0">Get your Heart Score</p>
                  <p className="md-content-desc">
                    Know your heart's health with a personalized Heart Score
                    assessment. Take control, prioritize prevention
                  </p>

                  <div className="form-data">
                    <Form
                      onSubmit={handleSubmit}
                      className="floating-label-custom"
                    >
                      <Row>
                        <Col md={12}>
                          <FloatingLabel
                            controlId="floatingFullName"
                            label="Full Name"
                            className=" custom-f-input"
                          >
                            <Form.Control
                              type="text"
                              name="fullName"
                              placeholder="Full Name"
                              value={formValues.fullName}
                              onChange={handleChange}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md={12}>
                          <FloatingLabel
                            controlId="floatingEmail"
                            label="Email"
                            className=" custom-f-input mt-2"
                          >
                            <Form.Control
                              type="email"
                              name="email"
                              placeholder="Email"
                              value={formValues.email}
                              onChange={handleChange}
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FloatingLabel
                            controlId="floatingPhoneNumber"
                            label="Phone Number"
                            className="custom-f-input mt-2"
                          >
                            <Form.Control
                              type="text"
                              name="phoneNumber"
                              placeholder="Phone Number"
                              value={formValues.phoneNumber}
                              onChange={handleChange}
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Form.Group className="mt-2">
                        <Form.Label className="custom-form-label">
                          Are you over 60 Years of age?
                        </Form.Label>
                        <div className="d-flex w-100 d-flex align-items-center justify-content-start flex-wrap">
                          <Form.Check
                            type="radio"
                            name="overSixty"
                            label="Yes"
                            value="yes"
                            checked={formValues.overSixty === "yes"}
                            onChange={handleChange}
                            className="text-light-c"
                          />
                          <Form.Check
                            type="radio"
                            name="overSixty"
                            label="No"
                            value="no"
                            checked={formValues.overSixty === "no"}
                            onChange={handleChange}
                            className="text-light-c ms-5"
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="mt-2 d-flex align-items-center justify-content-start">
                        <Form.Check
                          type="checkbox"
                          name="agree"
                          label="I agree to receive calls as explained under the button below."
                          checked={formValues.agree}
                          onChange={handleChange}
                          className="custom-form-label"
                        />
                      </Form.Group>
                      <Form.Group className="mt-2 d-flex align-items-center justify-content-start">
                        <Form.Check
                          type="checkbox"
                          name="terms"
                          label="I agree with Terms & conditions."
                          checked={formValues.terms}
                          onChange={handleChange}
                          className="custom-form-label"
                        />
                      </Form.Group>
                      <button className="btn-submit-score" type="submit">
                        {isSubmitting ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            Submitting...
                          </>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </Form>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} className="custom-bg p-0"></Col>
            </Row>
          </ModalStyless>
        </Modal.Body>
      </Modal>
    </PrimaryModalStyles>
  );
}

const PrimaryModalStyles = styled.div`
  .modal-body {
    padding: 0px;
  }
`;

const ModalStyless = styled.div`
  width: 100%;

  /* CustomForm.css */
  .floating-label-custom .form-control:focus,
  .floating-label-custom .form-select:focus {
    border-color: #22d1a3;
    box-shadow: 0 0 0 0.25rem rgba(34, 209, 163, 0.25);
  }

  .floating-label-custom .form-check-input:checked {
    background-color: #22d1a3;
    border-color: #fff;
  }

  .floating-label-custom .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(34, 209, 163, 0.25);
  }

  .floating-label-custom .form-check-input[type="radio"]:checked {
    background-color: #22d1a3;
  }

  .floating-label-custom .form-check-input[type="checkbox"]:checked {
    background-color: #22d1a3;
    border-color: #fff;
  }

  .floating-label-custom .form-check-input[type="checkbox"]:focus {
    box-shadow: 0 0 0 0.25rem rgba(34, 209, 163, 0.25);
  }
  .custom-bg {
    background-image: url(${BGMD});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 16.086px;
    box-shadow: -12.869px 1.609px 31.449px 0px rgba(0, 0, 0, 0.15);

    @media (max-width: 800px) {
      display: none;
    }
  }
  .custom-f-input {
    color: #848484;
    text-align: center;
    font-family: Tienne;
    font-size: 12.869px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .custom-form-label {
    color: #fff;
    text-align: center;
    font-family: Tienne;
    font-size: 12.869px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .text-light-c {
    color: #fff;
    text-align: center;
    font-family: Tienne;
    font-size: 12.869px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .btn-submit-score {
    border-radius: 6.435px;
    background: #fff;
    width: 100%;
    height: 55px;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #848484;
    text-align: center;
    font-family: Tienne;
    font-size: 16.086px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .md-content-title {
    color: #fff;
    font-family: Tienne;
    font-size: 28.955px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .md-content-desc {
    color: #fff;
    text-align: center;
    font-family: Tienne;
    font-size: 12.869px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 60ch;
  }
  .md-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 16.086px;
    background: #22d1a3;
    padding: 1rem;
    .icon {
      width: 55px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 50%;

      img {
        width: 40px;
        height: auto;
      }
    }
  }
`;
