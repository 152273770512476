import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import pbcillus from "../../../Assets/Medicare/phc-1.png";
import TitleFlag from "../../Common/TitleFlag/Index";
export default function PublicHealthCareAdvantage() {
  return (
    <>
      <PHC>
        <Row>
          <Col
            md={5}
            className="d-flex align-items-center justify-content-center"
          >
            <img src={pbcillus} className="pub-img" alt="" />
          </Col>
          <Col md={7}>
            <TitleFlag text="Why Choose Medicare Advantage Plans?" />
            <p className="title">
              Medicare Advantage Plans referred to as Medicare Part C, combine
              the benefits of Medicare Parts A and B with additional coverage
              options that cater to your overall well-being. Here's why our
              plans stand out:
            </p>

            <p className="mb-0 title">Medicare Part A (Hospital Insurance):</p>
            <p className="desc">
              It includes coverage for hospital stays, skilled nursing facility
              care, hospice services, and home healthcare.
            </p>

            <p className="mb-0 title">Medicare Part B (Medical Insurance):</p>
            <p className="desc">
            Covers sure doctors' services, outpatient care, medical supplies, and 
            preventive services.
            </p>

            
            <p className="mb-0 title">Medicare Part C (Medicare Advantage Plans):</p>
            <p className="desc">
            Many carriers offer the Medicare Part C Advantage plan, including all benefits and 
            services covered under Parts A and B, usually prescription drug coverage (Part D). 
            Additional benefits may include vision, hearing, and dental coverage may also be 
            offered.
            </p>

            <p className="mb-0 title">Medicare Part D (Prescription Drug Coverage):</p>
            <p className="desc">
            Cover the cost of most of the coverage for prescription medications 
            and can be obtained either as a standalone plan or included within a 
            Medicare Advantage Plan.
            </p>
          </Col>
        </Row>
      </PHC>
      <Facts>"11.8 million people had a Medicare plan in 2015."</Facts>
    </>
  );
}

const PHC = styled.div`
  width: 100%;
  min-height: 250px;
  border-radius: 12px;
  border: 1px solid #22d1a3;
  margin-top: 1.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .pub-img {
    width: 300px;
    height: auto;
  }
  .title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .desc {
    color: #3a3a3a;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const Facts = styled.div`
  width: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: Tienne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 12px;
  background: #4c9784;

  @media (max-width: 991px) {
    font-size: 14px;
    padding: 1rem;
  }
`;
