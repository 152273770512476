import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import BD from "../../../../Assets/Blogs/BTN-1.jpg";
import Subscribe from "../../../Home/Subscribe";
import MedicalInsurance from "../../../Home/MedicalInsurance";
import TrendingComp from "../../../Common/Trending";
import Footer from "../../../Footer";
export default function BlogDetailsNeckPainTypes() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <BlogStyles>
              <p className="mb-0 bs-title">Types Of Neck Pain In Seniors</p>
              <div className="w-100 auth-flex">
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
                <div className="share-social">
                  <FaFacebook size={15} color="#8A8A8A" />
                  <FaXTwitter size={15} color="#8A8A8A" className="mx-3" />
                  <FaInstagram size={15} color="#8A8A8A" />
                </div>
              </div>
              <div className="detail-content">
                <img src={BD} className="img-fluid w-100 my-2" />
                <p className="dc-desc">
                  Neck pain, one of the severest spinal column pains, is quite
                  common among people who belong to the age group of 60 -70
                  years or above. Early or late, senior/ elderly people have to
                  go through this type of pain and discomfort. Therefore, to
                  relieve the agony, you must be aware of what actually is neck
                  pain and why does it instigate at such a crucial age.
                </p>
                <p className="mb-0 dc-title-2">What is neck pain?</p>
                <p className="dc-desc">
                  A number of cervical vertebrae combine together to form a neck
                  that extends from right below the skull to the upper torso.
                  The neck supports our head and allows it to move freely. Thus,
                  any sort of abnormality, deformity, or injury in the neck
                  ligaments and muscles, eventually results in neck pain or
                  stiffness. Also, the wear and tear through which our spines
                  undergo on daily basis cause deteriorating spinal conditions.
                </p>
                <p className="dc-desc">
                  Such a type of state, where a person feels tightening in the
                  upper spinal column, is more likely to occur in elderly people
                  which causes pain in their necks, shoulders, and its adjoining
                  areas. In some cases, neck pain turns into a serious illness
                  that then requires immediate medical attention, while most of
                  the time this pain is curable and can be relieved in few days,
                  after proper medical treatment.
                </p>
                <p className="dc-title-2">Causes of Neck Pain</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Neck injury/ fracture</li>
                    <li>Muscle strain/ tension</li>
                    <li>Nerve compression</li>
                    <li>Fibromyalgia</li>
                    <li>Osteoporosis</li>
                    <li>Arthritis</li>
                    <li>Neck injury/ fracture</li>
                    <li>Muscle strain/ tension</li>
                    <li>Nerve compression</li>
                    <li>Fibromyalgia</li>
                    <li>Osteoporosis</li>
                    <li>Arthritis</li>
                  </ul>
                </div>
                <p className="mb-0 dc-title-2">Types of Neck Pain in Seniors</p>
                <p className="dc-desc">
                  Following are the seven most common types of neck pain that
                  could possibly occur in senior people;
                </p>
                <p className="mb-0 dc-title-2">Muscle Pain</p>
                <p className="dc-desc">
                  Muscle strains and tears are common in aged people as it
                  occurs due to prolonged physical or mental stress. The worse
                  the muscle strains and sprains are, the worse the inflammation
                  gets leading to swelling and severe pain in the neck. Hard
                  knots tend to develop eventually causing further muscle pain
                  and soreness.
                </p>

                <p className="mb-0 dc-title-2">Headache</p>
                <p className="dc-desc">
                  Headache related to neck pain is also a severe pain that is
                  usually felt at the back of the head and its surrounding neck
                  area. Worse muscle tension is the major cause of headache as
                  it is accompanied by neck stiffness and tenderness.
                </p>
                <p className="mb-0 dc-title-2">Muscle Spasm</p>
                <p className="dc-desc">
                  Muscle spasm is a result of painful contraction and tightening
                  of neck muscles. Generally, people who wake up with a stiff
                  neck are more likely to be the sufferers of muscle spasms.
                  Basically, it results in the inability of the neck to move in
                  different directions.
                </p>

                <p className="mb-0 dc-title-2">Nerve Pain</p>
                <p className="dc-desc">
                  In this type of neck pain, certain spinal nerves ache and
                  cause irritation and pinching in the whole neck. The pain may
                  extend and reach down the arm or into the hand along with
                  feelings of pin and needles or burning sensations.
                </p>
                <p className="mb-0 dc-title-2">Bone Pain</p>
                <p className="dc-desc">
                  Bone pain is a perilous form of neck pain than other types of
                  neck pain as in this condition, harsh tenderness occurs in the
                  cervical vertebrae. This condition occurs due to severe injury
                  of the soft tissues of the cervical spine. Moreover, bone pain
                  may be a sign of bone fracture or weakness which could even
                  put your spinal cord at risk.
                </p>
                <p className="mb-0 dc-title-2">Referred Pain</p>
                <p className="dc-desc">
                  In this type of pain, aching is triggered in one part of the
                  body but appears in another part of the body. For instance, a
                  heart attack might cause pain in the neck and shoulders.
                  Therefore, instant treatment must be given to the patient in
                  order to avoid any further damage.
                </p>
                <p className="mb-0 dc-title-2">Facet Joint Pain</p>
                <p className="dc-desc">
                  Particular points in the vertebral column where two adjacent
                  vertebrae meet are known as 'facet joints'. Sharp and deep
                  pain in these joints develops with an acute injury or
                  arthritic degradation. The pain may radiate in the whole upper
                  body making the pain grow worse.
                </p>
                <p className="mb-0 dc-title-2">Treatment of Neck Pain</p>
                <p className="dc-desc">Once you are clear about your type of neck pain and its symptoms, see the doctor as soon as possible. The doctor, after examining your condition thoroughly, will either advise you of medical treatment or will prescribe you to adopt home remedies.</p>

                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Muscle relaxants</li>
                    <li>Pain relievers</li>
                    <li>Gentle Massage</li>
                    <li>Neck collar</li>
                    <li>Muscle therapies</li>
                    <li>Use of heating pads</li>
                    <li>Surgery</li>
                    <li>Muscle relaxants</li>
                    <li>Pain relievers</li>
                    <li>Gentle Massage</li>
                    <li>Neck collar</li>
                    <li>Muscle therapies</li>
                    <li>Use of heating pads</li>
                    <li>Traction</li>
                    <li>Surgery</li>
                  </ul>
                </div>
              
              </div>
              <MedicalInsurance title="Related Blogs" />
              <Subscribe />
            </BlogStyles>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TrendingComp />
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

const BlogStyles = styled.div`
  width: 100%;

  .bs-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 21ch;
  }
  .author {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .auth-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dc-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dc-desc {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }

  .dc-title-2 {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .dc-list {
    ul {
      li {
        color: #3a3a3a;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.2rem;
      }
    }
  }
`;
