import React from "react";
import styled from "styled-components";
import { MedicareHomeArr } from "../StaticData/HeroMedicare";
import TitleFlag from "../TitleFlag/Index";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function MedicareArr() {
  return (
    <>
      <ImpMedicare>
        <div className="mb-4">
          <TitleFlag text="Important" />
        </div>
        <Row>
          {MedicareHomeArr.map((item, index) => (
            <Col key={index} md={3} sm={3}>
              <Link className="mb-0 imw-title" to={item.path}>
                <ImpMedicareWrapper>
                  <div className="image-container">
                    <img src={item.img} alt="" className="img-fluid" />
                    <div className="overlay">
                      <div className="overlay-text">Read More</div>
                    </div>
                  </div>
                  <p className="mb-0 flag">{item.flag}</p>
                  <p className="mb-0 imw-title">{item.title}</p>
                </ImpMedicareWrapper>
              </Link>
            </Col>
          ))}
        </Row>
      </ImpMedicare>
    </>
  );
}

const ImpMedicare = styled.div`
  width: 100%;
  min-height: 550px;
  margin: 2rem 0;
`;

const ImpMedicareWrapper = styled.div`
  margin-bottom: 1rem;

  .image-container {
    position: relative;
    overflow: hidden;
    border-radius: 5px;

    img {
      display: block;
      width: 100%;
      border-radius: 5px;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(34, 209, 163, 0.36);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;
      border-radius: 5px;
    }

    &:hover .overlay {
      opacity: 1;
    }

    .overlay-text {
      color: #3a3a3a;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background:#fff;
      padding:1rem;
      border-radius:5px;
    }
  }

  .flag {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 1rem 0;
  }
  .imw-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0.5rem;
    max-width: 16ch;
    transition: 1s all;
    &:hover {
      color: #22d1a3;
      transition: 1s all;
    }

    @media (max-width: 600px) {
      max-width: 100ch;
    }
  }
`;
