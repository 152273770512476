import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import EbookI from '../../../Assets/Home/ebook-bg.png'
export default function Ebook() {
  return (
    <>
      <EbookStyles>
        <p className="mb-0 title">The Ultimate Diabetes meal plan</p>
        <p className="desc">Your Health is Our Priorty!</p>
        <button className="download-ebook">Download eBook Now!</button>
      </EbookStyles>
    </>
  );
}

const EbookStyles = styled.div`
  width: 100%;
  min-height: 320px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 4rem;
  border-radius: 5px;
  background: linear-gradient(-270deg, #FF6E83 0%, rgba(153, 66, 79, 0.10) 71.72%), url(${EbookI});
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin:4rem 0;

  @media(max-width:600px){
    padding-left:1rem;
  }

  .title {
    color: #fff;
    font-family: Tienne;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 20ch;
  }
  .desc {
    color: #fff;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin:1.5rem 0;
  }

  .download-ebook {
    border-radius: 5px;
    background: #3a3a3a;
    width: 35%;
    height: 45px;
    outline: none;
    border: none;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width:600px){
    width:70%;
    font-size:12px;
  }
  }
`;
