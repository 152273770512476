import React from "react";
import styled from "styled-components";
import EC1 from "../../../Assets/Common/ElderCare/EC-1.png";
import EC2 from "../../../Assets/Common/ElderCare/EC-2.png";
import EC3 from "../../../Assets/Common/ElderCare/EC-3.png";
import EC4 from "../../../Assets/Common/ElderCare/EC-4.png";
import TitleFlag from "../../Common/TitleFlag/Index";
import { Link } from "react-router-dom";

export default function ElderCare() {
  return (
    <>
      <ElderCareStyles>
        <TitleFlag text="Elderly Care" />
        <div className="care-wrapper my-5">
          <Link to="/eldery-care/blogs/comprehensive-care" className="ec-1">
            <p className="mb-0 title">
            Comprehensive Care For The Elderly
            </p>
            <p className="mb-0 author">By Afrasiab Khatak | May 5, 2024</p>
          </Link>
          <Link to="/eldery-care/blogs/elder-diet" className="ec-2">
            <p className="mb-0 title">Diet In The Elderly</p>
            <p className="mb-0 author">By Afrasiab Khatak | May 5, 2024</p>
          </Link>
          <Link to="/eldery-care/blogs/aging" className="ec-3">
            <p className="mb-0 title">
              Concrete Answers to 10 Common Medicare Questions
            </p>
            <p className="mb-0 author">By Afrasiab Khatak | May 5, 2024</p>
          </Link>
          <Link to="/eldery-care/blogs/consider-older" className="ec-4">
            <p className="mb-0 title">
                At What Age Is Considered An Older Adult?
            </p>
            <p className="mb-0 author">By Afrasiab Khatak | May 5, 2024</p>
          </Link>
        </div>
      </ElderCareStyles>
    </>
  );
}

const ElderCareStyles = styled.div`
  width: 100%;
  min-height: 650px;
  .care-wrapper {
    min-height: 650px;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    @media (max-width: 600px) {
      grid-template-rows: repeat(10, 1fr);
    }

    .title {
      color: #fff;
      font-family: Tienne;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 25ch;
    }
    .author {
      color: #fff;
      font-family: Tienne;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 1rem;
    }
  }
  .ec-1 {
    grid-area: 1 / 1 / 6 / 5;
    border-radius: 5px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.36)),
      url(${EC1});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    @media (max-width: 600px) {
      grid-area: 1 / 1 / 4 / 11;
    }
  }
  .ec-2 {
    grid-area: 1 / 5 / 4 / 8;
    border-radius: 5px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.36)),
      url(${EC2});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    @media (max-width: 600px) {
      grid-area: 4 / 1 / 7 / 11;
    }
  }
  .ec-3 {
    grid-area: 1 / 8 / 4 / 11;
    border-radius: 5px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.36)),
      url(${EC4});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;

    @media (max-width: 600px) {
      grid-area: 7 / 1 / 10 / 11;
    }
  }
  .ec-4 {
    grid-area: 4 / 5 / 6 / 11;
    border-radius: 5px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.36)),
      url(${EC3});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;

    @media (max-width: 600px) {
      grid-area: 10 / 1 / 13 / 11;
    }
  }
`;
