import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import BookingSubmissions from "./BookingSubmissions";
import { DP } from "../../../Shared/SharedStyles";
export default function AnnualWellnessPhysical() {
  return (
    <>
      <DP>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="desc mb-0">
              You can enroll in Medicare by phone, online, or in person at your
              local Social Security office.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="desc mb-0">
              Need assistance & guidance in discovering the Medicare Supplement
              Insurance plans that suit you perfectly? Don't wait to secure the
              best Medicare coverage for your needs. Apply for Medicare and
              select the top Medicare Medigap plans to ensure your health and
              peace of mind.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12 col-md-6">
            <div className="custom-list">
              <ul>
                <li>Compare the Plans</li>
                <li>Choose the Carrier</li>
                <li>Call Our Agent</li>
                <li>Filling the Website Form</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-6">
            <div className="custom-list">
              <ul>
                <li>Apply for Medicare Supplement Today</li>
                <li>Quick Claims Payments</li>
                <li>Household Discounts</li>
              </ul>
            </div>
          </div>
        </div>
        <BookingSubmissions/>
      </DP>
    </>
  );
}


