import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Subillus from '../../../Assets/Common/sub-illus.png'
export default function Subscribe() {
  return (
    <>
      <SubscribeStyles>
        <Row className="w-100">
          <Col md={8}>
            <div className="ss-wrapper">
              <div className="content">
                <p className="title mb-0">theseniordigest.com Newsletter</p>
                <p className="desc">
                  Deliver to your inbox: medically certified health and wellness
                  information
                </p>
              </div>
              <div className="sub-form">
                <div className="input-wrapper">
                  <input type="text" placeholder="Enter your email address" />
                  <button className="sub-btn">SUBSCRIBE</button>
                </div>
                <p className="mb-0 desclaimer">
                  By clicking subscribe, I agree to theseniordigest.com Privacy
                  Policy and understand that I may opt out of this subscription
                  at any time.
                </p>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <img src={Subillus} alt="" className="img-fluid" />
          </Col>
        </Row>
      </SubscribeStyles>
    </>
  );
}

const SubscribeStyles = styled.div`
  width: 100%;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .ss-wrapper {
    min-height: 100%;
    height: 100%;

    .content {
      .title {
        color: #3a3a3a;
        font-family: Tienne;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        max-width: 20ch;
        @media(max-width:600px){
          font-size:30px;
        }
      }
      .desc {
        color: #848484;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .sub-form {
      .input-wrapper {
        input {
          height: 45px;
          width: 50%;
          border-radius: 5px;
          border: 1px solid #22d1a3;
          background: #fff;
          padding-left: 10px;
          font-size: 12px;
          outline: none;
          &::placeholder {
            color: #848484;
            font-family: Tienne;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          @media(max-width:600px){
            width:50%;
          }
        }
        .sub-btn {
          height: 45px;
          outline: none;
          padding: 0 20px;
          border: none;
          border-radius: 5px;
          background: #22d1a3;
          color: #fff;
          font-family: Tienne;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-left: -10px;
        }
      }
      .desclaimer{
            color: #848484;
            font-family: Tienne;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            max-width: 50ch;
            margin-top: 1rem;
        }
    }
  }
`;
