import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import BD from "../../../../Assets/Blogs/BDCC-1.jpg";
import Subscribe from "../../../Home/Subscribe";
import MedicalInsurance from "../../../Home/MedicalInsurance";
import TrendingComp from "../../../Common/Trending";
import Footer from "../../../Footer";
export default function BlogDetailsCO() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <BlogStyles>
              <p className="mb-0 bs-title">
                At What Age Is Considered An Older Adult?
              </p>
              <div className="w-100 auth-flex">
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
                <div className="share-social">
                  <FaFacebook size={15} color="#8A8A8A" />
                  <FaXTwitter size={15} color="#8A8A8A" className="mx-3" />
                  <FaInstagram size={15} color="#8A8A8A" />
                </div>
              </div>
              <div className="detail-content">
                <img src={BD} className="img-fluid w-100 my-2" />
                <p className="dc-desc">
                  By aging we understand the result of the effects of the
                  accumulation of various molecular and cellular damages that
                  occur over time throughout life. Those who are within the
                  stage of old age are considered older adults, but at what age
                  is considered Senior?
                </p>
                <p className="dc-desc">
                  The life of the human being is represented by several stages,
                  which although debatable when it begins and ends one and
                  another, we can distinguish the following:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>First age: includes from infancy to youth.</li>
                    <li>
                      Second age: also called middle age, it refers to
                      adulthood.
                    </li>
                    <li>Third age: refers to old age.</li>
                  </ul>
                </div>
                <p className="mb-0 dc-title-2">
                  At what age is considered a Senior Adult?
                </p>
                <p className="dc-desc">
                  The third age is considered in a range of ages close to 60
                  years. For example, in Colombia a person who reaches 55 years
                  of age is considered a senior citizen, in Spain it is
                  considered as of 65 years, while in Mexico, after 60 years of
                  age, they are an older adult.
                </p>
                <p className="mb-0 dc-desc">
                  According to the World Health Organization (WHO), a person is
                  considered to be elderly from 60 years of age in low and
                  middle-income countries, while in high-income countries a
                  person over 65 is considered Elderly.
                </p>
                <p className="dc-desc">
                  During the third age, people no longer have the work
                  obligations or financial burdens that their children
                  represent. Therefore, during this stage of life a new
                  conception of independence is reached. For these reasons, the
                  third age should be considered as a stage of fullness in the
                  life of the human being. However, this is not fulfilled in
                  most cases due to various factors such as: chronic diseases,
                  disabilities, economic dependency, among others.
                </p>

                <p className="mb-0 dc-title-2">
                  The role of the elderly in society
                </p>
                <p className="dc-desc">
                  When older adults reach this stage of life in good health,
                  they can make contributions to their family, community or
                  society after retirement. It is of the utmost importance to
                  reach this stage of life in optimal conditions, in this way it
                  is guaranteed that the older adult has the necessary
                  capacities to carry out the activities that are important to
                  him and to be able to contribute to his family and society.
                </p>
                <p className="dc-desc">
                  The third age is a stage of transitions and adaptations to
                  losses in human life. Some of the transitions will depend on
                  the health conditions of the older adult, such as a change of
                  residence to one that adapts to disabling conditions, social
                  changes, for example retirement and the loss of family and
                  friends.
                </p>

                <p className="mb-0 dc-title-2">Change of address</p>
                <p className="dc-desc">
                  There are various reasons why an older adult chooses or is
                  forced to change their residence. The main objective of the
                  change is to improve their living conditions. However, when
                  this change is forced, the older adult may not take it in the
                  best way. Some of the reasons why you must change your
                  residence are:
                </p>

                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Being in a smaller place that requires less maintenance
                      compared to a large house. Especially when the older adult
                      lives alone.
                    </li>
                    <li>
                      Presence of disabilities Most of the houses do not have
                      the necessary conditions to provide comfort to an older
                      adult with a disability. For example, an older adult with
                      muscle weakness, a user of orthopedic supports or a
                      wheelchair, will find it difficult to move around in a
                      house with stairs or steps between rooms, so they could be
                      confined to a single room.
                    </li>
                    <li>
                      Moving to a home closer to family members and even to
                      their home. In some cases, the older adult creates
                      dependency to carry out daily activities, such as
                      preparing food, shopping or housework. Being close to
                      family members, they can serve as your support network to
                      carry out these activities.
                    </li>
                    <li>
                      Economic deficiencies Not all older adults have a pension
                      or it may be insufficient for their minimum monthly
                      expenses. Moving home with relatives reduces some expenses
                      such as rent or utilities.
                    </li>
                    <li>
                      Lack of support networks. When the older adult does not
                      have family to rely on, and is in adverse economic
                      conditions, it is very likely that they will choose to
                      move to a nursing home or nursing home.
                    </li>
                  </ul>
                </div>

                <p className="mb-0 dc-title-2">Retirement</p>
                <p className="dc-desc">
                  After retirement or retirement from working life, the elderly
                  person goes from an active role to a passive one. In it, they
                  must adapt to changes such as reduced income, alteration of
                  social role and modification of their daily activities and
                  routines. Depending on the reasons for retirement, it will be
                  the adaptive response of the elderly. In other words, if you
                  have retired for medical reasons, retirement is likely to be a
                  problem for you. On the contrary, if your retirement is
                  programmed, it will be a stage of greater fulfillment.
                </p>
                <p className="dc-title-2">Loss of family and friends</p>
                <p className="dc-desc">
                  In the elderly, social life and interpersonal relationships
                  decrease. In this stage, people must face the death of
                  friends, spouse and family. After a loss of this type, the
                  health of the elderly person can be impaired by it. Seniors
                  will be forced to go through a grieving process that will
                  change various aspects of their lives.
                </p>
                <p className="dc-desc">
                  Those who are in mourning are more likely to suffer from mood
                  disorders such as depression or anxiety. During this stage
                  there may be sleep disturbances, eating habits or routine of
                  daily activities. Mourning can affect a person for days,
                  weeks, and even months. However, if it is prolonged and
                  prevents the performance of basic activities, even if they
                  have suicidal ideas, the older adult should be evaluated for
                  early detection and treatment of depression.
                </p>
                <p className="mb-0 dc-title-2">Loss of family and friends</p>
                <p className="dc-desc">
                  In the elderly, social life and interpersonal relationships
                  decrease. In this stage, people must face the death of
                  friends, spouse and family. After a loss of this type, the
                  health of the elderly person can be impaired by it. Seniors
                  will be forced to go through a grieving process that will
                  change various aspects of their lives.
                </p>
                <p className="dc-desc">
                  Those who are in mourning are more likely to suffer from mood
                  disorders such as depression or anxiety. During this stage
                  there may be sleep disturbances, eating habits or routine of
                  daily activities. Mourning can affect a person for days,
                  weeks, and even months. However, if it is prolonged and
                  prevents the performance of basic activities, even if they
                  have suicidal ideas, the older adult should be evaluated for
                  early detection and treatment of depression.
                </p>
                <p className="mb-0 dc-title-2">Stereotypes of old age</p>

                <p className="dc-desc">
                  One of the most important problems faced by older adults, in
                  addition to disabilities secondary to chronic diseases, are
                  the stereotypes that society itself has imposed on them. Older
                  adults are often seen as burdensome, dependent, weak, and even
                  detached from reality.
                </p>
                <p className="dc-desc">
                  All of these concepts, assumptions, and generalizations that
                  apply to older adults are, most of the time, completely wrong.
                  Despite these stereotypes, older people contribute to society
                  in different ways: within the family, most of the time, they
                  help children to care for grandchildren in childhood, and they
                  can become the support during their adolescence. We also
                  observed a part of the elderly population being in charge of
                  sick relatives within the home.
                </p>
                <p className="dc-desc">
                  However, their contributions to the family or to society are
                  little recognized, even the activities they carry out, most of
                  the time, are not remunerated. When retiring from work life,
                  the older adult performs these types of activities such as the
                  need to stay active, feel useful or maintain a lifestyle.
                  Despite the motivation and capacity of the elderly to be able
                  to carry out this type of activities, they must face problems
                  such as discrimination and inequity in old age.
                </p>
                <p className="mb-0 dc-title-2">
                  Discrimination against older adults
                </p>
                <p className="dc-desc">
                  Age discrimination is expressed through prejudicial attitudes
                  and practices that encourage stereotyped treatment of the
                  elderly. The stereotypes placed on the elderly by the same
                  society are conceived from a rigid conception of the stages of
                  life, in which young adults are in the prime of life, while
                  the elderly they are in a process of decline and deterioration
                  in the twilight of life.
                </p>
                <p className="dc-desc">
                  This rigid view of the stages of life sees the years of old
                  age only as a continuation of the years after retirement.
                  However, when the elderly is reached in optimal health and
                  functional capacities, this stage of life can be of great
                  fulfillment and maintain an active life within society. Age
                  discrimination is based on the obvious biological,
                  physiological and psychological deterioration of older adults,
                  which favors unproductivity and reduces levels of
                  independence.
                </p>

                <p className="mb-0 dc-title-2">Inequality in old age</p>
                <p className="dc-desc">
                  Socio-economic conditions are not the same for all seniors.
                  The reasons for these inequalities are mainly a consequence of
                  the previous living conditions of the older adult himself. If
                  during your working life you did not have social security,
                  your income will be affected by not having an old-age pension.
                  This, in turn, will reduce their quality of life, favoring the
                  appearance of diseases and disabilities.
                </p>
                <p className="dc-desc">
                  Health policies must be improved so that all older adults have
                  the same opportunities to improve or maintain their current
                  health. Not all older adults have access to health services,
                  which generates inequality of opportunity among older adults.
                </p>
                <p className="dc-desc">
                  Social policies must also improve, they must be focused on
                  improving the social conditions of the elderly. By improving
                  these, the older adult finds several important health
                  benefits. For example, it is believed that one in five cases
                  of Alzheimer's worldwide is due to a low educational level.
                  Therefore, it has been proposed to considerably reduce the
                  prevalence by improving access to education, in addition to
                  methods focused on reducing the incidence of vascular risk
                  factors and depression.
                </p>

                <p className="dc-title-2 ps-3">Self-perception of Old Age</p>
                <p className="dc-desc">
                  In a survey that I tried to investigate, At what age is
                  considered an Elderly Adult? conducted by the University of
                  the Santo Tomás de Aquino Group Fraternity, 490 adults over 65
                  years of age were interviewed. The survey was carried out
                  within the framework of the slogan “Aging and Health” proposed
                  by the World Health Organization (WHO). The result of the
                  answers gave an average of 68 years of age to be considered as
                  an older adult.
                </p>
                <p className="dc-desc">
                  This survey showed that the perception of the age at which the
                  third age begins increased with the age of the respondents.
                  Only 24% of the elderly, under 71 years of age, feel included
                  within this age group. Something that is striking, being that
                  the WHO establishes the age of 60 years to consider an older
                  adult in low and middle-income countries, while in developed
                  countries it is considered as of 65 years.
                </p>
                <p className="dc-desc">
                  Perhaps this difference between the age to be considered an
                  older adult established by the WHO and the perception of older
                  adults, is the idea of ​​conceiving themselves as old mainly
                  because this stage has been stereotyped with the decrease in
                  capacities, activities and possibilities.
                </p>
              </div>
              <MedicalInsurance title="Related Blogs" />
              <Subscribe />
            </BlogStyles>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TrendingComp />
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

const BlogStyles = styled.div`
  width: 100%;

  .bs-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 21ch;
  }
  .author {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .auth-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dc-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dc-desc {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }

  .dc-title-2 {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .dc-list {
    ul {
      li {
        color: #3a3a3a;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.2rem;
      }
    }
  }
`;
