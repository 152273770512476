import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import BD from "../../Assets/Common/BD-1.png";
import Subscribe from "../Home/Subscribe";
import MedicalInsurance from "../Home/MedicalInsurance";
import Footer from "../Footer";
import TrendingComp from "../Common/Trending";
export default function BlogDetails() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <BlogStyles>
              <p className="mb-0 bs-title">
                Understanding Medicare: A Comprehensive Guide for Beneficiaries
              </p>
              <div className="w-100 auth-flex">
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
                <div className="share-social">
                  <FaFacebook size={15} color="#8A8A8A" />
                  <FaXTwitter size={15} color="#8A8A8A" className="mx-3" />
                  <FaInstagram size={15} color="#8A8A8A" />
                </div>
              </div>
              <div className="detail-content">
                <img src={BD} className="img-fluid w-100 my-2" />

                <p className="dc-desc">
                  Navigating the complexities of healthcare can be daunting,
                  especially for seniors and individuals with disabilities.
                  Medicare, the federal health insurance program, plays a
                  crucial role in providing medical coverage to millions of
                  Americans aged 65 and older, as well as to some younger
                  individuals with specific disabilities. This guide aims to
                  demystify Medicare, explaining its parts, enrolment process,
                  and coverage options, ensuring beneficiaries make informed
                  decisions about their healthcare.
                </p>

                <p className="mb-0 dc-title">What is Medicare?</p>
                <p className="dc-desc">
                  Medicare is a federal health insurance program established in
                  1965 under the Social Security Act. It provides coverage for
                  various healthcare services, reducing the financial burden of
                  medical expenses for eligible individuals. Medicare is divided
                  into four parts: Part A, Part B, Part C, and Part D, each
                  covering different aspects of healthcare.
                </p>
                <p className="dc-title">The Four Parts of Medicare</p>
                <p className="mb-0 dc-title-2">Part A: Hospital Insurance</p>
                <p className="dc-desc">
                  Medicare Part A covers inpatient hospital stays, skilled
                  nursing facility care, hospice care, and some home health
                  services. Most people do not pay a premium for Part A if they
                  or their spouse paid Medicare taxes while working. However,
                  there may be deductibles and coinsurance costs associated with
                  Part A services.
                </p>

                <p className="mb-0 dc-title-2">Part B: Medical Insurance</p>
                <p className="dc-desc">
                  Medicare Part B covers outpatient care, preventive services,
                  doctor visits, and durable medical equipment. Unlike Part A,
                  Part B requires a monthly premium, which is determined based
                  on the beneficiary's income. Additionally, beneficiaries must
                  pay an annual deductible and coinsurance for services.
                </p>

                <p className="mb-0 dc-title-2">Part C: Medicare Advantage</p>
                <p className="dc-desc">
                  Medicare Advantage, or Part C, is an alternative to Original
                  Medicare (Parts A and B). These plans are offered by private
                  insurance companies approved by Medicare and often include
                  additional benefits such as vision, dental, and hearing
                  coverage. Most Medicare Advantage plans also include
                  prescription drug coverage (Part D). Beneficiaries typically
                  pay a monthly premium in addition to the Part B premium and
                  may face different out-of-pocket costs depending on the plan.
                </p>

                <p className="mb-0 dc-title-2">
                  Part D: Prescription Drug Coverage
                </p>
                <p className="dc-desc">
                  Medicare Part D provides coverage for prescription
                  medications. These plans are offered by private insurance
                  companies and vary in terms of covered drugs, costs, and
                  pharmacies. Beneficiaries must enrol in a Part D plan if they
                  want prescription drug coverage, and like Part B, Part D
                  requires a monthly premium, annual deductible, and coinsurance
                  or co-payments.
                </p>

                <p className="mb-0 dc-title">
                  Enrolment Periods and Eligibility
                </p>
                <p className="dc-desc">
                  Understanding the enrollment periods is crucial for
                  beneficiaries to avoid late enrollment penalties and ensure
                  continuous coverage.
                </p>

                <p className="mb-0 dc-title-2">
                  Initial Enrolment Period (IEP)
                </p>
                <p className="dc-desc">
                  The IEP is a seven-month window that begins three months
                  before an individual's 65th birthday, includes the birthday
                  month, and ends three months after. During this period,
                  individuals can <b>enrol</b> in Medicare Parts A and B.
                </p>

                <p className="mb-0 dc-title-2">
                  General Enrolment Period (GEP)
                </p>
                <p className="dc-desc">
                  If an individual misses the IEP, they can enrol during the
                  GEP, which runs from January 1 to March 31 each year. However,
                  late enrolment penalties may apply, and coverage will not
                  start until July 1.
                </p>

                <p className="mb-0 dc-title-2">
                  Special Enrolment Period (SEP)
                </p>
                <p className="dc-desc">
                  Certain circumstances, such as losing employer coverage,
                  moving, or qualifying for Medicaid, allow individuals to enrol
                  outside the IEP and GEP without penalties. The timing and
                  duration of SEPs vary based on the specific situation.
                </p>

                <p className="mb-0 dc-title-2">Annual Enrolment Period (AEP)</p>
                <p className="dc-desc">
                  The AEP, from October 15 to December 7 each year, allows
                  beneficiaries to make changes to their Medicare Advantage and
                  Part D plans. Changes made during AEP take effect on January 1
                  of the following year.
                </p>

                <p className="mb-0 dc-title">
                  Coverage Options and Considerations
                </p>
                <p className="dc-desc">
                  Choosing the right Medicare coverage involves evaluating
                  healthcare needs, budget, and preferences. Beneficiaries can
                  opt for Original Medicare (Parts A and B) with a standalone
                  Part D plan or a Medicare Advantage plan (Part C) that often
                  includes Part D coverage.
                </p>

                <p className="mb-0 dc-title-2">Original Medicare</p>
                <p className="dc-desc">
                  Original Medicare offers flexibility in choosing healthcare
                  providers without needing referrals for specialists.
                  Beneficiaries can visit any doctor or hospital that accepts
                  Medicare. However, Original Medicare does not have an
                  out-of-pocket maximum, which can result in high costs for
                  extensive medical care.
                </p>

                <p className="mb-0 dc-title-2">Medicare Advantage</p>
                <p className="dc-desc">
                  Medicare Advantage plans provide all-in-one coverage,
                  including additional benefits like dental, vision, and
                  hearing. These plans often have lower out-of-pocket costs and
                  include an annual out-of-pocket maximum, providing financial
                  protection. However, beneficiaries may need to use network
                  providers and obtain referrals for specialists.
                </p>

                <p className="mb-0 dc-title-2">
                  Medigap (Medicare Supplement Insurance)
                </p>
                <p className="dc-desc">
                  Medigap policies are sold by private insurance companies to
                  fill the gaps in Original Medicare coverage. These policies
                  help cover out-of-pocket costs such as co-payments,
                  coinsurance, and deductibles. Beneficiaries cannot have both a
                  Medigap policy and a Medicare Advantage plan.
                </p>

                <p className="mb-0 dc-title">Assistance Programs</p>
                <p className="dc-desc">
                  Several programs assist low-income beneficiaries with Medicare
                  costs:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      <b>Medicare Savings Programs (MSPs)</b>: Help pay
                      premiums, deductibles, and coinsurance for Part A and/or
                      Part B
                    </li>
                    <li>
                      <b>Extra Help:</b> Assists with Part D prescription drug
                      costs
                    </li>
                    <li>
                      <b>State Health Insurance Assistance Program (SHIP)</b>{" "}
                      Provides free, unbiased counselling on Medicare options
                      and coverage.
                    </li>
                  </ul>
                </div>

                <p className="mb-0 dc-title">Conclusion</p>
                <p className="mb-0 dc-desc">
                  Medicare is a vital resource for millions of Americans,
                  offering comprehensive coverage for various healthcare needs.
                  By understanding the different parts of Medicare, enrolment
                  periods, and coverage options, beneficiaries can make informed
                  decisions about their healthcare. Whether choosing Original
                  Medicare, Medicare Advantage, or additional coverage like
                  Medigap, it is essential to evaluate individual needs and
                  preferences to ensure the best possible healthcare outcomes.
                </p>
              </div>
              <MedicalInsurance title="Related Blogs"/>
              <Subscribe/>
            </BlogStyles>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TrendingComp/>
          </Col>
        </Row>
        <Footer/>
      </Container>
    </>
  );
}

const BlogStyles = styled.div`
  width: 100%;

  .bs-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 21ch;
  }
  .author {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .auth-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dc-title{
    color: #3A3A3A;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dc-desc{
    color: #3A3A3A;
    font-family: Tienne;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top:1rem;
  }

  .dc-title-2{
    color: #3A3A3A;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .dc-list{
    ul{
        li{
            color: #3A3A3A;
            font-family: Tienne;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom:1.2rem;
        }
    }
  }
`;
