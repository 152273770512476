import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Alert, Spinner } from "react-bootstrap";
import styled from "styled-components";
import Calli from "../../../Assets/Medicare/call.svg";
import axios from "axios";
import { Link } from 'react-router-dom';
export default function AppForm() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    agree: false,
  });
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (responseMessage || errorMessage) {
      const timer = setTimeout(() => {
        setResponseMessage("");
        setErrorMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [responseMessage, errorMessage]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setResponseMessage("");
    if (!formData.name || !formData.phone || !formData.email) {
      setErrorMessage("All fields are required.");
      return;
    }
    if (!formData.agree) {
      setErrorMessage("You must agree to the terms and confirm your age.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "https://solar-backend-qa7k.onrender.com/api/senior-digest-form",
        {
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
        }
      );
      setResponseMessage(response.data.message);
      setFormData({
        name: "",
        phone: "",
        email: "",
        agree: false,
      });
    } catch (error) {
      setResponseMessage("Error submitting form. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AppFormStyles>
        <Row className="w-100">
          <Col
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="afs-wrapper">
              <p className="title">
                Sign Up for an Appointment with the Agent Today!
              </p>
              <p className="desc">
                Take some time to fill out the form and get an appointment with
                an experienced agent.
              </p>
              <div className="line"></div>
              <div className="address">
                <p className="address-c">
                  1400 Hooper Ave, Toms River, New Jersey, 08753
                </p>
                <div className="number-wrapper">
                  <div className="call-icon">
                    <img src={Calli} className="calli" alt="" />
                  </div>
                  <div className="call-number ms-2">
                    <a className="mb-0 number" href="tel:+18668615366">
                      {" "}
                      +1 866-861-5366
                    </a>
                    <p className="mb-0 number-desc">
                      Call Now and Get a FREE English Consultation
                    </p>
                  </div>
                </div>
                <div className="number-wrapper my-2">
                  <div className="call-icon">
                    <img src={Calli} className="calli" alt="" />
                  </div>
                  <div className="call-number ms-2">
                    <a className="mb-0 number" href="tel:+18668392310">
                      {" "}
                      +1 866-839-2310
                    </a>
                    <p className="mb-0 number-desc">
                      Call Now and Get a FREE Spanish Consultation
                    </p>
                  </div>
                </div>
                <div className="timings">
                  <div className="upper-time">
                    <p className="mb-0">Monday - Friday: 07:00am - 10:00pm</p>
                  </div>
                  <div className="upper-time">
                    <p className="mb-0">Saturday - Sunday: 08:00am - 08:00pm</p>
                  </div>
                </div>
                <p className="email">info@theseniordigest.com</p>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="m-form">
              <p className="title mb-0">Make an Appointment</p>
              <Form className="w-100">
                <Form.Group controlId="formName">
                  <Form.Label>Your name*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="m-form-inputs"
                  />
                </Form.Group>

                <Form.Group controlId="formPhone" className="mt-3">
                  <Form.Label>Your phone*</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter your phone number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="m-form-inputs"
                  />
                </Form.Group>

                <Form.Group controlId="formEmail" className="mt-3">
                  <Form.Label>Your Email*</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="m-form-inputs"
                  />
                </Form.Group>

                <Form.Group controlId="formAgree" className="mt-3">
                  <Form.Check
                    type="checkbox"
                    name="agree"
                    label={
                      <span>
                        By clicking the “Submit” button, you hereby agree by your electronic signatures to the{' '}
                        <Link to="/privacy-policy"><b className="text-dark text-decoration-underline">Privacy Policy</b></Link> and{' '}
                        <Link to="/terms"><b className="text-dark text-decoration-underline">Terms & Conditions</b></Link>. You also hereby express written consent to receive 
                        marketing communications including about Medicare Advantage, Medicare Supplement and/or Part D 
                        plans, via automated telephone dialing system and/or pre-recorded calls or artificial voice, text 
                        messages, and/or emails from our “Marketing Partners” and marketing partners at the phone number, 
                        physical address, and email address provided above, even if you are on any State and/or Federal 
                        Do Not Call list. This is a solicitation for insurance. Consent is not a condition of purchase and 
                        may be revoked at any time. Message and data rates may apply California Residents.
                      </span>
                    }
                    checked={formData.agree}
                    onChange={handleChange}
                    className="agree-label"
                  />
                </Form.Group>
                <button
                  type="submit"
                  className="m-form-submit-btn"
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </button>

                {responseMessage && (
                  <Alert className="mt-3" variant="info">
                    {responseMessage}
                  </Alert>
                )}
                {errorMessage && (
                  <Alert className="mt-3" variant="danger">
                    {errorMessage}
                  </Alert>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </AppFormStyles>
    </>
  );
}

const AppFormStyles = styled.div`
  width: 100%;
  min-height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: #edf5f3;
  margin: 3rem 0;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 0.3rem;
  }
  .title {
    color: #112950;
    font-family: Epilogue;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 43.2px;
    max-width: 20ch;

    @media (max-width: 991px) {
      font-size: 20px;
      max-width: 100%;
      line-height: 1.5;
    }
  }
  .desc {
    color: #828ca5;
    font-family: Epilogue;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 50ch;
  }
  .line {
    height: 1px;
    border-top: 1px solid #e1e7ed;
    width: 100%;
    margin-bottom: 1rem;
  }
  .address-c {
    color: #112950;
    font-family: Epilogue;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .number-wrapper {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    .call-icon {
      display: flex;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: #fff;
      @media (max-width: 991px) {
        width: 40px;
        height: 40px;
      }
      .calli {
        width: 50%;
        height: auto;
        @media (max-width: 991px) {
          width: 50%;
        }
      }
    }

    .number {
      color: #112950;
      margin-left: 15px;
      font-family: Epilogue;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 43.2px;
      letter-spacing: -1.44px;
      @media (max-width: 991px) {
        font-size: 16px;
        line-height: 1px;
        letter-spacing: 0px;
      }
    }
    .number-desc {
      margin-left: 15px;
      color: #828ca5;
      font-family: Epilogue;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;

      @media (max-width: 991px) {
        line-height: 1;
        margin-top: 1rem;
      }
    }
  }
  .timings {
    color: #828ca5;
    font-family: Epilogue;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 2rem;
  }
  .email {
    color: #177ee5;
    font-family: Epilogue;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-decoration-line: underline;
    margin: 2rem 0;
  }
  .m-form {
    display: flex;
    width: 100%;
    min-height: 450px;
    padding: 39.39px 41px 40.01px 41px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 27.99px;
    background: #fff;
    @media (max-width: 768px) {
      padding: 15px;
    }
  }
  .m-form-inputs {
    border: 1px solid #f3f7fb;
    background: #f3f7fb;
    display: flex;
    height: 48px;
    padding: 15px 20px 16px 20px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    outline: none;
    border: none;
  }
  .m-form-submit-btn {
    display: flex;
    width: 100%;
    height: 42.5px;
    padding: 24.75px 0px 25.25px 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    background: #4c9784;
    outline: none;
    color: #fff;
    text-align: center;
    font-family: Epilogue;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.5px;
    letter-spacing: -0.15px;
    border: none;
    margin-top: 2rem;
  }
  .agree-label {
    color: #828ca5;
    font-family: Epilogue;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
`;
