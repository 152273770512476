 import Tr1 from '../../../../Assets/Common/Trending/Tr-1.png';
 import Tr2 from '../../../../Assets/Common/Trending/Tr-2.png';
 import Tr3 from '../../../../Assets/Common/Trending/Tr-3.png';
 import DE4 from '../../../../Assets/Blogs/BDTP-1.png';
 
 export const Trendinglist = [
    {
        id:"1",
        title:"10 Tips For A Healthier Future With Type 2 Diabetes",
        author:"Afrasiab Khatak",
        date:'May 5, 2024',
        img:Tr1,
        path:"/diabetes/blogs/healthier-future"
    },
    {
        id:"2",
        title:"10 Important Tests For Type 2 Diabetes",
        author:"Afrasiab Khatak",
        date:'May 6, 2024',
        img:Tr2,
        path:'/diabetes/blogs/important-test'
    },
    {
        id:"3",
        title:"Top 10 Ways To Improve Your Circulation And Blood Flow",
        author:"Afrasiab Khatak",
        date:'May 7, 2024',
        img:Tr3,
        path:"/diabetes/blogs/blood-flow"
    },
    {
        id:"4",
        title:"Tea And Diabetes: 11 Best Teas For People With Diabetes",
        flag:"Diabetes",
        author:"Afrasiab Khatak",
        date:'May 7, 2024',
        img:DE4,
        path:'/diabetes/blogs/diabetes-tea'
    },
 ]