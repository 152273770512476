import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Footer from "../Footer";

export default function Terms() {
  return (
    <>
      <Privacy>
        <Container>
          <p className="title">
            PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING
            THIS SITE.
          </p>
          <p className="desc">Effective date May 25, 2023</p>
          <p className="desc">
            By accessing The Senior Digest, using any of our services and/or
            accessing and using any other websites on which these Terms and
            Conditions reside (collectively also referred to herein as the
            “Site”), or any of the Site's Content (as defined below), you
            acknowledge that you agree to and are subject to the following Terms
            and Conditions, including without limitation, binding arbitration
            (described in Section 2 below), The Senior Digest Privacy Policy,
            E-Sign Consent, and any operating rules, policies, and procedures
            that may be published from time to time on this Site (collectively,
            the “Terms”). NOTE THAT THESE TERMS CONTAIN PROVISIONS THAT GOVERN
            THE RESOLUTION OF DISPUTES BETWEEN US AND YOU AND THAT LIMIT OUR
            LIABILITY TO YOU.
          </p>
          <p className="desc">
            If you do not agree to these Terms, you may not access or otherwise
            use the Site or the Content. The Terms constitute a legally binding
            agreement between you and us, The Senior Digest, the operator of the
            Site (hereafter referred to as “we”, “us” or “our”). References in
            these Terms to “Site operator,” “we”, “us” and “our” include our
            affiliates (including, without limitation, The Senior Digest, Inc.),
            agents, successors and assigns. The Site is available only to, and
            may only be used by, individuals who can form legally binding
            contracts under applicable law. Without limiting the foregoing, the
            Site is intended for and made available only to residents of the
            United States, age 18 or older. By accessing and using the Site, you
            accept and agree to, without qualification, the Terms and represent
            and warrant that you have the authority to bind the User (as defined
            below) to these Terms and that your use of the Site, including the
            provision or use of any Content, does not violate any applicable
            law.
          </p>
          <p className="title">1. Our Services</p>
          <p className="desc">
            The Site acts as a portal to facilitate insurance agents, insurance
            companies, lead aggregators and other similar parties (each a
            “Provider”) to offer for sale and to sell, insurance and other
            products to potential consumers who have applied through the Site to
            receive insurance or other authorized quotations from a Provider
            (each, a “Prospect”), and all Prospects and potential Prospects that
            use the Site are collectively called the Site's “Users” (and
            hereafter may be referred to as “you” or “your”). We may, with a
            Prospect's authorization, collect certain information (a “Lead”)
            about a Prospect and sell the Lead to a Provider. We are not
            responsible for the legality, safety or quality of the products
            offered for sale by a Provider, the accuracy or veracity of the
            representations regarding the products, the ability of Providers to
            sell the products or the ability of Prospects to pay for the
            products. For all purposes we are an independent contractor.
          </p>
          <p className="desc">
            With the exception of certain life insurance transactions, we do not
            issue insurance contracts or bind coverage. We are not responsible
            for, nor do we assume any financial or other liability whatsoever,
            for the conduct of any Prospect or Provider. With the exception of
            certain life insurance transactions, we do not endorse or recommend
            any Providers or insurance policies or other related products
            offered by such Providers. With the exception of certain life
            insurance transactions, we do not provide any type of insurance,
            tax, financial, legal or any other advice. We do not guarantee that
            any Providers to whom we send a Prospect's application will contact
            the Prospect or agree to provide the Prospect with the requested
            insurance coverage. If you would like personal advice or insurance
            specific policy recommendations, please consult with a qualified
            professional. Be advised that we do not offer or sell any insurance
            or other products shown on the Site.
          </p>

          <p className="title">2. Dispute Resolution By Binding Arbitration</p>

          <p className="desc">
            PLEASE READ THIS CAREFULLY. IT AFFECTS YOUR RIGHTS. You agree to
            attempt in good faith to settle any dispute or claim that has or may
            arise between us, which arises out of or relates in any way to these
            Terms or your use of the Site or the Content, including, without
            limitation, any dispute or claim between you and a Provider (each, a
            “Claim”), by way of consultations between you and us, which
            consultations will be initiated upon written notice by any party to
            the other (the “Consultation Notice”). The Consultation Notice must
            describe the nature and basis of the Claim and set forth the
            specific relief sought (“Demand”). If such Claim cannot be resolved
            within thirty (30) days after the Consultation Notice is received,
            any party to the consultations may initiate an arbitration
            proceeding upon written notice to the other party in accordance with
            this Section 2. Any notice to us under this Section 2 should be
            addressed to: info@theseniordigest.com (“Notice Address”).You agree
            to arbitrate all Claims between you and us, or any Provider, that
            cannot be amicably resolved in accordance with the foregoing
            paragraph. This agreement to arbitrate is intended to be broadly
            interpreted.
            <br />
            It includes, but is not limited to: (a) claims arising out of or
            relating to any aspect of your relationship with us, whether based
            in contract, tort, statute, fraud, misrepresentation or any other
            legal theory; and (b) claims that could be alleged as class action
            Claims (and you agree to waive the right to participate in a class
            action in accordance with this Section 2). For the avoidance of
            doubt, references in this Section 2 to “Site operator,” “Provider,”
            “we”, “Prospect”, “you,” and “us” include our respective
            subsidiaries, affiliates, agents, employees, predecessors in
            interest, successors and assigns, as well as all authorized or
            unauthorized users or beneficiaries of the services, information or
            Site Content available through the use the Site. This arbitration
            agreement does not preclude you from bringing an individual action
            in small claims court if your claims qualify, and so long as the
            matter remains in such court and advances only on an individual
            (non-class, non-representative) basis. Further, this arbitration
            agreement does not preclude you from bringing issues to the
            attention of federal, state, or local agencies, including, for
            example, the Federal Communications Commission or the Federal Trade
            Commission.
            <br />
            You agree that, by entering into this arbitration agreement, you and
            we are each waiving our respective rights to a trial by jury or to
            participate in a class or representative action, and that
            arbitration of disputes pursuant to this Section 2 shall be in your
            individual capacity. THIS MEANS YOU ARE LIMITING YOUR RIGHT TO
            APPEAL AND ARE WAIVING YOUR RIGHTS TO OTHER AVAILABLE RESOLUTION
            PROCESSES, SUCH AS A COURT ACTION. THE ARBITRATOR MAY NOT
            CONSOLIDATE, COMBINE, OR JOIN THE CLAIMS OF OTHER PARTIES WHO MAY BE
            SIMILARLY SITUATED OR OTHERWISE PRESIDE OVER ANY FORM OF A
            REPRESENTATIVE OR CLASS PROCEEDING. You acknowledge and agree that
            the Federal Arbitration Act governs the interpretation and
            enforcement of this provision. This arbitration provision shall
            survive termination of this Agreement.
            <br />
            The arbitration will be governed by the American Arbitration
            Association (“AAA”) under its then-prevailing rules and procedures,
            including the AAA's Supplementary Procedures for Consumer-Related
            Disputes (collectively, the “AAA Rules”), as modified by this
            Agreement (but expressly excluding the Supplementary Rules for Class
            Arbitration and any other AAA Rules that conflict with the waiver of
            class arbitration and representative proceedings below), and will be
            administered by one (1) arbitrator with relevant industry experience
            appointed in accordance with the AAA Rules. The AAA Rules are
            available at https://www.adr.org/Rules or by calling (800) 778-7879.
            <br />
            The arbitrator is bound by the terms of this Agreement and shall
            apply Delaware law consistent with the Federal Arbitration Act and
            applicable statutes of limitations, and shall honor claims of
            privilege recognized at law. All issues are for the arbitrator to
            decide, including, without limitation, issues relating to the
            applicability and enforceability of this arbitration agreement.
            <br />
            Unless otherwise mutually agreed by the parties to the arbitration,
            any arbitration hearings under this Section 2 will take place in the
            county where you are domiciled. If your Claim is for $5,000 or less,
            you may choose whether the arbitration will be conducted solely on
            the basis of documents submitted to the arbitrator, through a
            telephonic hearing, or by an in-person hearing as established by the
            AAA Rules. If your Claim exceeds $5,000, the right to a hearing will
            be determined by the AAA Rules. Except as otherwise provided for in
            this Section 2, in any arbitration between you and us under this
            Section 2, all AAA filing, administration and arbitrator fees for
            any arbitration initiated in accordance with the notice requirements
            above where Claims for damages do not exceed $10,000 shall, at your
            written request, be paid by us.
            <br />
            Any request for payment of fees by us shall be submitted by mail to
            the AAA along with your demand for arbitration, and we will make
            arrangements to pay all necessary fees directly to the AAA. If the
            value of the relief sought is more than $10,000 and you are able to
            demonstrate that the costs of arbitration will be prohibitive as
            compared to the costs of litigation, we will pay as much of the
            filing, administration and arbitrator fees as the arbitrator deems
            necessary to prevent the arbitration from being cost-prohibitive.
            <br />
            If, however, the arbitrator finds that either the substance of your
            Claim or the relief sought in the Demand is frivolous or brought for
            an improper purpose, you agree to reimburse us for all monies
            previously disbursed that are otherwise your obligation to pay under
            the AAA Rules.
            <br />
            The arbitrator may award declaratory or injunctive relief only in
            favor of the individual party seeking relief and only to the extent
            necessary to provide relief warranted by that party's individual
            claim. The arbitrator shall not be bound by rulings in prior
            arbitrations involving our other Users, but is bound by rulings in
            prior arbitrations involving the same User to the extent required by
            applicable law.
            <br />
            With the exception of the provision in the preceding paragraph
            prohibiting class arbitration or representative proceedings, if an
            arbitrator or court decides that any part of this Section 2 is
            invalid or unenforceable, the other parts of this Section 2 shall
            still apply to the maximum extent possible. In the event that the
            foregoing prohibition on class arbitration or representative
            proceedings is deemed invalid or unenforceable, then the entirety of
            this Section 2 shall be null and void. The remainder of the Terms,
            including, without limitation, Section 16 (Choice of Law and Forum),
            will remain in force.
            <br />
            You may opt-out of this arbitration provision only by written notice
            to us at the Notice Address (info@theseniordigest.com) within thirty
            (30) days of your acceptance of this agreement, which notice shall
            include your name, address, and a clear statement that you do not
            wish to resolve disputes with us through arbitration.
            <br />
            If we make any change to this arbitration provision (other than a
            change to the Notice Address) during the term of your relationship
            with us, that change shall not apply to any Claim against us
            initiated prior to the effective date of the change. The change
            shall apply to all other Claims that have arisen or may arise
            between you and us. We will notify you of changes to this
            arbitration provision by posting the amended terms on the Site or by
            email, in each case at least thirty (30) days before the effective
            date of the changes.
            <br />
            Notwithstanding anything to the contrary in these Terms, Providers
            shall be third party beneficiaries of the rights to dispute
            resolution and arbitration set forth in this Section 2, and each
            Provider shall have the right to enforce this Section 2 against
            Users as if such Provider were a party to the agreement set forth in
            this Section 2 in the event of any dispute or claim between you and
            such Provider based on or relating to any transaction or interaction
            between you and such Provider that is enabled by or arises in
            connection with your use of the Site.
            <br />
            DO NOT USE THIS SITE IF YOU DO NOT AGREE TO THE FOREGOING BINDING
            ARBITRATION PROVISIONS.{" "}
          </p>

          <p className="title">3. Personal Use</p>
          <p className="desc">
            We do not charge Users to access the Site. We grant each User a
            limited, revocable, non-exclusive license to access the Site and the
            text, images, maps, files, photos, information and other materials
            on the Site (the “Content”) in order to view, access or make
            inquiries to us regarding our services, in accordance with these
            Terms. Any other use of the Site is expressly prohibited. This
            limited license does not include any right of display, copying,
            aggregation, collection, or derivative use of the Site nor any right
            of use of spiders, data mining, robots or similar data extraction
            and gathering tools without our prior express written permission.
            Any rights not expressly granted herein are reserved by us.
          </p>

          <p className="title">
            4. Downloading of Information from the Site and Proprietary Rights
          </p>
          <p className="desc">
            All Content and the Site itself is protected by copyright and
            database rights as a compilation and/or collective work, pursuant to
            U.S. copyright laws, other copyright laws, trademark laws, and
            international conventions. The Content is only for each User's
            personal use in accordance with Section 3 (Personal Use). All Users
            agree to abide by these Terms and any and all additional
            information, restrictions or copyright notices contained in or
            relating to any Content on the Site. Copying or storing any Content
            other than for noncommercial, personal use is expressly prohibited
            without prior written permission from us or the applicable copyright
            holder. You acknowledge and agree that The Senior Digest and its
            respective affiliates, own the data gathered by the Application. By
            displaying, publishing, or otherwise posting Content on our through
            the Site, you hereby grant to The Senior Digest a non-exclusive,
            sub-licenseable, irrevocable, worldwide, fully-paid, royalty free
            license to use, modify, publicly display, reproduce, publish, and
            distribute such User Content in any and all media now known or
            hereafter developed without the requirement to make payment to you
            or any third party or to seek your or any third party's permission.
          </p>
          <p className="title">5. Unauthorized Use</p>
          <p className="desc">
            Unless otherwise provided herein, or unless otherwise allowed under
            applicable law, you may not do any of the following without our
            prior written consent: Upload, post, display, copy, republish,
            reproduce, transmit, or distribute any Content in any form
            whatsoever; Reproduce any portion of the Site on your website or,
            using any device, including but not limited to use of a border or
            frame environment around the Site, or other framing technique to
            enclose any portion or aspect of the Site, or replicate or mirror
            any portion of the Site;
            <br />
            Reverse engineer disassemble, copy, or duplicate any part of the
            Site; Modify, translate into any written or computer language, or
            create derivative works or translations from any Content or part of
            the Site;
            <br />
            Offer for sale, sell, license, or transfer, in any form, any portion
            of the Site any third parties; Use any spider, robot or other
            automated device or automated or manual process to copy, monitor, or
            keep a database copy of the Content or any portion of the Site;
            <br />
            Use the Site other than to make legitimate inquiries to us regarding
            our services or to learn about our services; Use the Site to create
            any fraudulent or false inquiry or account;
            <br />
            Transmit or post any abusive, threatening, unlawful, defamatory,
            libelous, false, misleading indications of origin or statements of
            fact, vulgar, obscene, indecent, sexually explicit, pornographic,
            profane or inflammatory material, or any material that could
            constitute or encourage conduct that would be considered a criminal
            offense, give rise to civil liability, or otherwise violate any law
            or for any other purpose that is unlawful or prohibited by these
            Terms; Access or use the Site in any way that, in our sole
            discretion, adversely affects the function or performance of the
            Site or any other networks or computer systems used by us or the
            Site, or infringes on our or any third party's trademark, copyright,
            patent rights, rights of privacy or publicity or any other
            proprietary rights;
            <br />
            Transmit or upload to the Site, or use in connection with the Site,
            any software, device or routine that contains viruses, worms, time
            bombs, Trojan horses or other computer programming routines that may
            interfere with or attempt to interfere with, damage or in any way
            intercept the normal operation of the Site, or appropriate the Site
            or any system, or take any action that imposes an unreasonable load
            on our computing platform;
            <br />
            Circumvent any encrypted data or gain access to more materials or
            data than was licensed or use the Site in any manner which violates
            these Terms or any applicable laws; or Attempt to gain unauthorized
            access to the Application or any computer system used to provide the
            Application, circumvent any access restriction or security measures
            with respect to the Application, or disclose your access information
            or otherwise give access to the Application to any third party.
            <br />
            Unauthorized access to and/or use of the Site, or the
            telecommunications or computer facilities used to deliver the Site,
            is a breach of these Terms and is a violation of law. Further,
            unauthorized access to and/or use of the Application may result in
            your license to use the Application being terminated immediately,
            and you may be denied future participation in any telematics
            offering distributed by The Senior Digest. Your internet service
            provider and/or mobile carrier may also remove you from their
            services in response to violations of these Terms applicable to the
            Application. If you are or become aware of or experience any
            Content, activity or communication through or in connection with the
            Site that appears to be in violation of the above, or in violation
            of any other provision of these Terms, we ask that you please inform
            us of any such violation by sending notification to
            info@theseniordigest.com.
          </p>
          <p className="title">6. Site Changes</p>
          <p className="desc">
            We may change, discontinue or suspend any aspect of the Site at any
            time, including the availability of any Site features, Content or
            database. We may also impose limits on certain services or features
            or restrict your access to all or part of the Site without notice to
            you or liability to us.
          </p>
          <p className="title">7. Our Privacy Policy</p>
          <p className="desc">
            When you use the Site, you consent to the collection and use of your
            personally identifiable information in accordance with the The
            Senior Digest Privacy Policy, which is incorporated into these
            Terms. Our Privacy Policy explains our information collection
            practices and safeguards, how we use and share your information, and
            how to opt-out of receiving communications from us.
          </p>
          <p className="title">8. Identity Verification</p>

          <p className="desc">
            We encourage all Users to use appropriate caution when interacting
            with anyone whom you may be doing business with specifically via the
            Site or generally via the Internet.
          </p>
          <p className="title">9. LIMITATION OF LIABILITY</p>
          <p className="desc">
            IN NO EVENT WILL WE OR ANY OF OUR OFFICERS, DIRECTORS, SHAREHOLDERS
            OR EMPLOYEES, OR THOSE OF ANY APPLICATION MARKETPLACE PROVIDER, BE
            LIABLE FOR ANY LOST PROFITS, COVER, LOSS OF REVENUE OR ANY INDIRECT,
            CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT
            OF, BASED ON, OR RESULTING FROM YOUR USE OF THE SITE, YOUR INABILITY
            TO USE THE SITE, OR ANY TRANSACTION BETWEEN US OR BETWEEN YOU AND
            PROVIDERS THAT IS ENABLED BY OR ARISES IN CONNECTION WITH YOUR USE
            OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. THESE LIMITATIONS AND EXCLUSIONS APPLY WITHOUT REGARD TO
            WHETHER THE DAMAGES ARISE FROM (i) BREACH OF CONTRACT, (ii) BREACH
            OF WARRANTY, (iii) STRICT LIABILITY, (iv) TORT, (v) NEGLIGENCE, (vi)
            STATUTE, OR (vii) ANY OTHER CAUSE OF ACTION, TO THE MAXIMUM EXTENT
            SUCH EXCLUSION AND LIMITATIONS ARE NOT PROHIBITED BY APPLICABLE LAW.
            IN ALL EVENTS, OUR LIABILITY, TO YOU OR TO ANY THIRD PARTY IN ANY
            CIRCUMSTANCE ARISING OUT OF OR IN CONNECTION WITH THE SITE IS
            LIMITED TO THE GREATER OF (a) THE AMOUNT OF FEES ACTUALLY RECEIVED
            BY US FROM YOU DURING THE CALENDAR MONTH DURING WHICH SUCH ALLEGED
            CLAIM(S) ACCRUED, AND
            <br />
            (b) $200.00. Any cause of action you may have hereunder or with
            respect to your use of the Site must be commenced within one (1)
            year after the claim or cause of action first arises. SOME
            JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY
            FOR INCIDENTAL, CONSEQUENTIAL, OR OTHER DAMAGES; AS A RESULT, THE
            ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU AND THE
            FOREGOING PARAGRAPH SHALL NOT APPLY TO A RESIDENT OF NEW JERSEY TO
            THE EXTENT DAMAGES TO SUCH NEW JERSEY RESIDENT ARE THE RESULT OF The
            Senior Digest's NEGLIGENT, FRAUDULENT OR RECKLESS ACT(S) OR
            INTENTIONAL MISCONDUCT.
          </p>

          <p className="title">10. DISCLAIMER</p>
          <p className="desc">
            THE SITE, INCLUDING ALL CONTENT, SERVICES, FUNCTIONS, SOFTWARE,
            MATERIALS AND INFORMATION MADE AVAILABLE ON OR ACCESSED THROUGH THE
            SITE, IS PROVIDED ON AN “AS IS, AS AVAILABLE” BASIS, AND The Senior
            Digest DOES NOT OFFER MAINTENANCE OR SUPPORT SERVICES FOR ANY
            PORTION OF THE SITE, INCLUDING THE APPLICATION. TO THE FULLEST
            EXTENT PERMISSIBLE BY LAW, WE MAKE NO REPRESENTATIONS OR WARRANTIES
            OF ANY KIND WHATSOEVER FOR THE CONTENT ON THE SITE OR THE SERVICES,
            INFORMATION, FUNCTIONS AND MATERIALS AVAILABLE THROUGH THE SITE, FOR
            ANY SERVICES OR PRODUCTS OR HYPERTEXT LINKS TO THIRD PARTIES OR FOR
            ANY BREACH OF SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE
            INFORMATION THROUGH THE SITE OR ANY LINKED SITE. FURTHER, WE
            EXPRESSLY DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING,
            WITHOUT LIMITATION, NON-INFRINGEMENT, TITLE, MERCHANTABILITY, AND
            FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THE
            FUNCTIONS CONTAINED IN THE SITE OR ANY SERVICES, CONTENT OR
            MATERIALS CONTAINED THEREIN WILL MEET YOUR NEEDS, BE COMPLETE OR
            ACCURATE, BE UNINTERRUPTED OR ERROR FREE,
            <br />
            THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVER THAT
            MAKES IT AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
            ANY MATERIAL OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
            USE OF THE SITE IS DONE AT USER'S OWN DISCRETION AND RISK, AND USER
            WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO USER'S COMPUTER SYSTEM
            OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF SUCH
            MATERIAL OR DATA. NO INFORMATION OBTAINED BY USER THROUGH THE SITE
            SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. SOME
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES.
            ACCORDINGLY, SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
          </p>
          <p className="title">11. Release</p>
          <p className="desc">
            In the event that you have a dispute with any Provider, Prospect, or
            other third party, including without limitation any other user of
            the Site, arising from or in connection with the use of the Site ,
            you hereby agree to release, remise and forever discharge us and our
            agents, directors, officers, employees, shareholders and all other
            related persons or entities from any and all manner of rights,
            complaints, demands, claims, causes of action, proceedings,
            obligations, liabilities, legal fees, costs, and disbursements of
            any nature whatsoever, whether known or unknown, which now or
            hereafter arise from, relate to, or are connected with such dispute
            or your use of the Site.
            <br />
            If you are a California resident, you hereby waive California civil
            code section 1542, which says: “a general release does not extend to
            claims which the creditor does not know or suspect to exist in his
            favor at the time of executing the release, which, if known by him
            must have materially affected his settlement with the debtor.” If
            you are a resident of a state other than California, you explicitly
            waive the terms and protections of any statute of your own state
            that has a similar import or intent.
          </p>
          <p className="title">12. Indemnity</p>

          <p className="desc">
            You hereby agree to indemnify, defend and hold us and our agents,
            directors, officers, employees, shareholders and all other related
            persons or entities (collectively, the “Indemnified Parties”)
            harmless from and against any and all liability and costs (including
            reasonable attorneys' fees and court costs) incurred by the
            Indemnified Parties in connection with any claim arising out of your
            use of the Site or the Content thereon (including, without
            limitation, as a result of any transaction or interaction between
            you and a Provider or any of Provider's affiliates, partners or
            agents that is enabled by or arises in connection with your use of
            the Site), any information or data you access or provide through the
            Site, and any act (or failure to act) by you or other users of your
            account or any breach by you of these Terms, including, without
            limitation, the representations, warranties and covenants made by
            you herein.
          </p>
          <p className="title">13. Proprietary Rights Notification</p>
          <p className="desc">
            We do not permit copyright or trademark infringing activities and
            other infringement of intellectual property rights on the Site, and
            we will remove or modify (or ask you to remove or modify) Content if
            we are properly notified that such Content infringes on another's
            intellectual property rights. If you are a copyright or trademark
            owner or an agent thereof and believe that any Content in any public
            area of the Site infringes upon or otherwise conflicts with your
            proprietary rights, you may submit a notification pursuant to the
            Digital Millennium Copyright Act (“DMCA”) by providing our copyright
            agent with the following information in writing (see 17 U.S.C. §
            512(c)(3) for further detail): A physical or electronic signature of
            a person authorized to act on behalf of the owner of an exclusive
            right that is allegedly infringed;
            <br />
            Identification of the proprietary work claimed to have been
            infringed, or, if multiple proprietary works are covered by a single
            notification, a representative list of such works; Identification of
            the material that is claimed to be infringing or to be the subject
            of infringing activity and that is to be removed or access to which
            is to be disabled and information reasonably sufficient to permit us
            to locate the material; Information reasonably sufficient to permit
            us to contact you, such as an address, telephone number, and an
            electronic mail address;
            <br />
            A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the owner,
            its agent, or the law; and A statement that the information in the
            notification is accurate and, under penalty of perjury, that you are
            authorized to act on behalf of the owner of an exclusive right that
            is allegedly infringed.
            <br />
            Please contact our designated Copyright Agent at
            info@theseniordigest.com to send notifications of claimed
            infringement. For clarity, only DMCA notices should go to the
            Copyright Agent; any other feedback, comments, requests for
            technical support, and other communications should be directed to
            our customer service through the Contact Us area of the Site. You
            hereby acknowledge and agree that if you fail to comply with all of
            the requirements listed above, your DMCA notice may not be valid.
          </p>

          <p className="title">14. Trademarks</p>
          <p className="desc">
            Our logo and our other product and service names are our trademarks
            (the “Marks”). You agree not to display, reproduce or otherwise use
            in any manner such Marks without our prior written permission.
          </p>
          <p className="title">15. Choice of Law and Forum</p>
          <p className="desc">
            The Senior Digest is a Delaware corporation and this agreement is
            governed by the laws of the State of Delaware, United States of
            America, excluding its choice of law rules. Subject to Section 2
            (Dispute Resolution By Binding Arbitration), you irrevocably agree
            that such jurisdiction and venue will be the sole and exclusive
            jurisdiction and venue in Delaware of any legal dispute. You
            covenant not to sue us in any other forum for any cause of action.
            Our failure to exercise or enforce any right or provision of these
            Terms shall not constitute a waiver of any such right or provision.
            Subject to Section 2,
            <br />
            if for any reason a court of competent jurisdiction finds any
            provision of these Terms, or portion thereof, to be unenforceable,
            that provision shall be enforced to the maximum extent permissible
            so as to affect the intent of these Terms, and the remainder of
            these Terms shall continue in full force and effect.
          </p>

          <p className="title">16. No Agency</p>
          <p className="desc">
            The relationship between the Company and each User and Provider is
            that of independent contractors, and no agency, joint venture,
            partnership, employee-employer or franchiser-franchisee relations is
            intended or created by these Terms or your use of the Site.
          </p>
          <p className="title">17. Notices</p>
          <p className="desc">
            Except as explicitly stated otherwise, any notices sent from you to
            us shall be submitted via e-mail at info@theseniordigest.com with a
            copy of such notice sent by certified postal mail, return receipt
            requested, to: Legal Department, The Senior Digest. When we need to
            send a notice to you, it shall be sent to the e-mail address you
            last provided to us. Notice shall be deemed given upon receipt or 24
            hours after e-mail is sent, unless the sending party is notified
            that the e-mail address is invalid. Alternatively, we may give you
            notice by certified mail, postage prepaid and return receipt
            requested, to the address last provided to us by you. In such cases,
            notice shall be deemed given three days after the date of mailing.
          </p>
          <p className="title">18. Amendments</p>

          <p className="desc">
            We reserve the right to change, modify, add or remove any portion of
            these Terms, in whole or in part, at any time. Notification of
            changes to these Terms will be posted on the Site and will be
            effective immediately thereafter. Your use of the Site following the
            posting of any such change, modification or amendment to these Terms
            will constitute your acceptance thereof. These Terms may not
            otherwise be modified, except in a writing signed by both parties.
            No delay or failure on the part of either party in exercising any
            right or remedy hereunder shall operate as a waiver thereof or of
            any other right or remedy. No waiver whatsoever shall be valid
            unless in writing, signed by the party to be charged, and then only
            to the extent therein set forth. These Terms (including, without
            limitation, the E-Sign Consent, binding arbitration) and the Privacy
            Policy constitute the entire agreement between us and Users with
            respect to the matters contemplated hereby
          </p>
          <p className="title">19. Links to Third-Party Sites</p>
          <p className="desc">
            This Site may contain links to other Internet sites. We are not
            responsible for the availability of such external sites or
            resources, and do not endorse and are not responsible or liable for
            any content, advertising, products, or other materials on or
            available from such sites or resources. We shall not be responsible
            or liable, directly or indirectly, for any damage or loss caused or
            alleged to be caused by or in connection with use of or reliance on
            any such content, products or services available on such external
            sites or resources. We provide these links and references to you
            only as a convenience, and inclusion of any link or reference does
            not imply endorsement of the Web site or other resource.
          </p>
          <p className="title">20. Partial Invalidity</p>
          <p className="desc">
            The invalidity or unenforceability of any one or more sections of
            these Terms shall not affect the validity or enforceability of its
            remaining provisions.
          </p>

          <p className="title">21. Acknowledgement</p>

          <p className="desc">
            You acknowledge that You have read and understand these Terms, and
            that these Terms have the same force and effect as a signed
            agreement. These Terms shall confer no rights upon any other party
            other than the parties hereto.
          </p>
          <p className="title">22. E-Sign Consent</p>
          <p className="desc">
            n this E-Sign Consent, the following definitions apply: (i) “You”
            and “your” refer to you, the User; (ii) “We,” “us,” and “our” refer
            to the Site operator and its affiliates (including, without
            limitation, The Senior Digest Inc.), agents, successors and assigns.
            By clicking the “I agree” button, which you adopt as your electronic
            signature, you consent and agree that: We can provide you
            disclosures required by law, and other information about your legal
            rights and duties, electronically.
            <br />
            Your electronic signature on agreements and documents has the same
            effect as if you signed them in ink. We can send all communications
            and disclosures, including, but not limited to, this E-Sign Consent,
            the telephone and e-mail contact consent, and the Privacy Policy and
            Terms of Use (collectively defined as “Disclosures”) to you
            electronically: (1) via e-mail; (2) by access to a web site that we
            designate in an e-mail notice we send to you at the time the
            information is available; or (3) to the extent permissible by law,
            by access to a web site that we generally designate in advance for
            such purpose.
            <br />
            If you would like a paper copy, you can print a copy of the
            Disclosures or download the information for your records. This
            consent applies to: (1) your use of products and services offered by
            us; (2) all future Disclosures associated with us; and (3) all
            future transactions with us, at any time, and to other Disclosures
            that we provide to you by email, unless you have, prior to such
            transaction, withdrawn your consent by the procedure mentioned
            below. You understand that, to access and retain the electronic
            Disclosures, you will need the following:
            <br />
            A computer with an Internet connection.
            <br />
            A current web browser that includes 128-bit encryption. Minimum
            recommended browser standards are Microsoft Internet Explorer
            version 8.0 and above (see www.microsoft.com/ie for current
            version), Mozilla Firefox current version (see www.mozilla.com for
            current version), Apple Safari current version (see
            www.apple.com/safari for current version), or Opera current version
            (see www.opera.com for current version). The browser must have
            cookies enabled. A valid email address.
            <br />
            Sufficient storage space to save past Disclosures and/or an
            installed printer to print them. If you use a spam filter that
            blocks or re-routes emails from senders not listed in your email
            address book, you must add info@theseniordigest.com to your email
            address book.
            <br />
            You understand that you may request paper copies of the Disclosures,
            and that we will provide them to you by mail at no charge. You can
            request paper copies and/or withdraw consent by contacting us at:
            The Senior Digest
            <br />
            Any withdrawal of your consent to receive electronic Disclosures
            will be effective only after we have a reasonable period of time to
            process your withdrawal. You understand and agree that if you
            withdraw your consent to receive electronic Disclosures, we
            may—though we are not obligated to—cancel your account.
            <br />
            At any time, you can update the email address to which we will send
            alerts that Disclosures are available. You can change your email
            address by contacting us at: info@theseniordigest.com
            <br />
            By consenting, you agree that electronic Disclosures have the same
            meaning and effect as if we provided paper Disclosures to you. When
            we send you an email or other electronic notification alerting you
            that Disclosures are available electronically and we do in fact make
            them available online, that shall have the same meaning and effect
            as if we provided paper Disclosures to you, whether or not you
            choose to view the Disclosures, unless you had previously withdrawn
            your consent to receive Disclosures electronically. You understand
            and agree that Disclosures are considered received by you within 24
            hours of the time posted to our website, or within 24 hours of the
            time emailed to you unless we receive notice that the e-mail address
            is invalid.
            <br />
            You understand and agree that we reserve the right to cancel this
            electronic Disclosure service, change the terms of use of this
            service or send Disclosures in paper form at any time.
            <br />
            You understand and agree that we are responsible for sending notice
            of the Disclosures to you electronically including at the email
            address you have provided, but are not responsible for any delay or
            failure in your receipt of the email notices.
            <br />
            This arbitration agreement includes claims related to our clients,
            vendors, and Marketing Partners, which are third party beneficiaries
            of this arbitration agreement.
            <br />
            Contact info@theseniordigest.com If you have any questions regarding
            any of these Terms, please contact us by clicking here or by calling
          </p>
          <p className="desc">
            {" "}
            © 2024 The Senior Digest. All rights reserved.
          </p>
          <Footer />
        </Container>
      </Privacy>
    </>
  );
}

const Privacy = styled.div`
  width: 100%;
  min-height: 450px;

  .title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .desc {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }
`;
