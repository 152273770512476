import styled from "styled-components";


export const TrendingStylesList = styled.div`
.trending-start {
  border-bottom: 1px solid #bfbfbf;
  margin-bottom: 1rem;
}
.ts-title {
  color: #3a3a3a;
  font-family: Tienne;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.title {
  color: #3a3a3a;
  font-family: Tienne;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.name-details {
  display: flex;
  align-items: center;
  color: #848484;
  font-family: Tienne;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;


  @media(max-width:991px){
    font-size:9px;
    margin-top:6px;
  }
}
`;



export const HealthContentStyles = styled.div`
width: 100%;
height: 100%;
display: flex;
align-items: flex-start;
justify-content: space-evenly;
flex-direction: column;

img {
  margin-bottom: 1rem;
}
.title {
  color: #3a3a3a;
  font-family: Tienne;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 18ch;

  transition:1s all;
    &:hover{
      color:#22d1a3;
      transition:1s all;
    }


  @media(max-width:600px){
    font-size:26px;
  }
}
.desc {
  color: #3a3a3a;
  font-family: Tienne;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media(max-width:600px){
    font-size:14px;
  }
}
.author {
  color: #848484;
  font-family: Tienne;
  font-size: px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
`;



export const DP = styled.div`
  width: 100%;
  .desc {
    color: #828ca5;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .custom-list {
    list-style-type: none;
    margin: 1rem 0;
    ul {
      padding-left: 0px;
    }
  }
  .input-booking-field {
    width: 100%;
    display: flex;
    align-items: center;

    input {
      height: 60px;
      width: 100%;
      border-left: 2px solid #112950;
      background: #fff;
      border: none;
      outline: none;
      padding: 20px;
    }
    .book-btn {
      display: flex;
      width: 30%;
      height: 60px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      color: #fff;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.5px;
      letter-spacing: -0.15px;
      border: none;
      outline: none;
      background: #177ee5;
    }
  }
  .custom-list li {
    position: relative;
    padding-left: 20px;
    color: #828ca5;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .custom-list li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #177ee5;
    font-size: 1.5em;
  }
`;