import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import BD from "../../../../Assets/Blogs/BDED-1.jpg";
import Subscribe from "../../../Home/Subscribe";
import MedicalInsurance from "../../../Home/MedicalInsurance";
import TrendingComp from "../../../Common/Trending";
import Footer from "../../../Footer";
export default function BlogDetailsED() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <BlogStyles>
              <p className="mb-0 bs-title">Diet In The Elderly</p>
              <div className="w-100 auth-flex">
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
                <div className="share-social">
                  <FaFacebook size={15} color="#8A8A8A" />
                  <FaXTwitter size={15} color="#8A8A8A" className="mx-3" />
                  <FaInstagram size={15} color="#8A8A8A" />
                </div>
              </div>
              <div className="detail-content">
                <img src={BD} className="img-fluid w-100 my-2" />
                <p className="dc-desc">
                  A good diet is the foundation of a healthy life and an
                  important part of caring for an older adult. Therefore,
                  knowing what foods an older adult should consume in abundance,
                  and on the contrary, which ones to avoid, represents a
                  significant help for grandparents who suffer from a chronic
                  disease or are at risk of suffering from it.
                </p>
                <p className="dc-desc">
                  Do you want to know what are the best eating habits in the
                  elderly? In this guide we will cover everything about best
                  eating practices for older adults
                </p>
                <p className="mb-0 dc-title-2">
                  What is Diet in the Elderly according to WHO?
                </p>
                <p className="mb-0 dc-desc">
                  Food according to the World Health Organization (including
                  that of the elderly) is a concept that refers to food intake
                  in relation to the needs of each perso's body. Therefore, it
                  is important to carry out a healthy and balanced diet to
                  maintain good health. In addition, some daily exercise
                  routines should also be included for best results. A poor diet
                  can weaken the elderly and make them more vulnerable to
                  certain diseases that can reduce their productivity and alter
                  their physical and mental development.
                </p>
                <p className="dc-desc">
                  In general, poor diet occurs when there is an increase in the
                  consumption of processed foods. Since the speed with which the
                  world moves today has forced us to change our lifestyle. So
                  many people tend to eat processed foods to save time. These
                  foods contain unhealthy substances with high caloric levels
                  along with a series of free sugars, fats and salt. This is
                  something that can be very harmful to the body, especially if
                  you do not eat enough vegetables, fruits and dietary fiber.
                </p>
                <p className="dc-desc">
                  It should be noted that the diet for older adults will be
                  different in each case. This happens because to determine a
                  good balanced, healthy and varied diet, various aspects of the
                  person's life must be taken into account. These aspects
                  include age, lifestyle, sex and degree of physical activity.
                  Therefore, you should go to a specialist so that he can
                  evaluate the characteristics of the elderly and can create an
                  effective diet based on them.
                </p>
                <p className="mb-0 dc-title-2">
                  Characteristics of a healthy diet in the elderly
                </p>
                <p className="dc-desc">
                  For an older adult to have a good diet, they must carry out a
                  diet that includes the following characteristics:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      It should be as complete as possible. This means that the
                      diet must include certain nutrients that provide a wide
                      variety of benefits to the body. For example: vitamins,
                      minerals, proteins, carbohydrates, water and fats.
                    </li>
                    <li>
                      It has to be adapted to your personal characteristics. As
                      we mentioned before, each person must have a different
                      diet based on their age, height, sex, work, physical
                      activity that they carry out and their state of health.
                    </li>
                    <li>
                      It must be varied. It is essential that the diet of the
                      elderly is made up of foods from all the groups in the
                      food pyramid to ensure that they are obtaining all the
                      necessary nutrients. Among these groups, some foods such
                      as fruits, vegetables, dairy products, cereals, meats,
                      cereals and vegetables stand out.
                    </li>
                    <li>
                      It is extremely important that your diet provides you with
                      a sufficient amount of energy for your day-to-day life.
                    </li>
                    <li>
                      You have to maintain a balance. All the nutrients in the
                      diet must be distributed in a balanced way. So the total
                      percentage of daily calories should be composed as
                      follows: 60% carbohydrates, 30% fat and 10% protein. In
                      addition to this, the elderly person should drink between
                      1.5 and 2 liters of water a day.
                    </li>
                    <li>
                      It should be enough. The amount of food that must be
                      incorporated into the diet must be adequate so that the
                      elderly can maintain a normal weight based on their height
                      so that they can reinforce their health.
                    </li>
                  </ul>
                </div>
                <p className="mb-0 dc-title-2">
                  What foods and nutrients should be included in the Diet for
                  Adults?
                </p>
                <p className="mb-0 dc-desc">
                  It is very important to follow a nutritional diet that
                  includes certain foods and nutrients that cover all the needs
                  of the body in a healthy way. These foods and nutrients are:
                </p>

                <p className="mb-0 dc-title-2">Fats</p>
                <p className="dc-desc">
                  The diet of the elderly must contain a percentage of fat less
                  than or equal to 30%. Since in this way the excessive
                  consumption of calories will be avoided in order to prevent
                  overweight problems. For this, the person must limit the
                  intake of trans fats and control the consumption of saturated
                  fats. In addition, it is vital that you replace these two
                  elements with the unsaturated fats found in walnuts, sunflower
                  seeds, corn or soybean oil, flaxseeds and in some fish such as
                  albacore, trout and salmon.
                </p>
                <p className="dc-desc">
                  To include the consumption of these fats in a healthy way, the
                  following tips should be followed:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Replace common butter with oils rich in polyunsaturated
                      fats such as corn, soy or sunflower.
                    </li>
                    <li>
                      Instead of frying food, it should be steamed or boiled.
                    </li>
                    <li>
                      It must be varied. It is essential that the diet of the
                      elderly is made up of foods from all the groups in the
                      food pyramid to ensure that they are obtaining all the
                      necessary nutrients. Among these groups, some foods such
                      as fruits, vegetables, dairy products, cereals, meats,
                      cereals and vegetables stand out.
                    </li>
                    <li>
                      Consume the least amount of baked or appetizing foods such
                      as cakes, donuts, biscuits, and cookies as possible.
                    </li>
                    <li>Include lean meats and skimmed dairy.</li>
                  </ul>
                </div>
                <p className="mb-0 dc-title-2">Calcium</p>
                <p className="dc-desc">
                  Both the teeth and bones of the human body are made up of a
                  large amount of calcium. As well as neurons, blood and body
                  tissues, but in this case this mineral is found in smaller
                  quantities. This nutrient is one of the most important of all,
                  since it influences:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Maintaining a normal heart rhythm.</li>
                    <li>Blood clotting.</li>
                    <li>The development of teeth and bones.</li>
                    <li>Relaxation and muscle contraction.</li>
                    <li>The sending and receiving of nerve signals.</li>
                    <li>The secretion of hormones.</li>
                  </ul>
                </div>
                <p className="dc-desc">
                  This mineral is present in foods such as cheese, milk, yogurt,
                  broccoli, chard, spinach, asparagus, and fish such as salmon
                  and sardines.
                </p>
                <p className="mb-0 dc-title-2">Fruits, vegetables and greens</p>
                <p className="dc-desc">
                  The consumption of these foods is one of the best options to
                  reduce the risk of suffering from non-communicable diseases,
                  such as cancer or cardiovascular and respiratory diseases. For
                  this reason, it is recommended that the older adult ingest at
                  least 400 g of fruits, vegetables and greens during the day as
                  follows:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Add vegetables to all meals.</li>
                    <li>Eat snacks of fresh fruits and raw vegetables.</li>
                    <li>
                      Choose a varied selection of vegetables and fruits that
                      include grapes, oranges, broccoli, carrots, blueberries,
                      apples, beets, tomatoes and other foods of this style.
                    </li>
                    <li>
                      Make sure vegetables and fruits are fresh in season.
                    </li>
                  </ul>
                </div>
                <p className="mb-0 dc-title-2">Zinc</p>
                <p className="dc-desc">
                  Zinc is a fundamental element for the enzymes in charge of
                  metabolism to maintain their proper functioning. Likewise, it
                  also has a great influence on the formation of DNA, the acuity
                  of the senses and the maintenance of the immune system. It can
                  be acquired by consuming beef, liver, lettuce, spinach,
                  carrot, peach, mushrooms and in egg yolk.
                </p>
                <p className="mb-0 dc-title-2">Vitamin D</p>
                <p className="dc-desc">
                  Like calcium, vitamin D is an ideal substance for bones and
                  teeth to develop properly. Therefore, an adequate consumption
                  of this vitamin can help the elderly not suffer from diseases
                  such as rickets or osteoporosis. This mineral can be obtained
                  through exposure to the sun's rays or through foods such as:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Meats</li>
                    <li>Mushrooms</li>
                    <li>Fatty fish like mackerel and salmon.</li>
                    <li>Fish liver oils.</li>
                    <li>Mil</li>
                    <li>Eggs</li>
                    <li>Butter</li>
                  </ul>
                </div>
                <p className="mb-0 dc-title-2">Potassium and sodium</p>
                <p className="dc-desc">
                  Potassium and sodium are two elements that work together to
                  maintain proper functioning in the muscular and nervous
                  system. In the same way, they help the production of proteins,
                  the maintenance of muscles and the regulation of the water
                  level in the body. This nutrient can be included in the diet
                  of the elderly through foods such as:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Walnuts</li>
                    <li>Meat and fish.</li>
                    <li>Soy products.</li>
                    <li>Dairy products.</li>
                    <li>Fruits like kiwi and bananas.</li>
                    <li>Vegetables such as tomatoes or potatoes.</li>
                  </ul>
                </div>

                <p className="mb-0 dc-title-2">Foods to Avoid Adults Over 60</p>
                <p className="dc-desc">
                  In addition to knowing what foods to include in a healthy
                  diet, it is also important to know what foods or drinks older
                  adults should avoid to further complement this plan.
                </p>

                <p className="mb-0 dc-title-2">Sugars</p>
                <p className="dc-desc">
                  The elderly should reduce the consumption of free sugars to
                  less than 10% to maintain good health. Since free sugars can
                  increase the risk of being overweight or obese and promote the
                  development of dental cavities and. These sugars are obtained
                  by consuming processed foods and beverages with a high sugar
                  content, such as sugary snacks, sweets, energy drinks,
                  flavored water, ready-to-drink coffee and soft drinks.
                  Therefore, it is important for the elderly to limit their
                  consumption as much as possible.
                </p>

                <p className="mb-0 dc-title-2">You go out</p>
                <p className="dc-desc">
                  Excessive consumption can greatly influence the increase in
                  blood pressure and thus make the elderly more likely to suffer
                  a stroke or coronary artery disease. That is why the diet
                  should contain this mineral but in moderate amounts of a
                  maximum of 5 grams per day.
                </p>
                <p className="dc-desc">
                  To reduce salt intake it is important to follow some tips:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Choose products with a low sodium level.</li>
                    <li>
                      Limit your intake of sodium-rich seasonings such as fish
                      sauce and soy sauce.
                    </li>
                    <li>Avoid using high-sodium sauces.</li>
                    <li>Cut down on salty snacks.</li>
                  </ul>
                </div>

                <p className="mb-0 dc-title-2">Precooked foods</p>
                <p className="dc-desc">
                  As we mentioned at the beginning of the article, convenience
                  foods are highly harmful to the body. Since they are prepared
                  with some preservatives so that they last long and can be
                  consumed several days after purchase. Although this is a very
                  effective option to save time cooking, what many do not know
                  is that these foods contain salts, sugars and fats that can
                  stimulate the appearance of some diseases in the elderly, such
                  as obesity.
                </p>

                <p className="mb-0 dc-title-2">Alcohol</p>
                <p className="dc-desc">
                  Seniors should consume the least amount of alcoholic beverages
                  possible. Because these substances intervene in the effect of
                  vitamins and decrease appetite, something that would make the
                  person not follow the eating plan to the letter. In addition
                  to this, if the elderly adult is taking any medication, it is
                  essential that they avoid alcohol intake so that the drug can
                  take effect and not cause adverse effects.
                </p>
                <p className="dc-desc">
                  The ways of cooking are also a fundamental factor to maintain
                  a correct diet. For this reason oils and fried foods should be
                  avoided. Instead we can opt for a healthier technique such as
                  steamed, baked or even grilled
                </p>

                <p className="mb-0 dc-title-2">
                  Importance of Nutrition in the Elderly
                </p>
                <p className="dc-desc">
                  Food is a very important element in the life of each person
                  that allows the correct physical, personal and intellectual
                  development, especially in older adults. Since during the
                  third stage of life, it is very common for some physical
                  changes to appear along with some diseases associated with age
                  that can be prevented or controlled with a good diet. Among
                  these changes, the weakening of the senses and the loss of
                  teeth stand out, which in turn reduce salivation and make the
                  person have problems chewing. Both factors directly influence
                  the adult and make him lose interest in food, thus promoting
                  malnutrition.
                </p>
                <p className="dc-desc">
                  For that reason, in old age it is important to follow certain
                  diets that are adjusted to the conditions of the elderly and
                  that limit the consumption of certain foods that are harmful
                  to the body. They must be composed of proteins, fibers,
                  vitamins, calcium, iron and other minerals.
                </p>
                <p className="dc-desc">
                  The consumption of these substances helps to maintain good
                  health, prevent the appearance of certain diseases and improve
                  the absorption of nutrients that in many cases is compromised
                  by the use of certain medications. In addition to this, they
                  are also responsible for providing energy to the person so
                  that they do not feel fatigued and can carry out their
                  day-to-day activities without any limitation.
                </p>

                <p className="mb-0 dc-title-2">
                  What factors influence the Diet of the elderly?
                </p>
                <p className="dc-desc">
                  Today it is very common for all people, including older
                  adults, to make certain decisions that are not compatible with
                  a good diet, something that can lead to a weight or health
                  problem. Among these factors that do not allow the person to
                  carry out a healthy diet, the following stand out:
                </p>

                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      <b>Economy:</b> Currently fast food is one of the cheapest
                      ways to eat. However, these foods are full of sugars and
                      fats that increase the amount of calories consumed and put
                      the life of the elderly at risk
                    </li>
                    <li>
                      <b>Tastes and preferences:</b> Many times people tend to
                      resort to fast foods for their attractive flavor and leave
                      aside vegetables that, although they do not have a very
                      pleasant taste for everyone, they do help maintain a good
                      state of health.
                    </li>
                    <li>
                      <b>The relationship between satiety and the brain:</b>{" "}
                      Some negative thoughts or mental illnesses make the adult
                      feel the need to continue eating and it is more difficult
                      for them to be satisfied.
                    </li>
                    <li>
                      <b>Time pressures:</b> The fast pace of life today means
                      that we have less time to eat healthy and exercise, for
                      that reason many choose fast foods.
                    </li>
                    <li>
                      <b>Food education:</b> It is very common to see how some
                      elderly people do not have any knowledge about food, so
                      their daily diets tend to lack the essential vitamins and
                      minerals that the body needs.
                    </li>
                  </ul>
                </div>

                <p className="mb-0 dc-title-2">
                  How do you know if a person is not eating properly?
                </p>
                <p className="dc-desc">
                  If an elderly person is not eating properly, they are likely
                  to experience any of the following symptoms:
                </p>

                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Damaged hair</li>
                    <li>Tooth and gum problems.</li>
                    <li>Wrinkles</li>
                    <li>
                      Gastrointestinal problems such as diarrhea, constipation,
                      or indigestion.
                    </li>
                    <li>Sudden weight gain or loss.</li>
                    <li>His wounds take time to heal.</li>
                    <li>Confusion</li>
                    <li>You get sick frequently</li>
                  </ul>
                </div>
                <p className="dc-desc">
                  It is extremely important to see a doctor as soon as possible
                  when these symptoms appear frequently.
                </p>

                <p className="mb-0 dc-title-2">Special Diet for Older Adults</p>
                <p className="dc-desc">
                  A healthy diet should be composed of several foods in adequate
                  quantities that are not excessive and that are distributed
                  throughout the day in a balanced way. For this, different food
                  plans can be applied, such as:
                </p>

                <p className="mb-0 dc-title-2">Breakfast:</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Yogurt or milk</li>
                    <li>Cereals, cookies type Maria or bread</li>
                    <li>Fruit</li>
                  </ul>
                </div>
                <p className="mb-0 dc-title-2">Midmorning:</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Infusion</li>
                    <li>Cheese, bread or ham</li>
                    <li>Fruit</li>
                  </ul>
                </div>

                <p className="mb-0 dc-title-2">Lunch:</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Rice, potato, pasta or legume.</li>
                    <li>Fish, meat or eggs.</li>
                    <li>Garnish: vegetable or salad.</li>
                    <li>Bread.</li>
                    <li>Fruit.</li>
                  </ul>
                </div>
                <p className="mb-0 dc-title-2">Snack:</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Milk or yogurt</li>
                    <li>Cookies or cereals.</li>
                    <li>Fruit.</li>
                  </ul>
                </div>
                <p className="mb-0 dc-title-2">Dinner:</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Vegetable or salad.</li>
                    <li>Fish, meat or eggs.</li>
                    <li>Garnish: rice or potatoes.</li>
                    <li>Bread.</li>
                    <li>Dairy or fruit dessert.</li>
                  </ul>
                </div>
                <p className="mb-0 dc-desc">
                  This diet is simply one example of how a meal plan should be
                  structured. Since, as we mentioned before, each older adult
                  must follow a diet that takes into account their health and
                  other aspects of their life. For this, you should go to a
                  geriatric nutritionist who can build a special and complete
                  diet for adults to help them avoid certain health problems.
                </p>

                <p className="mb-0 dc-title-2">
                  Recommendations for a good diet in the elderly:
                </p>
                <p className="dc-desc">
                  Diet in the elderly also needs other elements in addition to
                  adequate food consumption. These elements are:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Drink a lot of water.</li>
                    <li>Perform daily exercise routines.</li>
                    <li>Take care of the mood.</li>
                    <li>
                      Limit the consumption of alcoholic beverages, soft drinks
                      and ice cream.
                    </li>
                    <li>
                      Eat in a regulated way without skipping meals, especially
                      breakfast.
                    </li>
                    <li>Maintain a healthy body weight.</li>
                    <li>Choose whole foods.</li>
                    <li>Avoid fried, breaded or precooked foods.</li>
                    <li>Do not use the microwave.</li>
                  </ul>
                </div>

                <p className="dc-desc">
                  For its part, the Ministry of Public Health, in its “Manual
                  for caregivers of the elderly person; emphasizes the
                  importance of communication with the elderly to understand
                  their emotions and spirituality; It also considers that their
                  care must take into account their integral hygiene, as well as
                  their needs for mobility and comfort.
                </p>
                <p className="mb-0 dc-title-2">
                  How to make a plan of care for an elderly person?
                </p>
                <p className="dc-desc">
                  The Centers for Disease Control and Prevention (CDC)
                  recommends creating a written care plan that includes the
                  person's health conditions; it should also include the
                  treatments and medicines you are taking and emergency
                  contacts. Finally, you must give precise instructions on what
                  to do in the event of hospitalization or death.
                </p>
                <p className="dc-desc">
                  Developing a written plan, which is updated each year, can
                  help us reduce emergency room visits and hospitalizations; in
                  the same way, it can improve the medical treatment of people
                  with a chronic disease and improve the living conditions of
                  our grandparents.
                </p>
                <p className="dc-desc">
                  To do this, the first thing is to have an in-depth talk
                  between the grandparent and the person who will take care of
                  them; It will also be necessary to speak with your treating
                  doctor to find out your health status and the treatment you
                  are receiving; Finally, it is necessary to also discuss the
                  needs of the caregiver, in order to avoid anxiety and
                  depression problems that may arise in them.
                </p>
                <p className="mb-0 dc-title-2">
                  Recommendations for the Care of the Elderly
                </p>
                <p className="dc-desc">
                  Due to the multiple combinations of conditions and needs that
                  a single older adult may have, it is not possible to provide a
                  single specific plan; However, once the steps in the previous
                  section have been carried out, a series of recommendations can
                  be implemented to improve your quality of life:
                </p>
                <p className="dc-title-2 ps-3">Prevent falls</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Reviewing the medications they take to find out which ones
                      can cause dizziness or drowsiness.
                    </li>
                    <li>
                      Performing exercises that increase balance, strength, and
                      flexibility.
                    </li>
                    <li>Removing sources of risk in the home.</li>
                  </ul>
                </div>

                <p className="dc-title-2 ps-3">Doing physical activity</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Helping you perform multimodal exercises with an emphasis
                      on strength and endurance.
                    </li>
                    <li>Setting daily goals for physical activation.</li>
                  </ul>
                </div>

                <p className="dc-title-2 ps-3">
                  Prevent severe cognitive decline
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Monitoring their level of consciousness through constant
                      communication with them and evaluating their degree of
                      attention.
                    </li>
                    <li>
                      Offering them cognitive stimulation activities, even if
                      they have not been diagnosed with any impairment.
                    </li>
                  </ul>
                </div>

                <p className="dc-title-2 ps-3">
                  Promote your psychological well-being
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Proposing achievable goals and things they can or want to
                      do.
                    </li>
                    <li>Stimulating their social interactions.</li>
                    <li>
                      Carrying out activities in which they exercise short-term
                      memory.
                    </li>
                  </ul>
                </div>

                <p className="dc-title-2 ps-3">Maintain sensory functions</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Carrying out periodic examinations of the hearing and the
                      vision, especially.
                    </li>
                    <li>
                      Regularly carrying out personal hygiene and hygiene
                      measures.
                    </li>
                  </ul>
                </div>

                <p className="dc-title-2 ps-3">
                  Coping with problems associated with aging such as urinary
                  incontinence
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Performing bladder training exercises.</li>
                    <li>Implementing scheduled times to go to the bathroom.</li>
                    <li>Controlling fluids and diet in general.</li>
                  </ul>
                </div>

                <p className="dc-title-2 ps-3">Support caregivers</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Offering psychological interventions through a
                      professional.
                    </li>
                    <li>
                      Supporting them in caring for their grandparent so that
                      they can take time for themselves.
                    </li>
                    <li>Sharing useful and relevant information with them .</li>
                  </ul>
                </div>

                <p className="dc-title-2">Who should provide Elderly Care?</p>
                <p className="dc-desc">
                  Virtually all the laws of the world agree that the state must
                  provide health services and care options for the elderly;
                  However, it is in the family that the care and attention of
                  the grandparents and grandmothers falls.
                </p>
                <p className="dc-desc">
                  In this sense, more and more countries include in their
                  legislation the duty or even the obligation of the family to
                  take care of elderly relatives , most of them parents or
                  grandparents; although in countries like Colombia, for
                  example, this also extends to uncles in cae they do not have
                  children or their legitimate descendants cannot take care of
                  them. China has taken this precept further, with
                  administrative sanctions for those who fail to provide
                  adequate care for their elderly relatives.
                </p>
                <p className="dc-desc">
                  However, recent amendments to the law establish that the
                  family must also fulfill its social function of providing
                  “constant and permanent” care to the elderly; Likewise, it
                  makes them responsible for maintaining and caring for their
                  quality of life, as well as providing them with the means for
                  their care, attention and comprehensive development.
                </p>
              </div>
              <MedicalInsurance title="Related Blogs" />
              <Subscribe />
            </BlogStyles>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TrendingComp />
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

const BlogStyles = styled.div`
  width: 100%;

  .bs-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 21ch;
  }
  .author {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .auth-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dc-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dc-desc {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }

  .dc-title-2 {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .dc-list {
    ul {
      li {
        color: #3a3a3a;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.2rem;
      }
    }
  }
`;
