import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ADV1 from "../../../Assets/Medicare/Adv-1.png";
import ADV2 from "../../../Assets/Medicare/Adv-2.png";
import GetInTouch from "../../Models/GetInTouch";
export default function AdvantagesAdv() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <AdStyles>
        <Row className="mb-4 w-100">
          <Col lg={6} md={12} sm={12}>
            <div className="h-100 w-100 d-flex align-items-center justify-content-start">
              <img src={ADV1} className="w-100 h-auto" alt="" />
            </div>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <div className="content">
              <p className="c-title">
                Comprehensive Coverage with Medicare Advantage
              </p>
              <p className="c-desc">
                When you choose our Medicare Advantage Plans, you get more than
                just the basics. Our plans provide:
              </p>
              <p className="c-desc">
                <b>Vision Care:</b> Includes coverage for regular eye exams,
                eyeglasses, and contact lenses.
              </p>
              <p className="c-desc">
                <b>Dental Care:</b> Coverage for routine dental check-ups,
                cleanings, fillings, extractions, and sometimes more extensive
                dental work.
              </p>
              <p className="c-desc">
                <b>Hearing Care:</b> Coverage for hearing exams and hearing
                aids.
              </p>
              <p className="c-desc">
                <b>Wellness Programs:</b> Access to fitness programs like
                SilverSneakers, which provide gym memberships and fitness
                classes.
              </p>
              <p className="c-desc">
                <b>Over-the-Counter (OTC) Benefits:</b> Allowances for
                over-the-counter medications and health products, such as pain
                relievers, vitamins, and bandages.
              </p>
              <button
                className="get-in-touch-btn"
                onClick={() => setModalShow(true)}
              >
                Talk to Agent
              </button>
            </div>
          </Col>
        </Row>

        <Row className="my-4 w-100 c-reverse">
          <Col lg={6} md={12} sm={12}>
            <div className="content">
              <p className="c-title">
                Sign Up for Medicare Advantage Plans for Seniors that Fit Your
                Needs.
              </p>
              <p className="c-desc">
                Medicare Advantage plans provide comprehensive coverage for
                seniors, reducing out-of-pocket expenses for medical services,
                prescriptions, and more. Enjoy added benefits like dental,
                vision, and wellness programs, all with flexible options. Apply
                for Medicare Advantage today and choose from a wide network of
                doctors and hospitals without the limitations of traditional
                Medicare. Explore our plans for seniors and elders to fit their
                needs and ensure their healthcare needs are fully covered.
              </p>
              <p className="c-desc">
                <b>Transportation:</b> Provides coverage for transportation to
                and from medical appointments.
              </p>
              <p className="c-desc">
                <b>Telehealth Services:</b> Access to virtual doctor visits and
                telehealth services.
              </p>
              <p className="c-desc">
                <b>Home Health Services:</b> Offers additional home health care
                services beyond those covered by Original Medicare under
                Original Medicare.
              </p>
              <p className="c-desc">
                <b>Adult Daycare Services:</b> Coverage for adult day care for
                seniors who need supervision during the day.
              </p>
              <button
                className="get-in-touch-btn"
                onClick={() => setModalShow(true)}
              >
                Talk to Agent
              </button>
            </div>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <div className="h-100 w-100 d-flex align-items-center justify-content-lg-end">
              <img src={ADV2} className="w-100 h-auto" alt="" />
            </div>
          </Col>
        </Row>
        <GetInTouch show={modalShow} onHide={() => setModalShow(false)} />
      </AdStyles>
    </>
  );
}

const AdStyles = styled.div`
  width: 100%;
  min-height: 450px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 0;

  @media (max-width: 800px) {
    .c-reverse {
      flex-direction: column-reverse;
    }
  }

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .c-title {
      color: #3a3a3a;
      font-family: Tienne;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 25ch;
      margin: 1rem 0;
      @media (max-width: 600px) {
        font-size: 22px;
        max-width: 100ch;
      }
    }
    .c-desc {
      color: #3a3a3a;
      font-family: Tienne;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 55ch;
      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
    .get-in-touch-btn {
      border-radius: 10px;
      background: #4c9784;
      display: inline-flex;
      padding: 15px 3rem;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      text-align: center;
      font-family: Epilogue;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.5px;
      letter-spacing: -0.15px;
      outline: none;
      border: none;
    }
  }
`;
