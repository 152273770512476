import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import {
  TrendingStylesList,
  HealthContentStyles,
} from "../../Shared/SharedStyles";
import TitleFlag from "../../Common/TitleFlag/Index";
import { Trendinglist } from "../../Common/StaticData/Trending";
import MI1 from "../../../Assets/Common/MedicalInsurance/MI-1.png";
import MI2 from "../../../Assets/Common/MedicalInsurance/MI-2.png";
import TrendingComp from "../../Common/Trending";
export default function MedicalInsurance({title}) {
  return (
    <>
      <FinalExpStyles>
        <p className="fe-title">{title}</p>
        <Row>
          <Col md={8}>
            <Row>
              <Col  md={12} lg={5} >
                <img src={MI1} alt="" className="img-fluid w-100" />
              </Col>
              <Col  md={12} lg={7}>
                <HealthContentStyles>
                  <TitleFlag text="Health" />
                  <p className="title">How to Decide Whether to Review Medicare Plans</p>
                  <p className="desc">
                  Introduction Navigating the world of Medicare can be complex, yet staying informed about your Medicare plans is crucial for optimal...
                  </p>
                  <p className="author mb-0">
                    By Afrasiab Khatak | May 5, 2024
                  </p>
                </HealthContentStyles>
              </Col>
            </Row>
            <Row className="my-4">
              <Col md={12} lg={6}>
                <TrendingComp/>
              </Col>
              <Col md={12} lg={6}>
                <HealthContentStyles>
                  <TitleFlag text="Health" />
                  <p className="title">What is a benefit period for Medicare?</p>
                  <p className="desc">
                  Introduction Navigating Medicare can often seem daunting, especially when trying to understand specific terms and how they impact your coverage.
                  </p>
                  <p className="author mb-0">
                    By Afrasiab Khatak | May 5, 2024
                  </p>
                </HealthContentStyles>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <div>
              <div className="trending-start">
                <p className="mb-0 ts-title">Trending</p>
              </div>
              <TrendingComp/>
            </div>

            <img src={MI2} className="custom-w-h" alt="" />
          </Col>
        </Row>
      </FinalExpStyles>
    </>
  );
}

const FinalExpStyles = styled.div`
  width: 100%;
  margin: 1.5rem 0;
  .fe-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
  }
  .custom-w-h{
    width:100%;
    height:auto;
  }
`;
