import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Trendinglist } from "../StaticData/Trending";
import { TrendingStylesList } from "../../Shared/SharedStyles";
import { Link } from "react-router-dom";
export default function TrendingComp() {
  return (
    <>
      <TrendingStylesList>
        {Trendinglist.map((item, index) => (
          <Row key={index} className="my-4 reverse-column">
            <Col md={9}>
            <Link to={item.path} style={{ textDecoration: "none" }}>
              <div className="d-flex h-100 align-items-start justify-content-evenly flex-column">
                <p className="mb-0 title">{item.title}</p>
                <div className="name-details">
                  <p className="mb-0">By {item.author}</p>
                  <p className="mb-0">|</p>
                  <p className="mb-0">{item.date}</p>
                </div>
              </div>
              </Link>
            </Col>
            <Col md={3}>
              <img src={item.img} className="img-fluid my-2" alt={item.title} />
            </Col>
          </Row>
        ))}
      </TrendingStylesList>
    </>
  );
}
