import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import BD from "../../../Assets/Blogs/BRMP-2.jpg";
import Subscribe from "../../Home/Subscribe";
import MedicalInsurance from "../../Home/MedicalInsurance";
import TrendingComp from "../../Common/Trending";
import Footer from "../../Footer";
export default function BlogDetailsSubmitClaim() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <BlogStyles>
              <p className="mb-0 bs-title">
                Navigating The Process: How To Submit A Claim To Medicare
              </p>
              <div className="w-100 auth-flex">
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
                <div className="share-social">
                  <FaFacebook size={15} color="#8A8A8A" />
                  <FaXTwitter size={15} color="#8A8A8A" className="mx-3" />
                  <FaInstagram size={15} color="#8A8A8A" />
                </div>
              </div>
              <div className="detail-content">
                <img src={BD} className="img-fluid w-100 my-2" />
                <p className="dc-desc">
                  Understanding how to submit a claim to Medicare is crucial for
                  ensuring you receive the benefits and reimbursements you're
                  entitled to. Whether you're a new enrollee or have been using
                  Medicare for years, navigating the claim submission process
                  can sometimes seem complex. This article provides a
                  step-by-step guide to simplify this process, ensuring your
                  claims are handled efficiently and correctly.
                </p>

                <p className="mb-0 dc-title-2">
                  Understanding When to Submit a Claim
                </p>
                <p className="dc-desc">
                  1- Automatic Claims Submission: Typically, if you receive
                  services from a provider who accepts Medicare, they will
                  submit the claim to Medicare on your behalf.
                </p>
                <p className="dc-desc">
                  2- Self-Submission Necessity: In rare cases, such as when a
                  provider does not accept Medicare or fails to submit a claim,
                  you might need to submit the claim yourself.
                </p>
                <p className="mb-0 dc-title-2">
                  Step-by-Step Guide to Submitting a Claim
                </p>
                <p className="dc-desc">
                  1- Gather Necessary Documents: Collect all relevant
                  information about the services you received. This includes
                  itemized bills, receipts, and the provider's National Provider
                  Identifier (NPI).
                </p>
                <p className="dc-desc">
                  2- Complete the Claim Form: Download and fill out the 'Patient
                  Request for Medical Payment' form (CMS-1490S) from the
                  Medicare website. Ensure all information is accurate and
                  complete
                </p>
                <p className="desc-desc">
                  3- Attach Supporting Documentation: Include all related
                  medical bills and receipts with the claim form. Ensure these
                  documents are legible and include the necessary details like
                  service dates and provider information.
                </p>
                <p className="dc-desc">
                  4- Review Medicare Coverage: Before submitting, check that the
                  services or items you're claiming are covered by Medicare.
                  This will save time and reduce the likelihood of claim denial.
                </p>
                <p className="dc-desc">
                  5- Send the Claim to Medicare: Mail the completed form and
                  attached documents to the Medicare claims office for your
                  state. Addresses for each state can be found on the Medicare
                  website or by contacting Medicare directly.
                </p>

                <p className=" dc-title-2">Deadlines and Timelines</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Submission Deadline: Claims must be submitted within one
                      year (12 months) from the date of service.
                    </li>
                    <li>
                      Processing Time: Medicare typically processes claims
                      within 60 days of receipt.
                    </li>
                  </ul>
                </div>
                <p className=" dc-title-2">
                  What to Do After Submitting a Claim
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Track the Claim: You can track the status of your claim
                      through the MyMedicare.gov website.
                    </li>
                    <li>
                      Understanding Medicare's Decision: Once processed, you
                      will receive a Medicare Summary Notice (MSN) explaining
                      what was covered and what you owe, if anything.
                    </li>
                  </ul>
                </div>
                <p className=" dc-title-2">Common Pitfalls to Avoid</p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Missing Deadlines: Ensure you submit claims within the
                      12-month window.
                    </li>
                    <li>
                      Incomplete Forms: Double-check that all sections of the
                      claim form are filled out correctly.
                    </li>
                  </ul>
                </div>
                <p className=" dc-title-2">Conclusion</p>
                <p className="dc-desc">
                  Submitting a claim to Medicare doesn't have to be a daunting
                  task. By understanding when to submit a claim, carefully
                  preparing your documents, and following the proper procedures,
                  you can ensure your claim is processed smoothly. Always keep a
                  copy of your submission for your records and don't hesitate to
                  contact Medicare for assistance if you encounter any
                  difficulties.
                </p>
              </div>
              <MedicalInsurance title="Related Blogs" />
              <Subscribe />
            </BlogStyles>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TrendingComp />
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

const BlogStyles = styled.div`
  width: 100%;

  .bs-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 21ch;
  }
  .author {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .auth-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dc-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dc-desc {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }

  .dc-title-2 {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .dc-list {
    ul {
      li {
        color: #3a3a3a;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.2rem;
      }
    }
  }
`;
