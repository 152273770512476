import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Footer from "../Footer";

export default function PrivacyPolicy() {
  return (
    <>
      <Privacy>
        <Container>
          <p className="title">
            The Senior Digest Website Privacy Policy - Your Privacy Rights
          </p>
          <p className="desc">Effective date May 25, 2023</p>
          <p className="desc">
            This Privacy Policy applies to the sites and apps where it appears
          </p>
          <p className="desc">
            This Policy describes how we treat personal information on the
            websites and apps where it is located. Your use of this website
            indicates that you agree to our collection, disclosure, use, of your
            information as described in this Privacy Policy. This includes The
            Senior Digest auto, home and life insurance services. It also
            applies to our mobile sites and apps.
          </p>
          <p className="title">We collect information from and about you.</p>
          <p className="desc">
            We collect contact information. For example, we might collect your
            name and street address if you register on our site or in our app.
            We might also collect your phone number or email address. If you
            choose to invite friends to download our app, we will collect their
            email address.
          </p>
          <p className="desc">
            We collect information you submit or post. We collect the
            information you post in a public space on our site. We also collect
            information when you contact us.
          </p>
          <p className="desc">
            We collect demographic information. We may collect information like
            your gender, age, date of birth and marital status. We may also
            collect your zip code. We might collect this when you contact us or
            enter a promotion. We collect additional information depending upon
            which services you are interested in. For example, if you request
            information about car insurance, we will collect additional
            information including information about your vehicle.
          </p>
          <p className="desc">
            If you request information about home insurance, we will collect
            additional information including information about your homeowner
            status and the property where you live. If you request information
            about life insurance, we will collect additional information
            including information about your occupation, physical activity
            level, veteran status, tobacco use, DUI history, height and weight,
            and medical history. We also collect information about your current
            insurance coverage.
          </p>
          <p className="desc">
            We collect usage and device/location information. If you use our
            website, we may collect information about the browser you're using.
            We might look at what site you came from, or what sites you visit
            when you leave us. On our app, we may collect information about your
            location, including saved locations. This may include your precise
            location. We may collect this using different types of technologies,
            including GPS and Wi-Fi. If you use our mobile app, we may also
            access your device's location information in the background. We
            might also collect device identifiers. When you use our app, we
            collect information about your driving habits. This may be collected
            using your device's location services, accelerometer, screen on/off,
            and gyroscope. You may also elect to provide us with access to your
            device contact lists or other social media contact lists. Since the
            app may not always know if you are the driver or a passenger, the
            app may record driving habits where you are not the driver.
          </p>
          <p className="title">We collect information in different ways.</p>

          <p className="desc">
            We collect information directly from you. For example, we collect
            information from you if you use our website, request insurance
            information or register for our app. We also collect information if
            you contact us. You may have the option to share a picture of your
            driver's license barcode which we will use to collect information
            about you from third-party providers. See the section below
            entitled, “We may share information with third parties” for more
            information. We collect information from you passively. We use
            tracking tools like browser cookies and web beacons. We do this on
            our websites and in emails that we send to you. We collect
            information about users over time when you use this website. We may
            have third parties collect personal information this way. Learn more
            about these tools and how you can control them, here.
          </p>
          <p className="desc">
            We also collect information from our mobile apps. This includes
            information about your device, including your device and app ID. If
            you have enabled location services for our app, we collect this
            information in the background, even when you do not have our app
            open on your phone.
          </p>
          <p className="desc">
            We get information about you from third parties. For example, our
            business partners may give us information about you, including
            contact, demographic and other personal information. These may
            include insurance providers, credit reporting agencies and other
            data companies. Social media platforms may also give us information
            about you. We combine information. For example, we may combine
            information we get from a third party with information we already
            have. We may also combine information collected from you on our
            various platforms. We may also obtain information about you based on
            other websites you have visited.
          </p>
          <p className="title">
            We use information as disclosed and described here.
          </p>
          <p className="desc">
            We use information to respond to your requests or questions. For
            example, we and our business partners use your information to select
            and provide you with information about insurance products you may be
            interested in. We may also use your information to respond to your
            customer feedback. We may use your information to notify you if you
            win a contest or sweepstakes.
          </p>
          <p className="desc">
            We use information to improve our products and services. We may use
            your information to make our website and products better. We might
            use your information to customize your experience with us.
          </p>
          <p className="desc">
            We use information for security purposes. We may use your
            information to protect our company and our customers. We also use
            information to protect our websites and apps.
          </p>
          <p className="desc">
            We use information for marketing purposes. For example, we might
            provide you with information, special offers, and advertisements
            about products. This information may be from or on behalf of
            insurance providers or other third parties. We might tell you about
            new features or updates. We might also use your information to serve
            you ads about products and offers.
          </p>
          <p className="desc">
            By submitting your email address and/or phone number to us, you
            authorize us to use that email address and phone number to contact
            you periodically, including by the use of an auto-dialer and
            pre-recorded messages, concerning (i) your quote requests, (ii)
            about the website, app or our services and (iii) information or
            offers that we feel may be of interest to you. To learn about your
            choices for these communications, read the choices. We may also
            allow our partners to provide you with information about new
            products and special offers, including offers for insurance products
            and services.
          </p>
          <p className="desc">
            We use information to communicate with you. For example, we will
            communicate with you about your account or our relationship. We may
            contact you about your feedback. We might also contact you about
            this Policy or our website Terms.
          </p>
          <p className="desc">
            We may also use push notifications through your browser and on our
            mobile apps. We will send you push notifications about insurance
            related offers and services. We may also send you push notifications
            for other reasons like friend requests, prompts to view your trips,
            or product updates.
          </p>
          <p className="desc">
            We use your information to calculate a driving score. If you use the
            app, we allow Cambridge Mobile Telematics (CMT) to collect your
            driving habits as collected through the app and we display it in the
            form of a “driving score” in the app. We or CMT may also pass your
            driving habits as collected through the app to our business
            partners, who create a separate “risk score” based on their own
            proprietary algorithm. We or our business partners may use this risk
            score, together with your device's ID and your state of residence
            that we provide to our business partners, to select offers of
            insurance products and services, which we may provide to you through
            the app. Since the app may not always know if you are driving or a
            passenger, your score may include trips where you are not the
            driver. We use information as otherwise permitted by law or as we
            may notify you.
          </p>
          <p className="title">We may share information with third parties.</p>
          <p className="desc">
            We will share information with third parties who perform services on
            our behalf. For example, we share information with vendors who send
            emails for us. We may also share information with companies that
            operate our websites, app or run a promotion. If you use our app,
            your driving information may be collected by Cambridge Mobile
            Telematics (CMT) and shared with us and our third-party marketing
            partners. This includes your location (GPS), as well as data from
            the accelerometer and gyroscope, and time of day.
          </p>
          <p className="desc">
            We will share information with our business partners and other third
            parties. These partners may send you information about products and
            services by telephone, text, fax, mail or email. If you use our
            website or app we will share your information, including name,
            address, email, telephone number, and date of birth, which you
            provided to us or that we obtained from third-party sources, with
            insurance companies or other third parties that may provide it to
            insurance companies. Insurance companies that receive your
            information may use it to assist in providing you offers for
            insurance.
          </p>
          <p className="desc">
            You further acknowledge and agree that each insurance company that
            receives your quote request from this website or from our affiliates
            may confirm your information through the use of a consumer reporting
            agency, which may include among other things, your driving record
            and/or credit score to determine eligibility and accurate rates.
            Additionally, if you use our app, the data provided to insurance
            companies, either directly by us or through other third parties, may
            include your driving history, “driving score” and “risk score” as
            calculated by us or other third parties, and as described above in
            the section above entitled “We use your information to calculate a
            driving score.” If you purchase insurance from any third party that
            is based in part on your driving habits provided by us or CMT,
            unless you instruct us otherwise, we or CMT may continue to provide
            your driving habits to this third party, who may share it with your
            insurance provider during the term of your policy.
          </p>
          <p className="desc">
            We will share information if we think we have to in order to comply
            with the law or to protect ourselves. For example, we will share
            information to respond to a court order or subpoena. This may
            include information that is discoverable in connection with accident
            investigations, litigation or both. We may share it if a government
            agency or investigatory body requests. We might share information
            when we are investigating potential fraud.
          </p>
          <p className="desc">
            We may share information with any successor to all or part of our
            business. For example, if part of our business was sold we may give
            our customer list as part of that transaction.
          </p>
          <p className="desc">
            Some of your information will be viewable by other users. If you use
            the app, your user name, profile picture, driving score and rank,
            and city or town of residence will be publicly viewable by others.
            You may also elect to share your specific driving habits and trips
            with your friends, however, we will not share this information
            without your permission. You have certain choices about how we use
            your information.
          </p>
          <p className="desc">
            You can opt out of receiving our marketing emails. To stop receiving
            our promotional emails, email customer support at
            info@theseniordigest.com or follow the instructions in any
            promotional message you get from us. Even if you opt out of getting
            marketing messages, we will still send you transactional messages.
            These include responses to your questions. If you opt-out of
            receiving marketing communications from our business partners with
            whom we have shared your information, you will still receive
            marketing communications from us and any other business partners
            with whom your information was shared.
          </p>
          <p className="desc">
            You can request that we stop sharing information with third parties
            for their marketing purposes. To opt out of having us share your
            information with third parties for their promotional purposes, email
            us at info@theseniordigest.com. You can control cookies and tracking
            tools. To learn how to manage how we - and our vendors - use cookies
            and other tracking tools, and to read our Do Not Track policy,
            please click here.
          </p>
          <p className="desc">
            You can control tools on your mobile devices. For example, you can
            turn off the GPS locator or push notifications on your phone through
            your phone's settings.
          </p>

          <p className="title">YOUR CALIFORNIA PRIVACY RIGHTS</p>
          <p className="desc">
            Consumers residing in California have certain rights with respect to
            their personal information under the California Consumer Privacy Act
            or (“CCPA”) (California Civil Code Section 1798.100 et seq.) and the
            “Shine the Light” Law (California Civil Code Section 1798.83). If
            you are a California resident, this section applies to you.
            California Consumer Privacy Act
          </p>
          <p className="desc">
            Additional Disclosures Related to the Collection, Use, Disclosure
            and Sale of Personal Information <br />
            Collection and Use of Personal Information: In the Preceding 12
            months, we have collected the following categories of personal
            information: identifiers, categories of personal information
            described in subdivision (e) of Section 1798.80 Including financial
            information, commercial information, biometric information, internet
            or other electronic network activity information, geolocation data,
            professional or employment-related information, education
            information, and inferences draw from any of the information
            identified. For examples of more specific information we collect and
            the sources of such collection, please see “We collect information
            from and about you” and “We collect information in different ways”
            above. We collect personal information for the business and
            commercial purposes described in “We use information as disclosed
            and described” above.
          </p>
          <p className="desc">
            Disclosure of Personal Information: We may share your personal
            information with third parties as described in the “We may share
            information with third parties” section above. In the preceding 12
            months, we may have disclosed the following categories of personal
            information for business or commercial purposes: identifiers,
            categories of personal information described in subdivision (e) of
            Section 1798.80 including financial information, commercial
            information, biometric information, internet or other electronic
            network activity information, geolocation data, professional or
            employment-related information, education information, and
            inferences draw from any of the information identified.
          </p>
          <p className="desc">
            You will be contacted regarding insurance products, including but
            not limited to Medicare Advantage, Medicare Supplement, and
            Prescription Drug Plans.{" "}
          </p>
          <p className="desc">
            You will receive marketing communications via automated telephone
            dialing system and/or pre-recorded artificial voice calls, text,
            messages, and emails from our “Affiliate Partners” and marketing
            partners at the phone number, physical address and email address
            provided.
          </p>
          <p className="desc">
            Sale of Personal Information: California law requires that we
            provide transparency about personal information we “sell,” which for
            the purposes of the CCPA, means scenarios in which personal
            information is shared with third parties in exchange for valuable
            consideration. In the preceding 12 months, we have “sold” the
            following categories of personal information: identifiers,
            categories of personal information described in subdivision (e) of
            Section 1798.80 including financial information, commercial
            information, biometric information, internet or other electronic
            network activity information, geolocation data, professional or
            employment-related information, education information, and
            inferences draw from any of the information identified. California
            consumers above the age of 16 have the right to opt out of these
            sales at any time. We do not knowingly sell personal information
            about consumers under the age of 16. Please visit our “Do Not Sell
            My Info” webpage to learn more about how we sell information and to
            make an opt-out request.
          </p>

          <p className="title">Your Consumer Rights</p>
          <p className="desc">
            If you are a California resident, you have the right to (1) request
            more information about the categories and specific pieces of
            personal information we have collected, sold and disclosed for a
            business purpose in the last 12 months, (2) request deletion of your
            personal information, (3) opt out of the sale of your personal
            information, if applicable, and (4) be free from discrimination for
            exercising your rights under the CCPA. You may make these requests
            by submitting a request here or by calling .
          </p>
          <p className="desc">
            To opt out of sales, you can visit our “Do Not Sell My Info”
            webpage. We will verify your request by confirming at least two
            pieces of personal information, including contact information, you
            provided in submitting your form. To designate an authorized agent
            to exercise these rights on your behalf, send an email with your
            reques
          </p>

          <p className="title">We store information in the United States.</p>
          <p className="desc">
            Information we maintain may be stored within the United States. If
            you live outside of the United States, you understand and agree that
            you are transferring your information to the United States. Our
            platforms are subject to U.S. laws, which may not afford the same
            level of protection as those in your country.
          </p>
          <p className="desc">
            We may link to other sites or apps from our websites, platforms, or
            share information with third parties we don't control.
          </p>
          <p className="desc">
            If you click on a third party link, you will be taken to another
            website or platform we do not control. This policy does not apply to
            the privacy practices of that website or platform. Read other
            companies' privacy policies carefully. We are not responsible for
            these third parties.
          </p>
          <p className="desc">
            Our site may also serve third party content that contains their own
            cookies or tracking technologies. To learn more, click here. We do
            not control the use of those technologies.
          </p>
          <p className="desc">
            Feel free to contact us if you have more questions. If you have any
            questions about this Policy, please email us at .
          </p>
          <p className="desc">
            Mailing address: 704 N King St. Ste 500 Wilmington DE 19801 <br />
            DUNS 112150053 <br />
            EIN 82-5407581 <br />
            Global delivery centers located across Asia and the USA.
          </p>

          <p className="title">We may update this Policy.</p>

          <p className="desc">
            From time to time we may change our privacy policies. We will notify
            you by email us at: info@theseniordigest.com or by means of a notice
            on the website or app of any material changes to our Policy as
            required by law. We will also post an updated copy on our website.
            Please check our site periodically for updates.
          </p>
          <p className="desc">
            {" "}
            © 2024 The Senior Digest. All rights reserved.
          </p>
          <Footer/>
        </Container>
      </Privacy>
    </>
  );
}

const Privacy = styled.div`
  width: 100%;
  min-height: 450px;



  .title{
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .desc{
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }
`;
