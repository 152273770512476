import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import Logo from "../../Assets/Common/primary-logo.png";
import { Link, useNavigate  } from "react-router-dom";
export default function Header() {
  return (
    <>
      <Container>
        <div className="upper-nav my-3">
          <Link to="/">
            <img src={Logo} alt="" className="logo" />
          </Link>
          <div className="social-list">
            <ul className="d-flex header-one-list">
              <li>
                <Nav.Link eventKey={1} href="https://www.facebook.com/theseniordigest/">
                  Facebook
                </Nav.Link>
              </li>
              <li>
                <Nav.Link eventKey={2} href="https://x.com/theseniordigest">
                  Twitter
                </Nav.Link>
              </li>
              {/* <li>
                <Nav.Link eventKey={3} href="#memes">
                  Instagram
                </Nav.Link>
              </li> */}
            </ul>
          </div>
        </div>
      </Container>
      <NavbarPrimary/>
    </>
  );
}


 function NavbarPrimary() {
  const navigate = useNavigate();
  const goToMedicare = () => {
    navigate('/maxwell-berlin/medicare');
  };
  const goToMedicareAdvantage = () => {
    navigate('/maxwell-berlin/medicare-advantage-plans');
  };
  return (
    <>
      <Container className="my-4">
          <Nav variant="tabs" defaultActiveKey="/">
          <Nav.Item>
            <Nav.Link onClick={goToMedicare}>Medicare Supplement</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={goToMedicareAdvantage}>Medicare Advantage</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey="link-1">Final Expense</Nav.Link>
          </Nav.Item> */}
          {/* <Nav.Item>
            <Nav.Link eventKey="disabled" disabled>
              Disabled
            </Nav.Link>
          </Nav.Item> */}
          <div className="ms-auto"> 
          <a href="tel:+18668392310" class="health-score-btn-2 me-3">
            <button class="health-score-btn-2 me-3">
              Spanish: +1 866-839-2310
            </button>
          </a>
          <a href="tel:+18668615366" class="health-score-btn my-2">
            <button class="health-score-btn my-2">
              English: +1 866-861-5366
            </button>
          </a>
          </div>
        </Nav>
      </Container>
    </>
  )
}
