import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import BD from "../../../../Assets/Blogs/BDWO-1.png";
import Subscribe from "../../../Home/Subscribe";
import MedicalInsurance from "../../../Home/MedicalInsurance";
import TrendingComp from "../../../Common/Trending";
import Footer from "../../../Footer";

export default function BlogDetailsD5() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <BlogStyles>
              <p className="mb-0 bs-title">
                Exercising With Diabetes: 10 Simple Steps To Start Working Out
                Safely
              </p>
              <div className="w-100 auth-flex">
                <p className="author mb-0">By Afrasiab Khatak | May 5, 2024</p>
                <div className="share-social">
                  <FaFacebook size={15} color="#8A8A8A" />
                  <FaXTwitter size={15} color="#8A8A8A" className="mx-3" />
                  <FaInstagram size={15} color="#8A8A8A" />
                </div>
              </div>
              <div className="detail-content">
                <img src={BD} className="img-fluid w-100 my-2" />
                <p className="dc-desc">
                  Staying active and working out regularly is an unavoidable
                  life truth, especially for people with diabetes. If you have
                  diabetes, then you should have a strong bond with the workout.
                  But as a diabetic person, how can you start work out safely
                  without affecting your body?
                </p>
                <p className="dc-desc">
                  Starting a workout with diabetes is not as difficult as it
                  seems, but you should know how to begin and what factors may
                  worsen your diabetes despite its benefits. But, for all this
                  beginning, motivation is the key that keeps you stick to the
                  routine.
                </p>
                <p className="dc-desc">
                  What if I am not aware of how to start diabetes exercises?
                  This question hits the mind of many people with diabetes, and
                  the fear of unawareness doesn't let them even begin workouts.
                  Well, here we will discuss few basic steps to keep in mind
                  before starting any exercise.
                </p>

                {/* <p className="dc-desc">
                  Following tests are the most important among the diabetes
                  checkup checklist, and you should get them performed:
                </p> */}
                <p className="dc-title">Importance of Exercise With Diabetes</p>
                <p className="dc-desc">
                  Exercise is very much beneficial for health for everyone, but
                  for people with diabetes, exercise might be a lifesaver.
                  Exercising with diabetes can spare you from blood sugar spikes
                  and regulate your mental health. In addition, it lower
                  diabetic risk factors like cardiovascular disease and
                  contributes to weight loss.
                </p>
                <p className="dc-desc">
                  Exercising with diabetes helps better control serum glucose
                  levels, a much-needed effect for a diabetic person. Regular
                  exercise can prevent diabetes development, and if it occurs,
                  it can control diabetic symptoms.
                </p>
                <p className="dc-title">How to Begin Workout: 10 Safety Tips</p>
                <p className="dc-title-2">1- Consult Your Doctor </p>
                <p className="dc-desc">
                  It's an important and foremost step before initiating any
                  workout plan. You never know how exercise can affect your
                  diabetes. Exercising with diabetes is good, but it may become
                  a disaster if started without consultation.
                </p>
                <p className="dc-desc">
                  Let your doctor check your diabetic score to analyze your
                  condition. You should be very careful before beginning
                  exercises, especially if you take diabetic medication or have
                  any other complications.
                </p>
                <p className="dc-desc">
                  Health issues like high blood pressure and muscle and joint
                  problems can make it challenging to exercise. The doctor will
                  recommend the changes in medication, if any, guide you about
                  the best exercises for diabetes type1 and 2. The doctor also
                  shows you how to avoid any drastic effects of exercising with
                  diabetes.
                </p>
                <p className="dc-desc">
                  Consulting a doctor can keep you updated about which exercises
                  are unsafe for type 2 diabetes. Specific activities may not
                  suit you if you have any diabetic complications like
                  cardiovascular issues, eye problems, or nerve damage.
                </p>

                <p className="dc-title-2">2- Choose Different Exercises </p>
                <p className="dc-desc">
                  The majority of us never know the best exercises for diabetes,
                  but it's crucial to get started. A mixture of strength
                  training and aerobic exercises is the perfect combo for a
                  diabetic workout plan.
                </p>
                <p className="dc-desc">
                  Aerobic exercises include jogging, swimming, walking, hiking,
                  dancing, and using cardio machines. Strength exercises are the
                  ones that require suspension ropes, weights, resistance bands
                  to improve flexibility and build up muscles.
                </p>
                <p className="dc-desc">
                  You should choose different exercises as per your comfort and
                  health score, but again never get yourself exhausted with the
                  activity you can't do. Also, choose different exercises for
                  various times as per your goal. The best time to exercise for
                  type 2 diabetes is about 30 minutes before a meal; it helps
                  better use of glucose.
                </p>
                <p className="dc-desc">
                  Different exercises fit diabetic workout plans and include
                  swimming, cycling, walking, aerobic dance, weight lifting,
                  resistance training, etc.
                </p>
                {/* <p className="dc-desc">
                  Poor circulation results in the following significant issues;
                  although there is a long list, these are important ones:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Leg and feet pain</li>
                    <li>Swollen feet and ankle</li>
                    <li>Cold extremities</li>
                    <li>Slow and delayed healing</li>
                    <li>Erectile dysfunction in men</li>
                  </ul>
                </div> */}

                <p className="dc-title-2">3- Set a Goal for Yourself</p>
                <p className="dc-desc">
                  If you are not working out previously, then nothing to worry
                  about. You can start working out today from very little and
                  progress towards moderate physical activity and strengthening
                  exercise.
                </p>

                <p className="dc-desc">
                  Always choose a realistic approach in the beginning and set a
                  goal for yourself. Recommended not to select such goals that
                  you cannot achieve either due to work schedule or other
                  reasons.
                </p>
                <p className="dc-desc">
                  Never expect too much in the beginning; importantly, never
                  expect dramatic results within a few days. It will take some
                  time to put in the effort, but it will surely help you in the
                  long term future.
                </p>
                <p className="dc-desc">
                  In the beginning, analyze your body's limitations and
                  after-effects of how the body responds to the current workout.
                  Modify the targeted goal as per body needs, but remember not
                  to relax your body much.
                </p>
                <p className=" dc-title-2">
                  4- Understand the Effect of An Exercise on Medication
                </p>
                <p className="dc-desc">
                  Do you know how physical activity affects insulin? A diabetic
                  workout plan should contraindicate with the drug, which may
                  otherwise result in severe consequences. If you take insulin
                  and work out excessively, this may sometimes result in
                  extremely low blood sugar.
                </p>
                <p className="dc-desc">
                  The more the workout is, the more will be the burning of
                  glucose. During rest, the insulin helps glucose enter the body
                  cells while this entrance of glucose becomes independent
                  during exercise. So there is a need to lower the insulin
                  intake if you have regular physical activity to avoid dropping
                  serum glucose levels.
                </p>
                <p className="dc-desc">
                  The astonishing effect of exercise is that it improves insulin
                  activity, so if you are taking insulin externally, there is a
                  need to modify the dosage. According to a study, sulfonylureas
                  ( insulin secretagogues) stimulate the pancreas to release
                  insulin during exercise and increase hypoglycemic risk.
                </p>
                <p className="dc-title-2">5- Start Slowly in the Beginning </p>
                <p className="dc-desc">
                  Exercising with diabetes does not require running a marathon
                  all of a sudden in the beginning. Even a single step on the
                  1st day matters a lot, and it helps you build up slowly. You
                  can start walking at the beginning and slow progress towards
                  running, jogging, and other intense exercises.
                </p>
                <p className="dc-desc">
                  Few ignorable and straightforward moves could also be enough
                  at the beginning, like:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>
                      Keep on walking while talking to someone on the phone
                    </li>
                    <li>Try to avoid elevators and prefer stairs</li>
                    <li>
                      Park your car over some distance, so that you have to walk
                      a bit
                    </li>
                    <li>Do some sit-ups or jumping back during breaks</li>
                    <li>Walk on foot to market for buying groceries</li>
                  </ul>
                </div>
                <p className="dc-desc">
                  Build up your exercise program bit by bit, and try to achieve
                  the target of 2 ½ hours of moderate activity per week. Or try
                  to do vigorous activity for at least 1 ¼ hours in a week.
                </p>
                <p className=" dc-title-2">
                  6- Ensure Your Safety While Exercising
                </p>
                <p className="dc-desc">
                  Prioritize your safety over everything, and don't get yourself
                  exhausted while exercising with diabetes. Keep these simple
                  tips in mind for a safe workout:
                </p>
                <div className="dc-list">
                  <ul>
                    <li>
                      1- Carefully check your blood sugar before initiating a
                      workout, immediately after a workout, and act accordingly.
                    </li>
                    <li>2- Listen to your body during exercising.</li>
                    <li>
                      3- Recheck your blood sugar about an hour after a workout
                    </li>
                    <li>
                      4- Always warm up properly to stimulate the blood flow
                      throughout the body
                    </li>
                    <li>
                      5- Drink adequate water before, during, and after a
                      workout to stay hydrated.
                    </li>
                    <li>
                      6- Carry some carbohydrate source along, to treat sudden
                      low blood sugar
                    </li>
                    <li>
                      7- Wear the medical ID tag mentioning you have diabetes to
                      get some quick help in case of emergency
                    </li>
                    <li>
                      8- Avoid exercising in extreme summers and winters; choose
                      indoors if necessary
                    </li>
                    <li>9- Avoid overly tough workouts.</li>
                  </ul>
                </div>
                <p className="dc-desc">
                  Seniors are more suspected of having safety concerns while
                  exercising. What are suitable diabetes exercises for seniors?
                  These include dancing, yoga, walking, cycling, swimming, etc.
                </p>

                {/* <p className="dc-desc">
                  Following are the reference ranges for two hours:
                </p>
                <div className="dc-list">
                  <ul className="ps-0">
                    <li>Normal: less than 140 mg/dl</li>
                    <li>Prediabetes : 140-199 mg/dl</li>
                    <li>Diabetes: 200mg/dl or above</li>
                  </ul>
                </div> */}

                <p className=" dc-title-2">
                  7- Identify What is Hindering Your Exercise Schedule
                </p>

                <p className="dc-desc">
                  What is making you afraid to begin your workout plan? Is it
                  blood sugar or something else? Well, exercising with diabetes
                  is not essential, but we can say it's a basic necessity. Your
                  concerns might be genuine in some cases, but there is always a
                  solution to any trouble.
                </p>
                <p className="dc-desc">
                  If you are afraid of sudden low blood sugar, then talk to your
                  doctor about the situation. He will suggest the best exercises
                  along with an appropriate diet to prevent this condition.
                  Adjust dosage and timing of medication to avoid any
                  uncertainty.
                </p>
                <p className="dc-desc">
                  If your work schedule does't allow you to begin exercising,
                  start very slowly and build up gradually. You can start with a
                  bit of walking, and manage physical activity in your spare
                  time instead of wasting time on mobile phones.
                </p>
                <p className=" dc-title-2">8- Motivate Yourself </p>
                <p className="dc-desc">
                  Motivation is the key to exercising with diabetes. If you are
                  running from exercises, then never think of defeating
                  diabetes. Focus on your goals and specific benefits that will
                  come along with the activity.
                </p>
                <p className="dc-desc">
                  Self-motivation is essential; it helps a person be accountable
                  to himself. Always look for the far benefits you can enjoy by
                  sparing some time for the exercises. Take medication on time,
                  have adequate sleep, and stress-free life for better workouts.
                </p>
                <p className="dc-desc">
                  If possible, get a buddy for an exercise who can motivate you
                  and make this routine more amazing.
                </p>
                <p className="dc-desc">
                  No one wants to live a compromised diabetic life in the long
                  term, Right? This motivation is well enough for a person to
                  start a workout today or resume again if left.{" "}
                </p>
                <p className="dc-desc">
                  Never underestimate yourself while comparing yourself with
                  others; you can do anything that you want. But self-motivation
                  is key to the lock which you must open for a better life
                  ahead.
                </p>
                <p className=" dc-title-2">9- Listen to Your Body </p>
                <p className="dc-desc">
                  Quit your current workout if it is continuously causing pain,
                  discomfort, and itches. Certain exercises don't suit the
                  specific body, and you may suffer from this situation. Never
                  exhaust yourself and carefully listen to your body.
                </p>
                <p className="dc-desc">
                  If any specific exercise is creating problems, then replace it
                  and consult any physiotherapist for better recommendations.
                </p>
                <p className="dc-desc">
                  If your body is not cooperating during a workout and you feel
                  fainting, have low blood sugar, shortness of breath, chest
                  pain, etc., then it's time to visit your doctor. Discuss the
                  matter, and he will come up with the best solution that suits
                  your body.
                </p>

                <p className=" dc-title-2">10- Evaluate Your Progress</p>
                <p className="dc-desc">
                  Don't you want to see how exercising with diabetes benefits
                  your condition or affects it oppositely? Keep a record from
                  the very beginning about how you started this journey. Like if
                  you start with walking, record the distance you walked daily
                  from day one till now.
                </p>
                <p className="dc-desc">
                  And then compare your workout with the changes you noted in
                  body or blood sugar Maintenance. It will update you about the
                  progress report and how the body is responding to the
                  activity.
                </p>
                <p className="dc-desc">
                  Positive progress always came up with the motivation to do
                  more and even better. But not to worry if there is negative
                  progress; there is always a way to turn negative to positive.
                </p>
              </div>
              <MedicalInsurance title="Related Blogs" />
              <Subscribe />
            </BlogStyles>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TrendingComp />
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

const BlogStyles = styled.div`
  width: 100%;

  .bs-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 21ch;
  }
  .author {
    color: #848484;
    font-family: Tienne;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .auth-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dc-title {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dc-desc {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    margin-top: 1rem;
  }

  .dc-title-2 {
    color: #3a3a3a;
    font-family: Tienne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .dc-list {
    ul {
      li {
        color: #3a3a3a;
        font-family: Tienne;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.2rem;
      }
    }
  }
`;
